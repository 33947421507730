import { useDarkMode } from 'hooks/useDarkMode'
import { useNavigationLinks } from 'hooks/useNavigationLinks'
import { useSteps } from 'hooks/useSteps'
import { View } from './View'

const Container = (props) => {
  const darkMode = useDarkMode()
  const { current } = useSteps()
  const navigationLinks = useNavigationLinks()

  return (
    <View
      isDark={darkMode}
      links={navigationLinks.links?.filter(({ hideInMenu }) => !hideInMenu)}
      prevPath={current?.path}
      {...props}
    />
  )
}

Container.displayName = 'DesktopNav.Container'

export { Container }
