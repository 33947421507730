import { StepsItem } from 'components/App/StepsItem'
import { ConsumerFinancialOutcomes } from 'components/steps/ConsumerFinancialOutcomes'
import { EligibleDebtSummary } from 'components/steps/EligibleDebtSummary'
import { FinancialRecovery } from 'components/steps/FinancialRecovery'
import { FreedomAdvantage } from 'components/steps/FreedomAdvantage'
import { HowItWorks } from 'components/steps/HowItWorks'
import { Intro } from 'components/steps/Intro'
import { StartPage } from 'components/steps/StartPage'
import { AboutPage } from 'components/steps/AboutPage'
import { OtherDebts } from 'components/steps/OtherDebts'
import { ThankYou } from 'components/steps/ThankYou'
import { WeAreIn } from 'components/steps/WeAreIn'
import { Welcome } from 'components/steps/Welcome'
import {
  WhatOurClientsSay,
  constants as WHAT_OUR_CLIENT_SAY
} from 'components/steps/WhatOurClientsSay'
import { YourDebts } from 'components/steps/YourDebts'
import { YourSolution } from 'components/steps/YourSolution'
import { YourSolutionDiscovery } from 'components/steps/YourSolutionDiscovery'
import { CallAgenda } from 'components/steps/CallAgenda'
import { FinancialGoals } from 'components/steps/FinancialGoals'
import { OptionEvaluation } from 'components/steps/OptionEvaluation'
import { YourBudgetSummary } from 'components/steps/YourBudgetSummary'
import { IncomeAndExpenseSummary } from 'components/steps/IncomeAndExpenseSummary'
import { ThankYouDiscovery } from 'components/steps/ThankYouDiscovery'
import { TrustPage } from 'components/steps/TrustPage'
import { GetStarted } from 'components/steps/GetStarted'
import { ARP } from 'components/Layout/constants'

const pages = Object.freeze([
  {
    component: Intro,
    id: 'intro'
  },
  {
    component: () => (
      <StepsItem>
        <Welcome />
      </StepsItem>
    ),
    id: 'welcome'
  },
  {
    component: () => (
      <StepsItem>
        <CallAgenda />
      </StepsItem>
    ),
    id: 'call_agenda'
  },
  {
    component: () => (
      <StepsItem>
        <FinancialGoals />
      </StepsItem>
    ),
    id: 'financial_goals'
  },
  {
    component: () => (
      <StepsItem>
        <ConsumerFinancialOutcomes
          compositionOrder={[
            { monthlyPayments: ['minimumPayments', 'fdr'] },
            { amortization: ['minimumPayments', 'fdr'] },
            { totalDebt: ['minimumPayments', 'fdr'] }
          ]}
          subtitle="Compare the Freedom Debt Relief program to making minimum payments."
          title="An affordable solution to reduce your debt"
        />
      </StepsItem>
    ),
    id: 'fdr_cfos_transition'
  },
  {
    component: () => (
      <StepsItem>
        <EligibleDebtSummary title={'Your debt summary'} />
      </StepsItem>
    ),
    id: 'debt_summary'
  },
  {
    component: () => (
      <StepsItem>
        <EligibleDebtSummary />
      </StepsItem>
    ),
    id: 'summary'
  },
  {
    component: () => (
      <StepsItem>
        <YourDebts componentVariation="eligible_debts" />
      </StepsItem>
    ),
    id: 'eligible_debts'
  },
  {
    component: () => (
      <StepsItem>
        <YourDebts componentVariation="eligible_debts_discovery" />
      </StepsItem>
    ),
    id: 'eligible_debts_discovery'
  },
  {
    component: () => (
      <StepsItem>
        <YourDebts componentVariation="program_eligible_debts" />
      </StepsItem>
    ),
    id: 'program_eligible_debts'
  },
  {
    component: () => (
      <StepsItem>
        <OtherDebts componentVariation="other_debts" />
      </StepsItem>
    ),
    id: 'other_debts'
  },
  {
    component: () => (
      <StepsItem>
        <OtherDebts componentVariation="secured_debts" />
      </StepsItem>
    ),
    id: 'secured_debts'
  },
  {
    component: () => <OptionEvaluation />,
    id: 'option_evaluation'
  },
  {
    component: FreedomAdvantage,
    id: 'freedom_advantage'
  },
  {
    component: () => (
      <StepsItem>
        <ConsumerFinancialOutcomes />
      </StepsItem>
    ),
    id: 'consumer_financial_outcomes'
  },
  {
    component: () => (
      <StepsItem>
        <YourSolution />
      </StepsItem>
    ),
    id: 'your_solution'
  },
  {
    component: () => (
      <StepsItem>
        <YourSolutionDiscovery />
      </StepsItem>
    ),
    id: 'your_solution_discovery'
  },
  {
    component: () => (
      <StepsItem>
        <HowItWorks />
      </StepsItem>
    ),
    id: 'how_it_works'
  },
  {
    component: () => (
      <StepsItem>
        <HowItWorks />
      </StepsItem>
    ),
    id: 'how_it_works_discovery'
  },
  {
    component: () => (
      <StepsItem>
        <TrustPage />
      </StepsItem>
    ),
    id: 'trust'
  },
  {
    component: () => (
      <StepsItem>
        <TrustPage componentVariation={ARP}/>
      </StepsItem>
    ),
    id: 'trust_arp'
  },
  {
    component: () => (
      <StepsItem>
        <WeAreIn />
      </StepsItem>
    ),
    id: 'we_are_in'
  },
  {
    component: () => (
      <StepsItem>
        <WhatOurClientsSay reviews={WHAT_OUR_CLIENT_SAY.REVIEWS_V1} />
      </StepsItem>
    ),
    id: 'what_our_clients_say'
  },
  {
    component: () => (
      <StepsItem>
        <FinancialRecovery />
      </StepsItem>
    ),
    id: 'financial_recovery'
  },
  {
    component: () => (
      <StepsItem>
        <FinancialRecovery />
      </StepsItem>
    ),
    id: 'financial_recovery_discovery'
  },
  {
    component: ThankYouDiscovery,
    id: 'thank_you_discovery'
  },
  {
    component: ThankYou,
    id: 'thank_you'
  },
  {
    component: StartPage,
    id: 'start_page'
  },
  {
    component: AboutPage,
    id: 'about_page'
  },
  {
    component: () => (
      <StepsItem>
        <YourBudgetSummary />
      </StepsItem>
    ),
    id: 'your_budget_summary'
  },
  {
    component: () => (
      <StepsItem>
        <IncomeAndExpenseSummary />
      </StepsItem>
    ),
    id: 'income_and_expense_summary'
  },
  {
    component: () => (
      <StepsItem>
        <GetStarted />
      </StepsItem>
    ),
    id: 'lets_get_started'
  },
  {
    component: () => (
      <StepsItem>
        <GetStarted callPhone />
      </StepsItem>
    ),
    id: 'lets_get_started_self_guided'
  }
])

export { pages }
