import { useLocation } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { atoms } from 'store'

/**
 * While redirectiong if we change the deck path, fetch the new content
 * Usefull when directly land on the domain with key. To support existing URL's
 */
const ContentRefetch = () => {
  // useEffect is not working and any why useLocation re-renders the component
  const location = useLocation()

  const setContentConfig = useSetRecoilState(atoms.content)
  const currentContentConfig = useRecoilValue(atoms.content)

  const { pathname } = location
  const path = `/${pathname.split('/')[1]}`
  if (currentContentConfig.path !== path) {
    setContentConfig({
      domain: window.location.host,
      language: currentContentConfig.language,
      path
    })
  }

  return null
}

export { ContentRefetch }
