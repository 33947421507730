import { selector } from 'recoil'
import traverse from 'traverse'
import { pages } from 'consts/pages'
import { navigationSelector } from './navigation'

const routesSelector = selector({
  get: ({ get }) => {
    const navigationLinks = get(navigationSelector)
    return traverse(navigationLinks.links).reduce(function (acc, node) {
      const component = pages.find(({ id }) => id === node.id)?.component
      if (node && node.path && component) {
        acc.push({
          ...node,
          component
        })
      }
      return acc
    }, [])
  },
  key: 'routes'
})

export { routesSelector }
