import { View } from './View'
import { TradelineDetailDialog } from './TradelineDetailDialog'
import { useState, useMemo } from 'react'
import { shape, string, oneOf } from 'prop-types'
import { DebtTypes } from 'hooks/useDebts/consts'
import { useDebts } from 'hooks/useDebts'
import { useContent } from 'hooks/useContent'
import { propsSpec } from 'utils/propsSpec'
import { USER_ACTION } from 'consts/clickEvent'
import { EVENT_ACTION } from '@achieve/cx-event-horizon/browser'
import { useAnalytics } from 'hooks/useAnalytics'
import { useSteps } from 'hooks/useSteps'
const tradelineEventName = 'Detail page'

const contentToPropSpec = propsSpec({
  accountLabel: 'content.0.content.accountLabel',
  dialogLabels: 'content.0.content.dialogLabels',
  subtitle: 'content.0.content.subtitle',
  subtitlePlural: 'content.0.content.subtitlePlural',
  subtitleSingular: 'content.0.content.subtitleSingular',
  title: 'title',
  totalLabel: 'content.0.content.totalLabel',
  unitLabel: 'content.0.content.unitLabel'
})

const contentHelper = (variation, content = {}) => {
  if (variation === 'eligible_debts_discovery') {
    return contentToPropSpec(content.unsecuredDebtsPage)
  } else if (variation === 'program_eligible_debts') {
    return contentToPropSpec(content.programEligibleDebtsPage)
  } else {
    return contentToPropSpec(content.eligibleDebtsPage)
  }
}

const Container = ({ componentVariation }) => {
  const debtType =
    componentVariation === 'program_eligible_debts'
      ? DebtTypes.PROGRAM_ENROLED
      : DebtTypes.ENROLED
  const { tradelines, total } = useDebts(debtType)
  const [tradelineInDialog, setTradelineInDialog] = useState(null)
  const [isTradelineDetailOpen, setTradelineDetailOpen] = useState(false)
  const analytics = useAnalytics()
  const { current } = useSteps()
  const { content } = useContent()
  const { locale } = content
  const contentProps = useMemo(
    () => contentHelper(componentVariation, content),
    [content, componentVariation]
  )

  const setAndShowTradelineDetailDialog = (tradeline) => {
    analytics.track(`${tradelineEventName} - ${USER_ACTION.CTA_CLCIKED}`, {
      event_action: EVENT_ACTION.BUTTON_CLICK,
      page_name: current.label
    })
    setTradelineInDialog(tradeline)
    setTradelineDetailOpen(true)
  }

  return (
    <>
      <View
        {...contentProps}
        onTradelineSelect={setAndShowTradelineDetailDialog}
        total={total}
        tradelines={tradelines}
      />
      {tradelineInDialog && (
        <TradelineDetailDialog
          {...tradelineInDialog}
          dialogLabels={contentProps.dialogLabels}
          handleClose={() => setTradelineDetailOpen(false)}
          locale={locale}
          open={isTradelineDetailOpen}
        />
      )}
    </>
  )
}

View.displayName = 'YourDebtsContainer'

Container.propTypes = {
  componentVariation: oneOf([
    'eligible_debts',
    'eligible_debts_discovery',
    'program_eligible_debts'
  ]),
  debtType: oneOf(Object.values(DebtTypes)),
  dialogLabels: shape({
    account: string.isRequired,
    balance: string.isRequired,
    closeLabel: string.isRequired,
    latePayment: string.isRequired,
    monthlyPayment: string.isRequired,
    utilization: string.isRequired
  }).isRequired
}

Container.defaultProps = {
  debtType: DebtTypes.ENROLED
}

export { Container }
