import React from 'react'
import { View } from './View'
import { useIncomeAndExpenses } from 'hooks/useIncomeAndExpenses'
import { useContent } from 'hooks/useContent'
import { contentHelper } from './content'

export const Container = () => {
  const { content } = useContent()
  const props = contentHelper(content)
  const { incomeAndExpense, setValues } = useIncomeAndExpenses()
  return (
    <View
      {...props}
      setValues={({ target }) => setValues(target)}
      values={incomeAndExpense}
    />
  )
}
