import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { forwardRef } from 'react'
import { Input } from '../Input'

const View = forwardRef((props, ref) => {
  const { onChange, ...other } = props
  return (
    <NumberFormat
      {...other}
      customInput={Input}
      decimalSeparator={false}
      getInputRef={ref}
      isNumericString
      onKeyDown={(event) => {
        event.stopPropagation()
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        })
      }}
      placeholder="$0"
      prefix="$"
      thousandSeparator
    />
  )
})

View.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export { View }
