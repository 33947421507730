import React from 'react'
import { View } from './View'
import { getStaticContent } from './constants'
import { useIncomeAndExpenses } from 'hooks/useIncomeAndExpenses'

export const Container = () => {
  const {
    content: { incomeAndExpenseSummary }
  } = getStaticContent()

  const { incomeAndExpense } = useIncomeAndExpenses()

  return <View {...incomeAndExpenseSummary} {...incomeAndExpense} />
}

Container.displayName = 'IncomeAndExpenseSummary.Container'
