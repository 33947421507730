import { oneOf } from 'prop-types'
import { Image } from 'components/Image'
import StarEmpty from 'components/SvgIcon/StarEmpty.svg'
import StarFull from 'components/SvgIcon/StarFull.svg'
import StarHalf from 'components/SvgIcon/StarHalf.svg'
import { fulfillment } from './constants'

const SIZE_IN_PIXELS = 16

const View = ({ fulfillment: fulfillmentTemp }) => {
  let starProps = {}

  switch (fulfillmentTemp) {
    case fulfillment.FULL:
      starProps = {
        alt: '1 full star',
        src: StarFull
      }
      break
    case fulfillment.HALF:
      starProps = {
        alt: '1 half star',
        src: StarHalf
      }
      break
    default:
      starProps = {
        alt: 'An empty star',
        src: StarEmpty
      }
      break
  }

  return (
    <Image {...starProps} height={SIZE_IN_PIXELS} width={SIZE_IN_PIXELS} />
  )
}

View.defaultProps = {
  fulfillment: fulfillment.EMPTY
}

View.propTypes = {
  fulfillment: oneOf(Object.values(fulfillment))
}

export { View }
