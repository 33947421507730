const clickEvent = Object.freeze({
  APP_FOOTER_LINK_CONTACT_US: 'app_footer_link_contact_us',
  APP_FOOTER_LINK_PRIVACY_POLICY: 'app_footer_link_privacy_policy',
  APP_FOOTER_LINK_TERMS_OF_USE: 'app_footer_link_terms_of_use',
  APP_HEADER_PHONE: 'app_header_phone',
  APP_NAVIGATION_ARROW_LEFT: 'app_navigation_arrow_left',
  APP_NAVIGATION_ARROW_RIGHT: 'app_navigation_arrow_right',
  APP_NAVIGATION_DRAWER_OPEN: 'app_navigation_drawer_open',
  APP_NAVIGATION_DROPDOWN_THE_FREEDOM_ADVANTAGE:
    'app_navigation_dropdown_the_freedom_advantage',
  APP_NAVIGATION_DROPDOWN_WELCOME: 'app_navigation_dropdown_welcome',
  APP_NAVIGATION_LINK_AN_AFFORDABLE_SOLUTION:
    'app_navigation_link_an_affordable_solution',
  APP_NAVIGATION_LINK_CALL_AGENDA: 'app_navigation_link_call_agenda',
  APP_NAVIGATION_LINK_COMPARE: 'app_navigation_link_compare',
  APP_NAVIGATION_LINK_ELIGIBLE_DEBTS: 'app_navigation_link_eligible_debts',
  APP_NAVIGATION_LINK_FDR_CFOS_TRANSITION:
    'app_navigation_link_fdr_cfos_transition',
  APP_NAVIGATION_LINK_FINANCIAL_RECOVERY:
    'app_navigation_link_financial_recovery',
  APP_NAVIGATION_LINK_HOW_IT_WORKS: 'app_navigation_link_how_it_works',
  APP_NAVIGATION_LINK_INTRO: 'app_navigation_link_intro',
  APP_NAVIGATION_LINK_OTHER_DEBTS: 'app_navigation_link_other_debts',
  APP_NAVIGATION_LINK_START: 'app_navigation_link_start',
  APP_NAVIGATION_LINK_THANK_YOU: 'app_navigation_link_thank_you',
  APP_NAVIGATION_LINK_WE_ARE_IN_YOUR_STATE:
    'app_navigation_link_we_are_in_your_state',
  APP_NAVIGATION_LINK_WHAT_OUR_CLIENTS_SAY:
    'app_navigation_link_what_our_clients_say',
  APP_NAVIGATION_LINK_YOUR_ELIGIBLE_DEBT_SUMMARY:
    'app_navigation_link_your_eligible_debt_summary',
  APP_NAVIGATION_LINK_YOUR_SOLUTION: 'app_navigation_link_your_solution',
  CFOS_FREEDOM_ADVANTAGE_LEARN_MORE: 'cfos_freedom_advantage_learn_more',
  CFOS_FREEDOM_ADVANTAGE_READ_LESS: 'cfos_freedom_advantage_read_less',
  LETS_GET_STARTED_BUTTON: 'lets_get_started_button',
  LETS_GET_STARTED_LEARN_MORE: 'lets_get_started_learn_more',
  LETS_GET_STARTED_READ_LESS: 'lets_get_started_read_less',
  YOUR_DEBTS_TRADE_LINE: 'your_debts_trade_line',
  YOUR_DEBTS_TRADE_LINE_DIALOG_CLOSE: 'your_debts_trade_line_dialog_close'
})

const USER_ACTION = Object.freeze({ CTA_CLCIKED: 'Clicked' })

export { clickEvent, USER_ACTION }
