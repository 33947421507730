import { EVENT_ACTION } from '@achieve/cx-event-horizon/browser'
import { useCallback, useEffect } from 'react'
import useTrackData from 'hooks/useTrackEvents/useTrackData'

const useTrackEvents = ({ trackPageLoadEvents = false } = {}) => {
  const {
    fdrApplicantId,
    applicationData,
    ownerId,
    pageName,
    pathname,
    analytics
  } = useTrackData()

  useEffect(() => {
    if (!trackPageLoadEvents || !fdrApplicantId) {
      return
    }
    analytics.page(applicationData.page_name, {
      application_data: applicationData,
      profile: { fdrApplicantId }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageName,
    pathname,
    fdrApplicantId,
    ownerId
  ])

  const trackCoBrowseChange = useCallback((eventName) => {
    analytics.track(eventName, {
      application_data: applicationData,
      event_action: EVENT_ACTION.APP_EVENT,
      page_name: pageName,
      profile: { fdrApplicantId }
    })
  }, [analytics, applicationData, fdrApplicantId, pageName])

  return {
    trackCoBrowseChange
  }
}

export default useTrackEvents
