const restrictedLeadKeys = Object.freeze([
  'clientMailingState',
  'creditUtilization',
  'coApplicantFirstName',
  'coApplicantLastName',
  'doNothingTotalCost',
  'estimatedCostToClient',
  'estimatedMonthlyCreditorPayment',
  'estimatedDebtMonthlyPayment',
  'estimatedDebtMonthlyPaymentMaximum',
  'estimatedDebtMonthlyPaymentMinimum',
  'estimatedProgramLength',
  'estimatedProgramMaximumLength',
  'estimatedProgramMinimumLength',
  'estimatedProgramSavings',
  'fdrApplicantId',
  'firstName',
  'lastName',
  'payDownTimeMinPayments',
  'totalEstimatedDebt',
  'ownerId'
])

export { restrictedLeadKeys }
