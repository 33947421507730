import { useLead } from 'hooks/useLead'
import { defaultTo, pipe } from 'ramda'

const example = {
  accountsInCollection: {
    current: 5,
    total: 6
  },
  allTradelinesCount: 20,
  credit: {
    max: 100,
    used: 66
  },
  eligibleDebt: 40_470.3,
  eligibleDebtRatio: {
    allTradelinesCount: 20,
    count: 9
  },
  // 9 + 20 eligibleDebtRatio + otherDebtRatio
  latePayments: {
    current: 4,
    total: 6
  },
  otherDebtRatio: {
    allTradelinesCount: 20,
    count: 11
  }
}

const toSafeNumber = pipe(defaultTo(0), parseFloat)

const leadToEligibleDebtSummary = (lead) => {
  const tradelines = lead?.eligibleDebts?.tradelines ?? []
  const otherDebtsTradelines = lead?.otherDebts?.tradelines ?? []
  const inCollection = toSafeNumber(lead?.eligibleDebts?.inCollection)
  const latePayments = toSafeNumber(lead?.eligibleDebts?.latePayments)
  const utilization = Math.round(toSafeNumber(lead?.creditUtilization))
  const eligibleDebt = toSafeNumber(lead?.totalEstimatedDebt)
  const allTradelinesCount =
    tradelines.length + otherDebtsTradelines.length

  return {
    accountsInCollection: {
      current: inCollection,
      total: tradelines.length
    },
    allTradelinesCount,
    credit: {
      max: 100,
      used: utilization
    },
    eligibleDebt,
    eligibleDebtRatio: {
      count: tradelines.length
    },
    latePayments: {
      current: latePayments,
      total: tradelines.length
    },
    otherDebtRatio: {
      count: otherDebtsTradelines.length
    }
  }
}

const useEligibleDebtSummary = () => {
  const lead = useLead()

  return leadToEligibleDebtSummary(lead)
}

export { example, useEligibleDebtSummary }
