import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'
import { Rating } from 'components/steps/WhatOurClientsSay/Rating'
import { INLINES } from '@contentful/rich-text-types'

const options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => (
      <a href={node.data.uri} rel="noreferrer" target="_blank">
        {children}
      </a>
    )
  }
}

const View = ({ content, name, rating, ratingText }) => (
  // TODO: Move the ID to qaId consts
  <div
    className={styles.trustPilotReview}
    {...qaAttributes({ id: 'trustPilot', suffix: name })}
  >
    <Rating rating={rating} text={ratingText} />
    <div className={styles.reviews}>
      {documentToReactComponents(content, options)}
    </div>
  </div>
)

View.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]).isRequired,
  name: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  ratingText: PropTypes.string.isRequired
}

View.displayName = 'TextView'

export { View }
