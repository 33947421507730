import cx from 'classnames'
import styles from './index.module.scss'
import { node, func, bool, string } from 'prop-types'
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core'
import { ArrowLeft } from 'components/SvgIcon'
import { qaAttributes } from 'utils/qaAttributes'
import { qaId } from 'consts/qaId'

const View = ({ children, id, title, onListTitleClick, isOpen }) => (
  <>
    <ListItem
      {...qaAttributes({ id: `${qaId.NAV_MOBILE_PREFIX}-open-menu` })}
      button
      onClick={onListTitleClick}
    >
      <ListItemText
        classes={{
          primary: styles.link
        }}
        disableTypography
        id={`${id}_mobile`}
        primary={title}
      />

      <ArrowLeft
        className={cx(styles.arrow, {
          [styles.isOpen]: isOpen,
          [styles.isClosed]: !isOpen
        })}
      />
    </ListItem>

    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List className={styles.list} component="div" disablePadding>
        {children}
      </List>
    </Collapse>
  </>
)

View.propTypes = {
  children: node.isRequired,
  id: string.isRequired,
  isOpen: bool,
  onListTitleClick: func.isRequired,
  title: node.isRequired
}

View.displayName = 'Dropdown.view'

View.propTypes = {
  title: node.isRequired
}

export { View }
