import { string, shape, number, arrayOf, func } from 'prop-types'
import { StepHeader } from 'components/StepHeader'
import { TradelineTable } from 'components/TradelineTable'
import { Tradeline } from './Tradeline'
import styles from './index.module.scss'

const View = ({
  accountLabel,
  tradelines,
  onTradelineSelect,
  total,
  subtitlePlural,
  subtitleSingular,
  title,
  totalLabel,
  unitLabel
}) => (
  <>
    <StepHeader
      subtitle={`${subtitleSingular[0]} ${tradelines.length} ${tradelines.length > 1 ? subtitlePlural[1] : subtitleSingular[1]}`}
      title={title}
    />

    <TradelineTable
    accountLabel={accountLabel}
    className={styles.container}
    total={total}
    totalLabel={totalLabel}
    unitLabel={unitLabel}
    >
      {tradelines.map((tradeline, index) => (
        <Tradeline
          index={index}
          onClick={() => onTradelineSelect(tradeline)}
          {...tradeline}
          key={`${tradeline.accountNumber}-${index}`}
        />
      ))}
    </TradelineTable>
  </>
)

View.propTypes = {
  accountLabel: string.isRequired,
  onTradelineSelect: func,
  subtitlePlural: arrayOf(string).isRequired,
  subtitleSingular: arrayOf(string).isRequired,
  title: string.isRequired,
  total: number,
  totalLabel: string.isRequired,
  tradelines: arrayOf(
    shape({
      accountNumber: string.isRequired,
      balance: number,
      creditor: string.isRequired,
      monthlyPayment: number,
      utilization: number
    })
  ).isRequired,
  unitLabel: string.isRequired
}

View.defaultProps = {
  accountLabel: 'Creditor',
  subtitlePlural: ['You have', 'accounts that may be eligible for one of our solutions.'],
  subtitleSingular: ['You have', 'account that may be eligible for one of our solutions.'],
  totalLabel: 'TOTAL',
  unitLabel: '$ Amount owed'
}

View.displayName = 'YourDebts'

export { View }
