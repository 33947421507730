import { qaId } from 'consts/qaId'
import { node, string, func } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const View = ({ children, footer: Footer, title, id }) => (
  <section className={styles.container}>
    {title && (
      <h2
        {...qaAttributes({
          id: qaId.DATA_SECTION_TITLE,
          suffix: id
        })}
        className={styles.title}
      >
        {title}
      </h2>
    )}
    <p
      {...qaAttributes({
        id: qaId.DATA_SECTION_CONTENT,
        suffix: id
      })}
      className={styles.content}
    >
      {children}
    </p>
    {Footer && (
      <p
        {...qaAttributes({
          id: qaId.DATA_SECTION_FOOTER,
          suffix: id
        })}
        className={styles.footer}
      >
        <Footer />
      </p>
    )}
  </section>
)

View.propTypes = {
  children: node.isRequired,
  footer: func,
  id: string,
  title: string
}

View.displayName = 'DebtHeader'

export { View }
