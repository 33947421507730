import React from 'react'
import {
  string,
  arrayOf,
  oneOfType,
  shape,
  number,
  bool,
  func
} from 'prop-types'
import { Button } from '@material-ui/core'

import { StepHeader } from 'components/StepHeader'
import { ArrowRight, Phone } from 'components/SvgIcon'
import { TitleTooltip } from 'components/steps/ConsumerFinancialOutcomes/Section/Title'
import { LegalFooter } from './LegalFooter'

import { qaAttributes } from 'utils/qaAttributes'
import { clickEvent } from 'consts/clickEvent'
import { qaId } from 'consts/qaId'
import styles from './index.module.scss'
import { ARP } from '../../Layout/constants'

const ValuePropSection = ({ subtitle, testId, title, value }) => {
  if (subtitle && title && value) {
    return (
  <div className={styles.valuePropSection}>
    <span
      className={styles.valuePropTitle}
      {...qaAttributes({
        id: qaId.LETS_GET_STARTED_VALUE_PROP,
        suffix: `${testId}-title`
      })}
    >
      {title}
    </span>
    <span
      className={styles.valuePropSubtitle}
      {...qaAttributes({
        id: qaId.LETS_GET_STARTED_VALUE_PROP,
        suffix: `${testId}-subtitle`
      })}
    >
      {subtitle.text}
      {subtitle.hasTooltip && (
        <TitleTooltip
          superText={subtitle.footnoteNumber}
          testId={`${qaId.LETS_GET_STARTED_VALUE_PROP}-tooltip-${subtitle.footnoteNumber}`}
          toolTip={subtitle.toolTip}
        />
      )}
    </span>
    <span
      className={styles.valuePropValue}
      {...qaAttributes({
        id: qaId.LETS_GET_STARTED_VALUE_PROP,
        suffix: `${testId}-value`
      })}
    >
      {value}
    </span>
  </div>)
  }
  return null
}

ValuePropSection.propTypes = {
  subtitle: shape({
    footnoteNumber: number,
    hasTooltip: bool.isRequired,
    text: string.isRequired,
    toolTip: string
  }),
  testId: string.isRequired,
  title: string.isRequired,
  value: oneOfType([string.isRequired, number.isRequired]).isRequired
}

const ArrowIcon = () => (
  <div className={styles.arrowIconWrapper}>
    <ArrowRight className={styles.arrowIcon} />
  </div>
)

const PhoneIcon = () => (
  <div className={styles.phoneIconWrapper}>
    <Phone className={styles.phoneIcon} />
  </div>
)

const View = ({
  buttonLabel,
  valueProps,
  title,
  phoneHref,
  isSelfGuided,
  ctaOnClick,
  footerText,
  description
}) => {
  const hideBtn = description === ARP
  return (
  <div className={styles.container}>
    <StepHeader title={title} />
    <div className={styles.content}>
      {valueProps.map((vp) => (
        <ValuePropSection key={vp.testId} {...vp} />
      ))}
    </div>
    {!hideBtn &&
 <Button
 {...qaAttributes({
   id: qaId.LETS_GET_STARTED_BUTTON
 })}
 className={
   isSelfGuided ? styles.getStartedButton : styles.getStartedButtonNoPhone
 }
 color="primary"
 endIcon={!isSelfGuided && <ArrowIcon />}
 href={phoneHref}
 id={clickEvent.LETS_GET_STARTED_BUTTON}
 onClick={ctaOnClick}
 startIcon={isSelfGuided && <PhoneIcon />}
 target={phoneHref && '_parent'}
>
 {buttonLabel}
</Button>
    }

    <LegalFooter footerText={footerText} />
  </div>
  )
}
View.displayName = "Let's get started"

View.propTypes = {
  buttonLabel: string.isRequired,
  ctaOnClick: func.isRequired,
  description: string,
  footerText: shape({
    collapse: string.isRequired,
    disclaimer: string.isRequired,
    expand: string.isRequired
  }).isRequired,
  isSelfGuided: bool,
  phoneHref: string,
  title: string.isRequired,
  valueProps: arrayOf(
    shape({
      subtitle: shape({
        footnoteNumber: number,
        hasTooltip: bool.isRequired,
        text: string.isRequired,
        toolTip: string
      }),
      testId: string.isRequired,
      title: string.isRequired,
      value: oneOfType([string.isRequired, number.isRequired]).isRequired
    })
  ).isRequired
}

View.defaultProps = {
  buttonLabel: 'Get Started Today!',
  description: undefined,
  footerText: {
    collapse: 'Read less',
    expand: 'Learn more about our estimates'
  },
  isSelfGuided: false,
  phoneHref: '',
  title: "Let's get started",
  valueProps: []
}

export { View }
