const generateContentData = (gaps, percentages, formatted) => {
  if (!gaps && !percentages && !formatted) {
    return ''
  }

  return Object.keys(formatted).reduce((accumulator, source) => {
    accumulator[source] = Object.keys(formatted[source]).reduce(
      (row, rowName) => {
        const range = formatted[source][rowName]
        row[rowName] = {
          hasGap: gaps[source][rowName],
          percentage: percentages[source][rowName]
        }
        if (typeof (range) === 'object') {
          Object.assign(row[rowName], range)
        } else {
          row[rowName].value = range
        }
        return row
      },
      {}
    )

    return accumulator
  }, {})
}

export { generateContentData }
