const usState = Object.freeze({
  AK: { code: 'AK', name: 'Alaska' },
  AL: { code: 'AL', name: 'Alabama' },
  AR: { code: 'AR', name: 'Arkansas' },
  AS: { code: 'AS', name: 'American Samoa' },
  AZ: { code: 'AZ', name: 'Arizona' },
  CA: { code: 'CA', name: 'California' },
  CO: { code: 'CO', name: 'Colorado' },
  CT: { code: 'CT', name: 'Connecticut' },
  DC: { code: 'DC', name: 'District Of Columbia' },
  DE: { code: 'DE', name: 'Delaware' },
  FL: { code: 'FL', name: 'Florida' },
  FM: { code: 'FM', name: 'Federated States Of Micronesia' },
  GA: { code: 'GA', name: 'Georgia' },
  GU: { code: 'GU', name: 'Guam' },
  HI: { code: 'HI', name: 'Hawaii' },
  IA: { code: 'IA', name: 'Iowa' },
  ID: { code: 'ID', name: 'Idaho' },
  IL: { code: 'IL', name: 'Illinois' },
  IN: { code: 'IN', name: 'Indiana' },
  KS: { code: 'KS', name: 'Kansas' },
  KY: { code: 'KY', name: 'Kentucky' },
  LA: { code: 'LA', name: 'Louisiana' },
  MA: { code: 'MA', name: 'Massachusetts' },
  MD: { code: 'MD', name: 'Maryland' },
  ME: { code: 'ME', name: 'Maine' },
  MH: { code: 'MH', name: 'Marshall Islands' },
  MI: { code: 'MI', name: 'Michigan' },
  MN: { code: 'MN', name: 'Minnesota' },
  MO: { code: 'MO', name: 'Missouri' },
  MP: { code: 'MP', name: 'Northern Mariana Islands' },
  MS: { code: 'MS', name: 'Mississippi' },
  MT: { code: 'MT', name: 'Montana' },
  NC: { code: 'NC', name: 'North Carolina' },
  ND: { code: 'ND', name: 'North Dakota' },
  NE: { code: 'NE', name: 'Nebraska' },
  NH: { code: 'NH', name: 'New Hampshire' },
  NJ: { code: 'NJ', name: 'New Jersey' },
  NM: { code: 'NM', name: 'New Mexico' },
  NV: { code: 'NV', name: 'Nevada' },
  NY: { code: 'NY', name: 'New York' },
  OH: { code: 'OH', name: 'Ohio' },
  OK: { code: 'OK', name: 'Oklahoma' },
  OR: { code: 'OR', name: 'Oregon' },
  PA: { code: 'PA', name: 'Pennsylvania' },
  PR: { code: 'PR', name: 'Puerto Rico' },
  PW: { code: 'PW', name: 'Palau' },
  RI: { code: 'RI', name: 'Rhode Island' },
  SC: { code: 'SC', name: 'South Carolina' },
  SD: { code: 'SD', name: 'South Dakota' },
  TN: { code: 'TN', name: 'Tennessee' },
  TX: { code: 'TX', name: 'Texas' },
  UT: { code: 'UT', name: 'Utah' },
  VA: { code: 'VA', name: 'Virginia' },
  VI: { code: 'VI', name: 'Virgin Islands' },
  VT: { code: 'VT', name: 'Vermont' },
  WA: { code: 'WA', name: 'Washington' },
  WI: { code: 'WI', name: 'Wisconsin' },
  WV: { code: 'WV', name: 'West Virginia' },
  WY: { code: 'WY', name: 'Wyoming' }
})

export { usState }
