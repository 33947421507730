import { Button, Drawer, List } from '@material-ui/core'
import { Logo } from 'components/Logo'
import { X } from 'components/SvgIcon'
import { qaId } from 'consts/qaId'
import { bool, func, array } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
// import { links } from '../constants'
import { Dropdown } from './Dropdown'
import styles from './index.module.scss'
import { Item } from './Item'

const View = ({ isOpen, onClose, links }) => (
  <Drawer
    ModalProps={{
      keepMounted: true // Better open performance on mobile.
    }}
    classes={{
      paper: styles.paper
    }}
    onClose={onClose}
    open={isOpen}
  >
    <div role="presentation">
      <div className={styles.header}>
        <Logo className={styles.logo} />
        <Button
          {...qaAttributes({ id: qaId.NAV_MOBILE_CLOSE })}
          onClick={onClose}
        >
          <X />
        </Button>
      </div>

      <List>
        {links
          .filter(({ hideInMenu }) => !hideInMenu)
          .map(({ clickEventId, label, links: innerLinks, path, id }) =>
            !Array.isArray(innerLinks)
              ? (
              <Item
                key={label}
                id={clickEventId}
                pageId={id}
                title={label}
                to={path}
              />
                )
              : (
              <Dropdown key={label} id={clickEventId} title={label}>
                {innerLinks
                  .filter(({ hideInMenu }) => !hideInMenu)
                  .map(
                    ({ clickEventId: innerClickEventId, label, path, id }) => (
                      <Item
                        key={label}
                        id={innerClickEventId}
                        pageId={id}
                        title={label}
                        to={path}
                      />
                    )
                  )}
              </Dropdown>
                )
          )}
      </List>
    </div>
  </Drawer>
)

View.displayName = 'MobileNav.View'

View.propTypes = {
  isOpen: bool,
  links: array,
  onClose: func
}

export { View }
