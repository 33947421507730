import { useState } from 'react'
import { element, node, oneOfType } from 'prop-types'
import { useOmitCfos } from 'hooks/useOmitCfos'
import { cfosPages } from 'components/Layout/constants'
import { View } from './View'

const Container = ({ children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { omitCfos } = useOmitCfos()
  const handleClick = () => {
    setIsOpen(!isOpen)
  }
  const menuLinks = omitCfos
    ? children.filter(({ id }) => !cfosPages.includes(id))
    : children

  return (
    <View
      children={menuLinks}
      isOpen={isOpen}
      onListTitleClick={handleClick}
      {...props}
    />
  )
}

Container.displayName = 'Dropdown.container'

Container.propTypes = {
  children: oneOfType([element, node])
}

export { Container }
