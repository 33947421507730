import { useEffect } from 'react'
import axios from 'axios'
import { httpStatus } from 'consts/httpStatus'
import { useRecoilState } from 'recoil'
import { atoms } from 'store'

const PATHNAME = '/api/clientEnv'

let isInit = false

const useEnv = () => {
  const [env, setEnv] = useRecoilState(atoms.env)
  const isLoading = env === null && !isInit

  useEffect(() => {
    if (isInit) {
      return
    }

    const fetchConfig = async () => {
      try {
        const { data: runtimeVariables } = await axios.get(PATHNAME)

        setEnv(runtimeVariables)
      } catch (error) {
        if (error?.response?.status !== httpStatus.NOT_FOUND) {
          // eslint-disable-next-line no-console
          console.warn('App runtime env was not loaded', error)
          setEnv({})
        }
      } finally {
        isInit = true
      }
    }

    fetchConfig()
  }, [setEnv])

  return { env, isLoading }
}

export { useEnv }
