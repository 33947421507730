const fdr = ({ max, min }) => {
  if (min < 12) {
    // Show in months
    return {
      units: 'months',
      value: `${Math.floor(min)}-${Math.ceil(max)}`
    }
  }

  // There is no min or max
  if (min === max) {
    return {
      units: 'years',
      value: `${Math.floor(min / 12)}`
    }
  }

  return {
    units: 'years',
    value: `${Math.floor(min / 12)}-${Math.ceil(max / 12)}*`
  }
}

const minimumPayments = (months) => {
  if (months < 12) {
    // Show in months
    return {
      units: 'months',
      value: months
    }
  }

  return {
    units: 'years',
    value: Math.ceil(months / 12)
  }
}

export { fdr, minimumPayments }
