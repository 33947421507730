import { useEffect } from 'react'
import { either, isEmpty, isNil } from 'ramda'
import { useEnv } from 'hooks/useEnv'
import { useIsCoBrowse } from 'hooks/useIsCoBrowse'

// https://github.com/upscopeio/js-api#upscopeinit-
const options = {
  allowRemoteClick: true,
  allowRemoteScroll: true,
  allowRemoteType: true,
  showUpscopeLink: false
}

const isNullOrEmpty = either(isNil, isEmpty)

const Container = () => {
  const { env, isLoading } = useEnv()
  // eslint-disable-next-line no-unused-vars
  const [_, beCoBrowse] = useIsCoBrowse()

  useEffect(() => {
    const load = function (w, u, d) {
      const { UPSCOPE_PUBLIC_API_KEY } = env

      const i = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args) {
        i.q.push(args)
      }
      const l = function () {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = `https://code.upscope.io/${UPSCOPE_PUBLIC_API_KEY}.js`

        const x = d.getElementsByTagName('script')[0] || d.body
        x.parentNode.insertBefore(s, x)
      }
      if (typeof u !== 'function') {
        w.Upscope = i
        l()
      }
    }

    if (!window.Upscope && !isLoading && !isNullOrEmpty(env)) {
      const onSessionRequestedOrContinued = (
        isAuthorize
      ) => {
        if (isAuthorize) {
          isAuthorize(true)
        }
        beCoBrowse(true)
      }

      const eventHandlers = {
        onSessionContinue: onSessionRequestedOrContinued,
        onSessionEnd () {
          beCoBrowse(false)
        }
        // NOTE: On Start is handled in Authorized, if we put in a custom hanlder here, we have to show a custom notification to user also
      }

      load(window, window.Upscope, document)

      window.Upscope('init', { ...options, ...eventHandlers })
    }
  }, [env, isLoading, beCoBrowse])

  return null
}

export { Container }
