import { useContent } from 'hooks/useContent'
import { useSteps } from 'hooks/useSteps'
import { propsSpec } from 'utils/propsSpec'

const getHeaderContent = propsSpec({
  logoUrl: 'brandImage.file.url',
  logoWhiteUrl: 'brandImageLight.file.url',
  phone: {
    href: 'phoneHref',
    phoneNumber: 'phoneNumber'
  }
})

const useHeader = () => {
  const { content } = useContent()
  const { current } = useSteps()
  const header = getHeaderContent(current?.header || content.header)

  return header
}

export { useHeader, getHeaderContent }
