import { useSetDarkMode } from 'hooks/useDarkMode'
import { useLead } from 'hooks/useLead'
import { View } from './View'

const Container = () => {
  useSetDarkMode({ backgroundImage: './intro-bg.jpg', hideHeader: true })
  const {
    firstName,
    lastName,
    coApplicantFirstName,
    coApplicantLastName
  } = useLead()

  return (
    <View
      coApplicantFirstName={coApplicantFirstName}
      coApplicantLastName={coApplicantLastName}
      firstName={firstName}
      lastName={lastName}
    />
  )
}

export { Container }
