import { useOmitCfos } from 'hooks/useOmitCfos'
import { useSteps } from 'hooks/useSteps'
import { RedirectWithQuery } from 'components/RedirectWithQuery'
import { StepContent } from './StepContent'

const Container = ({ ...props }) => {
  const { omitCfos } = useOmitCfos()
  const { all, current, currentIndex, previousIndex } = useSteps()
  const redirectTo = previousIndex > currentIndex
    ? all[currentIndex - 1]?.path
    : all[currentIndex + 1]?.path

  return omitCfos
    ? (
    <RedirectWithQuery to={{
      pathname: redirectTo,
      state: {
        prevPath: current?.path
      }
    }} />
      )
    : (
    <StepContent {...props}/>
      )
}

Container.displayName = 'ConsumerFinancialOutcomes.Container'
export { Container }
