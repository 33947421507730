import cx from 'classnames'
import { oneOf, string } from 'prop-types'
import styles from './index.module.scss'

const decoding = {
  ASYNC: 'async',
  AUTO: 'auto',
  SYNC: 'sync'
}

const loading = {
  AUTO: 'auto',
  EAGER: 'eager',
  LAZY: 'lazy'
}

const View = ({
  alt,
  avif,
  className,
  decoding: decodingTmp,
  jpg,
  loading: loadingTmp,
  maxWidth,
  png,
  src,
  svg,
  webp,
  ...props
}) => (
  <picture className={styles.container}>
    {avif && <source data-testid="avif" srcSet={avif} type="image/avif" />}
    {webp && <source data-testid="webp" srcSet={webp} type="image/webp" />}
    {svg && <source data-testid="svg" srcSet={svg} type="image/svg" />}
    {png && <source data-testid="png" srcSet={png} type="image/png" />}
    {jpg && <source data-testid="jpg" srcSet={jpg} type="image/jpg" />}
    <img
      {...props}
      alt={alt}
      className={cx(styles.image, className)}
      decoding={decodingTmp}
      loading={loadingTmp}
      src={src}
    />
  </picture>
)

View.defaultProps = {
  className: undefined,
  decoding: decoding.ASYNC,
  loading: loading.LAZY,
  maxWidth: '100%'
}

View.propTypes = {
  alt: string.isRequired,
  avif: string,
  className: string,
  decoding: oneOf(Object.values(decoding)),
  jpg: string,
  loading: oneOf(Object.values(loading)),
  maxWidth: string,
  png: string,
  src: string.isRequired,
  svg: string,
  webp: string
}

export { View }
