import { node } from 'prop-types'
import styles from './index.module.scss'

const View = ({ children }) => (
  <div className={styles.container}>{children}</div>
)

View.displayName = 'StepItem.View'

View.propTypes = {
  children: node.isRequired
}

export { View }
