import { qaId } from 'consts/qaId'
import { number, string } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const View = ({ author, index, review, link }) => (
  <li className={styles.container}>
    <blockquote
      {...qaAttributes({
        id: `${qaId.WHAT_OUR_CLIENTS_SAY_REVIEWS_PREFIX}-quote-${index}`
      })}
      dangerouslySetInnerHTML={{ __html: `"${review}"` }}
      className={styles.quote}
    />
    <cite
      {...qaAttributes({
        id: `${qaId.WHAT_OUR_CLIENTS_SAY_REVIEWS_PREFIX}-author-${index}`
      })}
      className={styles.author}
    >
      <a href={link} rel="noopener noreferrer" target="_blank">
        {`– ${author}`}
      </a>
    </cite>
  </li>
)

View.propTypes = {
  author: string.isRequired,
  index: number.isRequired,
  link: string,
  review: string.isRequired
}

export { View }
