const sectionList = Object.freeze({
  amortization: {
    footnote:
      'Time to pay down your debts is the total estimated number of months it would take for you to pay your debts in full if you pay only the minimum monthly payment due to your creditors each month.',
    footnoteNumber: 2,
    title: 'Reduce time to pay down your debt'
  },
  monthlyPayments: {
    footnote:
      'Monthly payment is the total estimated minimum monthly payment due to your creditors for your eligible debt accounts.',
    footnoteNumber: 1,
    title: 'Pay less each month'
  },
  totalDebt: {
    footnote:
      'Total cost is the estimated total amount you will pay to your creditors over time if you pay only the minimum monthly payment due to your creditors each month.',
    footnoteNumber: 3,
    title: 'Reduce what you owe'
  }
})

const defaultCompositionOrder = Object.freeze([
  { monthlyPayments: ['fdr', 'minimumPayments'] },
  { amortization: ['fdr', 'minimumPayments'] },
  { totalDebt: ['fdr', 'minimumPayments'] }
])

const defaultTitleSubtitle = Object.freeze({
  subtitle: "See Freedom's program vs making minimum payments yourself",
  title: 'Compare'
})

export { sectionList, defaultCompositionOrder, defaultTitleSubtitle }
