import cx from 'classnames'
import { Grid } from '@material-ui/core'
import { node, number, string, oneOf } from 'prop-types'
import { TotalRow } from './TotalRow'
import styles from './index.module.scss'
import { qaAttributes } from 'utils/qaAttributes'
import { qaId } from 'consts/qaId'

const View = ({
  accountLabel,
  children,
  className,
  total,
  totalLabel,
  amountAlign,
  unitLabel,
  ...props
}) => (
  <Grid container {...props} className={cx(className, styles.root)}>
    <Grid
      {...qaAttributes({
        id: qaId.YOUR_DEBTS_CREDITOR_HEADER_CREDITOR
      })}
      className={styles.header}
      item
      xs={6}
    >
      {accountLabel}
    </Grid>
    <Grid
      {...qaAttributes({
        id: qaId.YOUR_DEBTS_CREDITOR_HEADER_AMOUNT_OWED
      })}
      align={amountAlign}
      className={cx(styles.header, styles.lastColumn)}
      item
      xs={6}
    >
      {unitLabel}
    </Grid>
    {children}
    <TotalRow amountAlign={amountAlign} label={totalLabel} value={total} />
  </Grid>
)

View.propTypes = {
  accountLabel: string.isRequired,
  amountAlign: oneOf(['left', 'right']),
  children: node.isRequired,
  className: string,
  total: number,
  totalLabel: string,
  unitLabel: string.isRequired
}

View.defaultProps = {
  accountLabel: 'Creditor',
  amountAlign: 'left',
  totalLabel: 'TOTAL',
  unitLabel: 'Amount Owed'
}

View.displayName = 'TradelineTable'

export { View }
