import ChatBubble from 'components/SvgIcon/ChatBubble.svg'
import Finance from 'components/SvgIcon/Finance.svg'
import ThumbsUp from 'components/SvgIcon/ThumbsUp.svg'
import User from 'components/SvgIcon/User.svg'

const standard = {
  items: [
    {
      src: Finance,
      text:
        'The money you would have paid to creditors is saved in an FDIC-insured account that you control.',
      title: 'Build your funds'
    },
    {
      src: ChatBubble,
      text:
        'When enough funds build, we negotiate with your creditors to settle your debts for less than you owe.',
      title: 'Negotiation'
    },
    {
      src: ThumbsUp,
      text:
        'When an agreement is reached, we send the terms to you for approval and process payments to the creditor from your account.',
      title: 'Settlement'
    },
    {
      src: User,
      text:
        'When all enrolled debts go through this process, you graduate the program and are free of this debt!',
      title: 'Freedom'
    }
  ],
  subtitle:
    'Debt resolution is an effective 4-step process to help you achieve your short and long-term goals.',
  title: 'How It Works'
}

const discovery = {
  items: [
    {
      src: Finance,
      text:
        'Your monthly program payments are deposited in an FDIC-insured account you control.',
      title: 'Deposits'
    },
    {
      src: ChatBubble,
      text:
        'When enough funds accrue, we negotiate with your creditors to settle your debts for less than you owe.',
      title: 'Negotiation'
    },
    {
      src: ThumbsUp,
      text:
        'When an agreement is reached, we send the terms to you for approval so we can process payments to the creditor from your account.',
      title: 'Settlement'
    },
    {
      src: User,
      text:
        'Once all the debts you enrolled in the program go through these three steps and are fully resolved, you graduate from the program and are free of the debt!',
      title: 'Freedom'
    }
  ],
  subtitle:
    'We help you overcome debt using our 4-step debt settlement process.',
  title: 'How our program works'
}

export { standard, discovery }
