import cx from 'classnames'
import { node, string } from 'prop-types'
import styles from './index.module.scss'

const View = ({ className, ...props }) => (
  <ul {...props} className={cx(styles.container, className)} />
)

View.propTypes = {
  children: node.isRequired,
  className: string
}

export { View }
