import { arrayOf, shape, node, string, oneOfType, bool } from 'prop-types'
import { StepHeader } from 'components/StepHeader'
import styles from './index.module.scss'
import Box from '@material-ui/core/Box'
import { qaId } from 'consts/qaId'
import { qaAttributes } from 'utils/qaAttributes'

const View = ({ title, items }) => (
  <div className={styles.container}>
    <StepHeader className={styles.stepHeader} title={title} />
    {items.map(({ text, img, title }, index) => (
      <Box
        {...qaAttributes({ id: `${qaId.OPTION_EVALUATION_ITEM}-${index + 1}` })}
        key={title}
        alignItems="flex-start"
        className={styles.item}
        display="flex"
      >
        <img alt={title} src={img} />
        <div className={styles.text}>
          <div
            className={styles.label}
            {...qaAttributes({
              id: `${qaId.OPTION_EVALUATION_ITEM_LABEL}-${index + 1}`
            })}
          >
            {title}
          </div>
          <div
            {...qaAttributes({
              id: `${qaId.OPTION_EVALUATION_ITEM_DESCRIPTION}-${index + 1}`
            })}
          >
            {text}
          </div>
        </div>
      </Box>
    ))}
  </div>
)

View.propTypes = {
  items: arrayOf(
    shape({
      img: node,
      text: string,
      title: string
    })
  ).isRequired,
  title: oneOfType([bool, string]).isRequired
}

View.defaultProps = {
  title: 'Your options'
}

View.displayName = 'OptionEvaluation'

export { View }
