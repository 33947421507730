import { Image } from 'components/Image'
import { qaId } from 'consts/qaId'
import { usState } from 'consts/usState'
import { oneOf } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import { map, region } from './constants'
import styles from './index.module.scss'

const View = ({ regionCode }) => {
  const isStateMap = Boolean(usState[regionCode])

  const imageProps = isStateMap
    ? {
        alt: `A state map of ${region[regionCode].name}`,
        src: map[regionCode]
      }
    : {
        alt: 'A condensed map of the United States',
        src: map.USA
      }

  return (
    <div className={styles.container}>
      <Image
        {...qaAttributes({
          id: `${qaId.WE_ARE_IN_STATE_PREFIX}-${(isStateMap
            ? regionCode
            : 'USA'
          ).toLowerCase()}`
        })}
        {...imageProps}
        className={styles.region}
        height={180}
      />
    </div>
  )
}

View.propTypes = {
  regionCode: oneOf(Object.keys(region).sort()).isRequired
}

View.displayName = 'RegionMap'

export { View }
