import { CircularProgress } from '@material-ui/core'
import cx from 'classnames'
import { StepHeader } from 'components/StepHeader'
import { bool } from 'prop-types'
import styles from './index.module.scss'

const View = ({ isDark }) => (
  <div
    aria-busy
    aria-live="polite"
    aria-valuetext="Please wait while your page is loaded"
    className={styles.container}
    role="progressbar"
  >
    <StepHeader
      subtitle={
        <CircularProgress
          className={cx(styles.progress, { [styles.dark]: isDark })}
          variant="indeterminate"
        />
      }
      title="Loading..."
    />
  </div>
)

View.propTypes = {
  isDark: bool
}

View.displayName = 'PageLoadingSpinner.View'

export { View }
