import { Grid } from '@material-ui/core'
import cx from 'classnames'
import { qaId } from 'consts/qaId'
import { number, oneOf, string } from 'prop-types'
import { currencyFormat } from 'utils/currencyFormat'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const View = ({ label, value, amountAlign }) => (
  <>
    <Grid
      {...qaAttributes({
        id: qaId.YOUR_DEBTS_CREDITOR_FOOTER_TOTAL
      })}
      className={cx(styles.totalColumn, styles.label)}
      item
      xs={6}
    >
      {label}
    </Grid>
    <Grid
      {...qaAttributes({
        id: qaId.YOUR_DEBTS_CREDITOR_FOOTER_TOTAL_VALUE
      })}
      align={amountAlign}
      className={styles.totalColumn}
      item
      xs={6}
    >
      {currencyFormat(value)}
    </Grid>
  </>
)

View.propTypes = {
  amountAlign: oneOf(['left', 'right']),
  label: string,
  value: number
}

View.defaultProps = {
  amountAlign: 'left',
  label: 'TOTAL'
}

export { View }
