import { StepHeader } from 'components/StepHeader'
import styles from './index.module.scss'
import { Rating } from './Rating'
import { Reviews } from './Reviews'
import { arrayOf, shape, string, oneOfType, bool } from 'prop-types'
import { STAR_RATING } from './constants'

const View = ({ title, reviews }) => (
  <div className={styles.container}>
    <StepHeader title={title} />
    <div className={styles.spacerTop} />
    <Rating rating={STAR_RATING} />
    <div className={styles.spacerBottom} />
    <Reviews reviews={reviews} />
  </div>
)

View.propTypes = {
  reviews: arrayOf(
    shape({
      author: string.isRequired,
      link: string,
      review: string.isRequired
    })
  ).isRequired,
  title: oneOfType([bool, string]).isRequired
}

View.defaultProps = {
  title: 'What Our Clients Say'
}

View.displayName = 'State'

export { View }
