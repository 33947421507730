import { AnimatePresence, motion } from 'framer-motion'
import { object, oneOf, shape, string, array } from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Layout } from 'components/Layout'
import { RedirectWithQuery } from 'components/RedirectWithQuery'
import { KeyboardNavigate } from 'components/KeyboardNavigate'
import { Authorized as UpscopeAuthorized } from 'components/Upscope'

const View = ({
  match,
  location,
  horizontalScrollDirection,
  transitionVariants,
  pageTransition,
  routes,
  normalizedRedirect
}) => (
  <Layout path={match.url}>
    <AnimatePresence custom={horizontalScrollDirection} initial={false}>
      <Switch key={location.pathname} location={location}>
        <RedirectWithQuery exact from={match.url} to={normalizedRedirect} />

        <RedirectWithQuery
          from="/:url*(/+)"
          to={location.pathname.slice(0, -1)}
        />

        {routes.map(({ path, component: Component }) => (
          <Route
            key={path}
            component={(props) => (
              <motion.div
                animate="center"
                className="framer-motion-div"
                custom={horizontalScrollDirection}
                exit="exit"
                initial="enter"
                transition={pageTransition}
                variants={transitionVariants}
              >
                <Component {...props} />
              </motion.div>
            )}
            path={`${match.path}/${path}`}
          />
        ))}

        <Redirect push to="/404" />
      </Switch>
    </AnimatePresence>
    <KeyboardNavigate />
    <UpscopeAuthorized />
  </Layout>
)

View.displayName = 'StepsLayout'

View.propTypes = {
  horizontalScrollDirection: oneOf([-1, 1]),
  location: shape({
    pathname: string.isRequired
  }).isRequired,
  match: shape({
    path: string.isRequired
  }).isRequired,
  normalizedRedirect: string.isRequired,
  pageTransition: object.isRequired,
  routes: array.isRequired,
  transitionVariants: object.isRequired
}

export { View }
