import { featureFlags } from 'api'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { atoms } from 'store'

const useFeatureFlags = () => {
  const [flags, setFlags] = useRecoilState(atoms.featureFlags)
  const isLoading = flags === atoms.featureFlagsInitialState

  useEffect(() => {
    if (!isLoading) {
      return
    }

    const fetchConfig = async () => {
      const data = await featureFlags.getAll()

      setFlags(data)
    }

    fetchConfig()
  }, [flags, isLoading, setFlags])

  return { featureFlags: flags, isLoading }
}

export { useFeatureFlags }
