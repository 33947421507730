import cx from 'classnames'
import { oneOf, string } from 'prop-types'
import { ReactComponent as SvgPhone } from './phone.svg'
import { variant as phoneVariant } from './constants'
import styles from './index.module.scss'
import { Hidden } from '@material-ui/core'

const View = ({ variant, className, href, phoneNumber, ...props }) => (
  <a
    className={cx(styles.freedomPhone, styles[variant], className)}
    href={href}
    target="_parent"
    {...props}
  >
    <SvgPhone alt="Freedom phone" className={styles.svg} />
    <Hidden only="xs">
      <span className={styles.number}>{phoneNumber}</span>
    </Hidden>
  </a>
)

View.defaultProps = {
  variant: phoneVariant.BLUE
}

View.propTypes = {
  className: string,
  href: string,
  phoneNumber: string,
  variant: oneOf(Object.values(phoneVariant))
}

export { View }
