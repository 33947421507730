import { useLocation } from 'react-router-dom'

const mergeQueryParams = (str1, str2) => {
  const searchParams1 = new URLSearchParams(str1)
  const searchParams2 = new URLSearchParams(str2)

  searchParams2.forEach((key, value) => {
    searchParams1.set(key, value)
  })

  return searchParams1.toString()
}

const usePreserveToQuery = to => {
  const { search } = useLocation()

  return typeof to === 'string'
    ? { pathname: to, search }
    : { ...to, search: mergeQueryParams(search, to.search) }
}

export { usePreserveToQuery }
