import React from 'react'
import ReactDOM from 'react-dom'
import 'styles/scss/index.scss'
import { RecoilRoot } from 'recoil'
import { App } from 'components/App'
import reportWebVitals from 'reportWebVitals'
import { ThemeProvider, CssBaseline, StylesProvider } from '@material-ui/core'
import { theme } from './materialTheme'

export const APP_ROOT_ID = 'root'

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot> {/* Can we inject nav here into state here if self? */}
      {/* StylesProvider-injectFirst is necessary for ability to override styles in SCSS modules */}
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </StylesProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById(APP_ROOT_ID)
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
