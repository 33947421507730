const REVIEWS_V1 = [
  {
    author: 'Devan R.',
    review:
      'I wish I found them sooner!! A wonderful, <strong>stress free experience</strong> through and through.'
  },
  {
    author: 'Sydney J.',
    review: 'Very clear and transparent, <strong>very affordable</strong>.'
  },
  {
    author: 'Mark G.',
    review:
      'They really worked with us and broke it down and made it very <strong>easy for us to understand</strong> and come up with a payment that we could afford. I love my freedom debt relief.'
  }
]
const REVIEWS_V2 = [
  {
    author: 'Dina R.',
    link: 'https://www.trustpilot.com/reviews/609ea55ef9f4870b7003ab3b',
    review:
      'They have such a positive and caring way that was welcoming at a time when I was losing hope. There is light at the end of the tunnel with Freedom Debt Relief!'
  },
  {
    author: 'Sally M.',
    link: 'https://www.trustpilot.com/reviews/5d850a6f3585c7048cc5ac2b',
    review: 'I have been with Freedom Debt Relief for going on two years now and I am thrilled with the results. My monthly payments are reasonable and we are getting debts paid down and paid off.'
  }
]
const STAR_RATING = 4.6

export { REVIEWS_V1, REVIEWS_V2, STAR_RATING }
