import { useEffect } from 'react'

const useKeyPress = (targetKey, onKeyPress) => {
  useEffect(() => {
    const downHandler = ({ key }) => {
      if (key === targetKey) {
        onKeyPress()
      }
    }

    window.addEventListener('keydown', downHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [targetKey, onKeyPress])
}

export { useKeyPress }
