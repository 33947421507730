import { selector } from 'recoil'
import { contentSelector } from './content'

const footerSelector = selector({
  get: ({ get }) => {
    const content = get(contentSelector)
    if (content?.footer) {
      return content.footer
    }
    return { legalCopy: {}, legalLinks: [] }
  },
  key: 'footer'
})

export { footerSelector }
