import { number, string } from 'prop-types'
import { View } from './View'

const toTenths = (rating) => Math.round((rating % 1.0) * 10)

const Container = ({ rating, text }) => (
  <View
    tenths={toTenths(rating)}
    text={text}
    wholeNumber={Math.floor(rating)}
  />
)

Container.propTypes = {
  rating: number.isRequired,
  text: string.isRequired
}

export { Container }
