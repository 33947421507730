// todo: implement logger (winston?)

// eslint-disable-next-line no-console
const handleLog = (...args) => console.log(...args)

/* eslint-disable sort-keys-fix/sort-keys-fix */
const logLevel = Object.freeze({
  ERROR: 0,
  WARN: 1,
  INFO: 2,
  DEBUG: 5
})
/* eslint-enable sort-keys-fix/sort-keys-fix */

const { LOG_LEVEL = 'DEBUG' } = process.env
const logLevelNumber = logLevel[LOG_LEVEL]
const makePrefixedLog = (prefix) => (...args) => handleLog(prefix, ...args)
const squelch = () => null

const logger = {
  debug: makePrefixedLog('DEBUG'),
  error: makePrefixedLog('ERROR'),
  info: makePrefixedLog('INFO'),
  log: makePrefixedLog('LOG'),
  warn: makePrefixedLog('WARN')
}

if (logLevelNumber <= logLevel.ERROR) {
  logger.debug = squelch
  logger.info = squelch
  logger.log = squelch
  logger.warn = squelch
} else if (logLevelNumber <= logLevel.WARN) {
  logger.debug = squelch
  logger.info = squelch
  logger.log = squelch
} else if (logLevelNumber <= logLevel.INFO) {
  logger.debug = squelch
}

export { logger, logLevel, squelch }
