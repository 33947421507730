import { atom } from 'recoil'

const initialState = null

const featureFlags = atom({
  default: initialState,
  key: 'featureFlags'
})

export { featureFlags, initialState }
