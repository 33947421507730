import { qaId } from 'consts/qaId'
import { useYourSolution } from 'hooks/useYourSolution'

const { format } = new Intl.NumberFormat('en-us', {
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: 'currency'
})

const useItems = ({ labels, language }) => {
  const yourSolution = useYourSolution()
  const { duration, totalCost, monthlyPayments } = yourSolution
  const isEspanol = language === 'es'
  let durationValue = duration.min
  if (duration.min !== duration.max) {
    durationValue = `${duration.min}-${duration.max}* ${isEspanol ? 'mes' : 'month'}${
      duration.max === 1 ? '' : isEspanol ? 'es' : 's'
    }`
  }
  return [
    {
      label: labels[0],
      labelId: qaId.YOUR_SOLUTION_DURATION_LABEL,
      value: durationValue,
      valueId: qaId.YOUR_SOLUTION_DURATION_VALUE
    },
    {
      label: labels[1],
      labelId: qaId.YOUR_SOLUTION_MONTHLY_PAYMENT_LABEL,
      value: `${format(monthlyPayments.min)}-${format(monthlyPayments.max)}`,
      valueId: qaId.YOUR_SOLUTION_MONTHLY_PAYMENT_VALUE
    },
    {
      label: labels[2],
      labelId: qaId.YOUR_SOLUTION_ESTIMATED_COST_LABEL,
      value: format(totalCost),
      valueId: qaId.YOUR_SOLUTION_ESTIMATED_COST_VALUE
    }
  ]
}

export { format, useItems }
