import React from 'react'
import { View } from './View'
import PropTypes from 'prop-types'
import { useContent } from 'hooks/useContent'

export const Container = ({ componentVariation }) => {
  const { content: { trustPage } } = useContent()
  return <View componentVariation={componentVariation} {...trustPage} />
}

Container.propTypes = {
  componentVariation: PropTypes.string
}

Container.defaultProps = {
  componentVariation: undefined
}
