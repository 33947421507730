import { useSetDarkMode } from 'hooks/useDarkMode'
import { View } from './View'

const Container = () => {
  useSetDarkMode({ backgroundImage: './advantage-bg.jpg' })

  return <View />
}

export { Container }
