import { toText } from 'utils/content/toText'

export const contentHelper = (content) => {
  const { title, content: items } = content.yourOptionsPage
  return {
    items: items.map((item) => ({
      img: item.icon.file.url,
      text: toText(item.content.content[1]),
      title: toText(item.content.content[0])
    })),
    title: toText(title)
  }
}
