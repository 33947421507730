import { node, number, string } from 'prop-types'
import { Title } from './Title'

import styles from './index.module.scss'

const View = ({ children, footnote, footnoteNumber, title }) => (
  <div className={styles.container}>
    <Title superText={footnoteNumber} text={title} toolTip={footnote} />
    <div>{children}</div>
  </div>
)

View.propTypes = {
  children: node.isRequired,
  footnote: string.isRequired,
  footnoteNumber: number.isRequired,
  title: string.isRequired
}
View.displayName = 'Section'
export { View }
