import { View } from './View'
import { useContent } from 'hooks/useContent'
import { string } from 'prop-types'
import { propSatisfies, includes, find } from 'ramda'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Container = ({ pageName }) => {
  const { content } = useContent()
  const legalDisclaimer = find(
    propSatisfies(includes('legalDisclaimer'), ':tags'),
    content[pageName].content
  )

  const legalDisclaimerLabels = find(
    propSatisfies(includes('legalDisclaimerLabels'), ':tags'),
    content[pageName].content
  )

  return (
    legalDisclaimer
      ? (
      <View
        legalDisclaimer={documentToReactComponents(legalDisclaimer.content)}
        {...legalDisclaimerLabels?.content}
      />
        )
      : null
  )
}

Container.propTypes = {
  pageName: string.isRequired
}

View.displayName = 'OtherDebts'

export { Container }
