import { contentAPI } from 'api'
import { atoms } from 'store'
import { selector } from 'recoil'

const contentSelector = selector({
  get: async ({ get }) => {
    try {
      const { domain, language, path } = get(atoms.content)
      const data = await contentAPI.getDeck({ domain, language, path })
      return data.content
    } catch (error) {
      if (error.response) {
        return {
          error: {
            code: error.response?.status
          }
        }
      }
      return { error }
    }
  },
  key: 'contentValue'
})

export { contentSelector }
