import { toCamelCase } from 'utils/content/toCamelCase'
import { toText } from 'utils/content/toText'

const contentHelper = (content) => {
  const {
    title,
    content: [item1, item2, item3]
  } = content.yourBudgetSummaryPage
  return {
    items: [item1, item2, item3].map((item) => ({
      description: toText(item.content.content[1]),
      icon: item.icon.file.url,
      id: toCamelCase(toText(item.content.content[2])),
      inputLabel: toText(item.content.content[2]),
      label: toText(item.content.content[0])
    })),
    title: toText(title)
  }
}

export { contentHelper }
