import cx from 'classnames'
import { qaId } from 'consts/qaId'
import { bool, node, string } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const View = ({
  className,
  isDark,
  subtitle,
  title,
  subtitleClassName,
  ...props
}) => {
  const lightClass = { [styles.dark]: isDark }

  return (
    <div {...props} className={cx(styles.container, className)}>
      <h1
        {...qaAttributes({
          id: qaId.STEP_HEADER_TITLE
        })}
        className={cx(styles.title, lightClass)}
      >
        {title}
      </h1>
      {subtitle && (
        <div
          {...qaAttributes({
            id: qaId.STEP_HEADER_SUBTITLE
          })}
          className={cx(styles.subtitle, lightClass, subtitleClassName)}
        >
          {subtitle}
        </div>
      )}
    </div>
  )
}

View.propTypes = {
  className: string,
  isDark: bool.isRequired,
  subtitle: node,
  subtitleClassName: string,
  title: string.isRequired
}

View.defaultProps = {
  isDark: false
}

export { View }
