import PropTypes from 'prop-types'
import { Box, TextField } from '@material-ui/core'
import { qaId } from 'consts/qaId'
import { qaAttributes } from 'utils/qaAttributes'
import { NumberFormatedInput } from './NumberFormatedInput'

import styles from './index.module.scss'

export const View = ({
  id,
  description,
  icon,
  label,
  inputLabel,
  index,
  ...props
}) => (
  <Box
    {...qaAttributes({ id: `${qaId.BUDGET_SUMMARY_ITEM}-${index + 1}` })}
    key={label}
    alignItems="flex-start"
    className={styles.inputGroup}
    display="flex"
  >
    <img alt={id} className={styles.icon} src={icon} />
    <div className={styles.content}>
      <div
        className={styles.label}
        {...qaAttributes({
          id: `${qaId.BUDGET_SUMMARY_ITEM_LABEL}-${index + 1}`
        })}
      >
        {label}
      </div>
      {description && (
        <div
          className={styles.description}
          {...qaAttributes({
            id: `${qaId.BUDGET_SUMMARY_ITEM_DESCRIPTION}-${index + 1}`
          })}
        >
          {description}
        </div>
      )}
      <TextField
        InputLabelProps={{
          classes: {
            root: styles.inputLabel
          },
          shrink: true
        }}
        InputProps={{
          classes: {
            root: styles.input
          },
          id,
          inputComponent: NumberFormatedInput,
          name: id
        }}
        classes={{
          root: styles.textField
        }}
        id="outlined-number"
        label={inputLabel}
        {...props}
        size="small"
        type="text"
        variant="outlined"
      />
    </div>
  </Box>
)

View.propTypes = {
  description: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  inputLabel: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}
