import React from 'react'
import { clickEvent } from 'consts/clickEvent'
import { Legal as SharedLegal } from 'components/Disclosures/Legal'
import { LegalText } from 'components/Disclosures/LegalText'
import styles from './index.module.scss'
import { shape, string } from 'prop-types'

const ariaLabels = {
  collapsed: 'Expand learn more about our estimates',
  expanded: 'Collapse learn more about our estimates'
}

const clickEvents = {
  collapsed: clickEvent.LETS_GET_STARTED_LEARN_MORE,
  expanded: clickEvent.LETS_GET_STARTED_READ_LESS
}

const LegalFooter = ({ footerText }) => (
  <div className={styles.legalContent}>
    <SharedLegal
      ariaLabels={ariaLabels}
      clickEvents={clickEvents}
      toggleTexts={footerText}
    >
      <LegalText>{footerText.disclaimer}</LegalText>
    </SharedLegal>
  </div>
)

LegalFooter.propTypes = {
  footerText: shape({
    collapsed: string.isRequired,
    disclaimer: string.isRequired,
    expanded: string.isRequired
  })
}

export { LegalFooter }
