import cx from 'classnames'
import { oneOf, string } from 'prop-types'
import { ReactComponent as SvgLogo } from './logo.svg'
import { variant as logoVariant } from './constants'
import styles from './index.module.scss'

const variantValues = Object.values(logoVariant)

const View = ({
  variant,
  className,
  ...props
}) => (
  <SvgLogo
    alt='Freedom logo'
    className={cx(styles[variant], className)}
    {...props}
  />
)

View.defaultProps = {
  variant: logoVariant.RED_BLUE
}

View.propTypes = {
  className: string,
  variant: oneOf(variantValues)
}

export { View }
