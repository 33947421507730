import { path } from 'ramda'
import { toText } from 'utils/content/toText'
import { currencyFormat } from 'utils/currencyFormat'
/** Ids for lets get started page content */
const subTitleTestIds = ['monthly-payment', 'program-benefit', 'time-saved']
/**
 * Format time saved text based on program length and pay down time
 * @param {Number} programMonths - number of months in the program
 * @param {Number} payDownMonths - number of months to pay down
 * @returns {String}
 */
export const calcTimeSavings = (
  programMonths,
  payDownMonths,
  unitsLocalized
) => {
  const monthsDiff = payDownMonths - programMonths
  if (monthsDiff > 1 && monthsDiff < 12) {
    return `${monthsDiff} ${unitsLocalized.MONTHS}`
  } else if (monthsDiff >= 12 && monthsDiff < 24) {
    return `${Math.floor(monthsDiff / 12)} ${unitsLocalized.YEAR}`
  } else if (monthsDiff >= 24) {
    return `${Math.floor(monthsDiff / 12)} ${unitsLocalized.YEARS}`
  } else {
    return `${monthsDiff} ${unitsLocalized.MONTH}`
  }
}

/**
 * Parse lead and content data to generate page text
 * @param {Object} leadData - Lead data object to be used to generate page text
 * @param {Object} content - Contentful data
 * @returns {PageContent}
 */
export const contentHelper = (leadData, content) => {
  const {
    estimatedProgramMaximumLength,
    estimatedProgramMinimumLength
  } = leadData
  const timeSavedValues = [
    estimatedProgramMinimumLength,
    estimatedProgramMaximumLength
  ]
  const {
    title,
    content: [item1, item2, item3, buttonLabel, footerText]
  } = content.letsGetStartedPage
  let footNoteIndex = 1
  const { phoneHref } = content.header
  const footerTextLabels = footerText?.content?.content
  const disclaimer = formatDisclaimer(
    path(['data', 'target', 'content'], footerTextLabels[2])
  )
  const units = path(
    ['data', 'target', 'content', 'units'],
    footerTextLabels[2]
  )
  return {
    buttonLabel: toText(buttonLabel.content),
    footerText: {
      collapsed: toText(footerTextLabels[0]),
      disclaimer,
      expanded: toText(footerTextLabels[1])
    },
    items: [item1, item2, item3].map(({ content: { content } }, index) => {
      const toolTip = toText(content[2])
      const testId = subTitleTestIds[index]
      const data = {
        subtitle: {
          footnoteNumber: toolTip ? footNoteIndex++ : null,
          hasTooltip: Boolean(toolTip),
          text: toText(content[1]),
          toolTip
        },
        testId: testId,
        title: toText(content[0]),
        value: getValueFromLeadData(subTitleTestIds[index], leadData, units)
      }
      if (testId === 'time-saved') {
        data.title = replaceNumbers(data.title, timeSavedValues)
      }
      return data
    }),
    phoneHref,
    title: toText(title)
  }
}

const formatDisclaimer = (disclaimer) => (
  <div>
    {disclaimer?.body?.map((p, i) => (
      <div key={i}>
        <div>{p}</div>
        <br />
      </div>
    ))}
    <ol>
      {disclaimer.list.map((item, j) => (
        <li key={j}>{item}</li>
      ))}
    </ol>
  </div>
)

/**
 * Helper function to replace numbers in a string
 * i.e.
 * text= 'Your program will save you 1 months'
 * values= [3]
 * returns 'Your program will save you 3 months'
 * @param {String} text - Text to replace numbers in
 * @param {String[]} values - Array of values to replace numbers with
 * @returns {String}
 */
const replaceNumbers = (text, values) => {
  let matchIndex = 0
  return text?.replace(/(\d+)/gi, () => values[matchIndex++]) || ''
}

/**
 * Helper function to get numeric content for the items section from lead data
 * @param {String} type - Type of value prop to get from lead data
 * @param {Object} leadData - Lead data object to be used to generate page text
 * @returns {String}
 */
const getValueFromLeadData = (type, leadData, units) => {
  const {
    estimatedDebtMonthlyPayment,
    estimatedMonthlyCreditorPayment,
    estimatedProgramLength,
    estimatedProgramSavings,
    payDownTimeMinPayments
  } = leadData
  switch (type) {
    case 'monthly-payment':
      return currencyFormat(
        Math.floor(
          estimatedMonthlyCreditorPayment - estimatedDebtMonthlyPayment
        )
      )
    case 'program-benefit':
      return currencyFormat(estimatedProgramSavings)
    case 'time-saved':
      return calcTimeSavings(
        estimatedProgramLength,
        payDownTimeMinPayments,
        units
      )
    default:
      return null
  }
}
