import { Typography } from '@material-ui/core'
import { FullBleed } from 'components/Layout/FullBleed'
import { Header } from 'components/Layout/Header'
import { qaId } from 'consts/qaId'
import { object, string } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const View = ({
  backgroundUrl,
  firstName,
  lastName,
  logo,
  coApplicantFirstName,
  coApplicantLastName,
  title,
  paragraph,
  coAppSeparator
}) => (
  <FullBleed className={styles.container}>
    <div className={styles.headerContainer}>
      <Header isMinimal />
    </div>
    <img alt={logo.title} async className={styles.logo} src={logo.file.url} />
    <div className={styles.content}>
      <div className={styles.hero}>
        <span
          className={styles.heroText}
          {...qaAttributes({
            id: qaId.START_PAGE_TITLE
          })}
        >
          {title}
        </span>
        <Typography className={styles.name} variant="h1">
          <span
            {...qaAttributes({
              id: qaId.START_PAGE_FULL_NAME
            })}
          >
            {firstName} {lastName}
          </span>
          {Boolean(coApplicantFirstName) && Boolean(coApplicantLastName) && (
            <>
              <div className={styles.coAppAnd}>{coAppSeparator}</div>
              <span
                {...qaAttributes({
                  id: qaId.START_PAGE_CO_APP_FULL_NAME
                })}
              >
                {coApplicantFirstName} {coApplicantLastName}
              </span>
            </>
          )}
        </Typography>
      </div>
      <div
        className={styles.explanation}
        {...qaAttributes({
          id: qaId.START_PAGE_PARAGRAPH
        })}
      >
        <Typography variant="body1">{paragraph}</Typography>
      </div>
    </div>
  </FullBleed>
)

View.defaultProps = {
  coAppSeparator: 'and'
}

View.propTypes = {
  backgroundUrl: string,
  coAppSeparator: string.isRequired,
  coApplicantFirstName: string,
  coApplicantLastName: string,
  firstName: string.isRequired,
  lastName: string.isRequired,
  logo: object.isRequired,
  paragraph: string.isRequired,
  title: string.isRequired
}

export { View }
