const featureFlags = Object.freeze({
  // TODO: remove this after adding first feature flag.
  AGENT_SCRIPT: 'px_sales_aid_agent_script',
  ESTIMATED_SAVINGS_CALC: 'px_sales_estimated_saving_calc',
  SALES_AID_SKIP_ANIMATION: 'px-sales-aid-skip-animation',
  SALES_AID_TEST_FLAG: 'px_sales_aid_test_flag',
  SALES_AID_USE_PROGRAM_API: 'px-sales-aid-use-program-api'
})

export { featureFlags }
