import { useState } from 'react'
import { arrayOf, shape, string } from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import { useSteps } from 'hooks/useSteps'
import { useDarkMode } from 'hooks/useDarkMode'
import { useOmitCfos } from 'hooks/useOmitCfos'
import { cfosPages } from 'components/Layout/constants'
import { View } from './View'

const Container = ({ links, ...props }) => {
  const [anchorEl, setAnchorEl] = useState()
  const history = useHistory()
  const stepInfo = useSteps()
  const { search } = useLocation()
  const darkMode = useDarkMode()
  const { omitCfos } = useOmitCfos()
  const menuLinks = omitCfos
    ? links.filter(({ id }) => !cfosPages.includes(id))
    : links

  const onAnchorClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget)
  }

  const onClose = () => setAnchorEl(null)

  const onNavigate = (href) => {
    onClose()
    href && history.push({
      pathname: href,
      search,
      state: {
        prevPath: stepInfo?.current?.path
      }
    })
  }

  const viewProps = {
    anchorEl,
    currentPath: stepInfo?.current?.path,
    isDark: darkMode,
    links: menuLinks.filter(({ hideInMenu }) => !hideInMenu),
    onAnchorClick,
    onClose,
    onNavigate,
    ...props
  }

  return <View {...viewProps} />
}

Container.propTypes = {
  links: arrayOf(shape({
    clickEventId: string.isRequired,
    label: string.isRequired,
    path: string.isRequired
  })),
  text: string.isRequired
}

Container.displayName = 'Dropdown.Container'

export { Container }
