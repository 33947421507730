import { useLead } from 'hooks/useLead'
import { View } from './View'
import PropTypes from 'prop-types'
import { qaId } from 'consts/qaId'
import { currencyFormat } from 'utils/currencyFormat'
import { useSetDarkMode } from 'hooks/useDarkMode'

const Container = ({ backgroundUrl }) => {
  useSetDarkMode({ backgroundImage: backgroundUrl })
  const {
    estimatedDebtMonthlyPayment,
    estimatedProgramLength,
    estimatedProgramSavings,
    totalEstimatedDebt
  } = useLead()

  const dataSections = [{
    footer: () => (
      <>
        per month<sup>*</sup>
      </>
    ),
    qaId: qaId.MONTHLY_PAYMENT,
    title: 'Total Monthly Payment',
    value: currencyFormat(estimatedDebtMonthlyPayment)
  },
  {
    qaId: qaId.TOTAL_ENROLLED_DEBT,
    title: 'Total Estimated Debt',
    value: currencyFormat(totalEstimatedDebt)
  },
  {
    qaId: qaId.PROGRAM_LENGTH,
    title: 'Estimated Program Duration*',
    value: `${estimatedProgramLength} months`
  }]
  if (estimatedProgramSavings) {
    dataSections.push({
      qaId: qaId.ESTIMATED_SAVINGS,
      title: 'Estimated Benefit',
      value: currencyFormat(estimatedProgramSavings)
    })
  }

  return (
    <View
      dataSections={dataSections}
      totalReduction={estimatedProgramSavings}
    />
  )
}

Container.propTypes = {
  backgroundUrl: PropTypes.string
}

Container.defaultProps = {
  backgroundUrl: './thank-you-bg.jpg'
}
export { Container }
