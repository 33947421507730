import { ClickAwayListener } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import { qaId } from 'consts/qaId'
import { number, string } from 'prop-types'
import { useState } from 'react'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: '#333',
    color: '#fff',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    // eslint-disable-next-line comma-dangle
    marginInlineStart: '0.25rem',
  }
}))

const TitleTooltip = ({ testId, superText, toolTip, ariaLabel }) => {
  const toolTipClasses = useStyles()
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }
  return (
<ClickAwayListener onClickAway={handleTooltipClose}>
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          aria-label={ariaLabel || toolTip}
          className={styles.toolTip}
          classes={toolTipClasses}
          disableTouchListener
          onClick={handleTooltipOpen}
          onClose={handleTooltipClose}
          onMouseOverCapture={handleTooltipOpen}
          open={open}
          placement="top"
          title={toolTip}
        >
          <sup
            {...qaAttributes({
              id: testId
            })}
          >
            <button className={styles.button}>{superText}</button>
          </sup>
        </Tooltip>
      </ClickAwayListener>
  )
}

TitleTooltip.propTypes = {
  ariaLabel: string,
  superText: number.isRequired,
  testId: string.isRequired,
  toolTip: string.isRequired
}

const View = (props) => {
  const { text, superText } = props
  return (
    <h4 className={styles.title}>
      {text}
      <TitleTooltip testId={`${qaId.CFOS_LEGAL_FOOTNOTE}-${superText}`} {...props} />
    </h4>
  )
}

View.propTypes = {
  ariaLabel: string,
  superText: number.isRequired,
  text: string.isRequired,
  toolTip: string.isRequired
}

View.displayName = 'Title'

export { View, TitleTooltip }
