import PropTypes from 'prop-types'
import cls from 'classnames'
import { currencyFormat } from 'utils/currencyFormat'
import { variant as IncomeExpenceVariant } from './constants'
import { qaId } from 'consts/qaId'
import { qaAttributes } from 'utils/qaAttributes'

import styles from './index.module.scss'

const variantValues = Object.values(IncomeExpenceVariant)

export const View = ({ title, value, variation }) => (
  <div className={styles.ieCard}>
    <div
      className={styles.ieCardTitle}
      {...qaAttributes({
        id: `${qaId.INCOME_EXPENSE_SUMMARY_CARD_TITLE}-${title}`
      })}>
            {title}</div>
    <div
    className={cls(styles.ieAmount, styles[variation])}
    {...qaAttributes({
      id: `${qaId.INCOME_EXPENSE_SUMMARY_CARD_VALUE}-${title}`
    })}>
      {currencyFormat(value)}
    </div>
  </div>
)

View.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  variation: PropTypes.oneOf(variantValues)
}
