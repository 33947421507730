import { lead } from 'api'
import { selector } from 'recoil'

const leadSelector = selector({
  get: async () => {
    try {
      return await lead.getOne()
    } catch (error) {
      if (error.response) {
        return {
          error: {
            code: error.response?.status
          }
        }
      }
      return { error }
    }
  },
  key: 'lead'
})

export { leadSelector }
