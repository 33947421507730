import { atom } from 'recoil'

function getDefaultState () {
  try {
    const { host, pathname } = window.location
    const path = `/${pathname.split('/')[1]}`
    return { domain: host, language: 'en-US', path }
  } catch (e) {
    return {}
  }
}

const content = atom({
  default: getDefaultState(),
  key: 'contentLocationState'
})

export { content }
