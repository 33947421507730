import React from 'react'
import { View } from '.'
import { useContent } from 'hooks/useContent'
import { toText } from 'utils/content/toText'

const contentHelper = (content) => {
  const {
    title,
    subtitle,
    content: [item1, item2, item3, footer, img]
  } = content.strongerFinancialFuturePage
  return {
    footer: toText(footer.content),
    img: img.icon.file.url,
    items: [item1, item2, item3].map((item) => ({
      img: item.icon.file.url,
      name: item.name,
      text: toText(item.content)
    })),
    subtitle: toText(subtitle),
    title: toText(title)
  }
}

const Container = () => {
  const { content } = useContent()
  const { footer, items, subtitle, title, img } = contentHelper(content)
  return (
    <View
      footer={footer}
      img={img}
      items={items}
      subtitle={subtitle}
      title={title}
    />
  )
}

export { Container }
