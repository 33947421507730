import { xhr } from 'utils/xhr'

const getDeck = ({ domain, language, path }) => xhr.get('/content', {
  params: {
    domain,
    language,
    path
  }
})

export { getDeck }
