import confetti from 'canvas-confetti'

const DEFAULT_DURATION = 10 * 1000

export const initConfetti = (duration = DEFAULT_DURATION) => {
  const animationEnd = Date.now() + duration
  const defaults = { spread: 360, startVelocity: 30, ticks: 60, zIndex: 0 }

  function randomInRange (min, max) {
    return Math.random() * (max - min) + min
  }

  const interval = setInterval(function () {
    const timeLeft = animationEnd - Date.now()

    if (timeLeft <= 0) {
      return clearInterval(interval)
    }

    const particleCount = 50 * (timeLeft / duration)
    // since particles fall down, start a bit higher than random
    confetti(Object.assign({}, defaults, {
      origin: {
        x: randomInRange(0.1, 0.3),
        y: Math.random() - 0.3
      },
      particleCount
    }
    ))
    confetti(Object.assign({}, defaults, {
      origin: {
        x: randomInRange(0.7, 0.9),
        y: Math.random() - 0.3
      },
      particleCount
    }
    ))
  }, 250)
}
