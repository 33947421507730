import PropTypes from 'prop-types'
import { View } from './View'

const STAR_RATING = 4.4

const Container = ({ ':tags': tags, ...props }) => {
  const rating = tags.reduce((acc, tag) => {
    if (tag.startsWith('rating')) {
      return Number.parseInt(tag.substr(6), 10) / 10
    }

    return acc
  }, STAR_RATING)

  return <View {...props} rating={rating} />
}

Container.propTypes = {
  ':tags': PropTypes.arrayOf(PropTypes.string),
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]).isRequired,
  name: PropTypes.string.isRequired
}

Container.displayName = 'TrustPilotReview'

export { Container }
