import { useLead } from 'hooks/useLead'
import { DebtTypes } from './consts'

const useDebts = (debtType = DebtTypes.ENROLED) => {
  const lead = useLead()

  return lead[debtType]
}

export { useDebts }
