import React from 'react'
import styles from './index.module.scss'
import { StepHeader } from 'components/StepHeader'
import { Image } from 'components/Image'
import { qaAttributes } from 'utils/qaAttributes'
import { qaId } from 'consts/qaId'
import { string, arrayOf, shape } from 'prop-types'
import { renderable } from 'utils/propTypes'

const View = ({
  title,
  subtitle,
  footer,
  items,
  img: FinancialRecoveryGraph
}) => (
  <div className={styles.container}>
    <StepHeader title={title} />
    {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    <div className={styles.content}>
      <ul className={styles.itemContainer}>
        {items.map((item) => (
          <li key={item.name} className={styles.item}>
            <img
              alt="financial-recovery-icon"
              className={styles.icon}
              src={item.img}
            />
            <div
              {...qaAttributes({
                id: `${
                  qaId.FINANCIAL_RECOVERY_ITEMS_PREFIX
                }-${item?.name?.toLowerCase().replace(/[ \t]/gi, '-')}`
              })}
              className={styles.text}
            >
              {item.text}
            </div>
          </li>
        ))}
      </ul>

      <div>
        <div>
          <Image
            alt={'recoveryGraph'}
            height={226}
            src={FinancialRecoveryGraph}
            width={320}
          />
        </div>
        <div
          {...qaAttributes({
            id: qaId.FINANCIAL_RECOVERY_FOOTER
          })}
          className={styles.asterisk}
        >
          {footer}
        </div>
      </div>
    </div>
  </div>
)
View.displayName = 'FinancialRecovery'

View.propTypes = {
  footer: string.isRequired,
  img: string.isRequired,
  items: arrayOf(
    shape({
      text: string.isRequired
    })
  ).isRequired,
  subtitle: renderable.isRequired,
  title: renderable.isRequired
}

export { View }
