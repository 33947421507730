import { useLead } from 'hooks/useLead'

const example = {
  amortization: {
    fdr: {
      max: 5 * 12 + 2, //  5 years, 2 months
      min: 4 * 12 // 4 years
    },
    minimumPayments: 461 // 32 years
  },
  monthlyPayments: {
    fdr: 62.0,
    minimumPayments: 461
  },
  totalDebt: {
    fdr: 103478.98,
    minimumPayments: 169112.28
  }
}

/*
actualDebt: 36042
estimatedDebt: 25000
estimatedSavings: 7819
fdrProgramMaxMonthlyPayment: 784
fdrProgramMinMonthlyPayment: 588
firstName: "Kimberly"
programLengthRange: "36-48"
state: "NY"
*/

const useDebtComparison = () => {
  const {
    doNothingTotalCost,
    estimatedDebtMonthlyPaymentMinimum,
    estimatedMonthlyCreditorPayment,
    estimatedProgramMinimumLength,
    estimatedProgramMaximumLength,
    estimatedCostToClient,
    payDownTimeMinPayments
  } = useLead()

  const fdrMin = estimatedProgramMinimumLength
  const fdrMax = estimatedProgramMaximumLength

  return {
    amortization: {
      fdr: {
        max: Number.parseInt(fdrMax, 10),
        min: Number.parseInt(fdrMin, 10)
      },
      minimumPayments: payDownTimeMinPayments
    },
    monthlyPayments: {
      fdr: estimatedDebtMonthlyPaymentMinimum,
      minimumPayments: estimatedMonthlyCreditorPayment
    },
    totalDebt: {
      fdr: estimatedCostToClient,
      minimumPayments: doNothingTotalCost
    }
  }
}

export { example, useDebtComparison }
