import { useSteps } from 'hooks/useSteps'
import { useState, useEffect } from 'react'
import { View } from './View'

const Container = (props) => {
  const { all, currentIndex: _currentIndex } = useSteps()
  const [currentIndex, setCurrentIndex] = useState(_currentIndex)
  const currentPath = all[currentIndex]?.path
  const previousPath = all[currentIndex - 1]?.path
  const nextPath = all[currentIndex + 1]?.path

  useEffect(() => {
    if (currentIndex !== _currentIndex) {
      setCurrentIndex(_currentIndex)
    }
  }, [_currentIndex, currentIndex])
  return (
    <View
      {...props}
      currentIndex={currentIndex}
      currentPath={currentPath}
      nextPath={nextPath}
      previousPath={previousPath}
      setCurrentIndex={setCurrentIndex}
    />
  )
}

Container.displayName = 'Navigation.Container'

export { Container }
