import { useLead } from 'hooks/useLead'

const useOmitCfos = () => {
  const {
    estimatedDebtMonthlyPaymentMinimum,
    estimatedMonthlyCreditorPayment
  } = useLead()

  return {
    omitCfos:
      estimatedDebtMonthlyPaymentMinimum > estimatedMonthlyCreditorPayment
  }
}

export { useOmitCfos }
