const qaId = Object.freeze({
  ABOUT_PAGE_FIGURES: 'about-page-figures',
  ABOUT_PAGE_TITLE: 'about-page-title',
  BUDGET_SUMMARY_ITEM: 'budget-summary-item',
  BUDGET_SUMMARY_ITEM_DESCRIPTION: 'budget-summary-item-description',
  BUDGET_SUMMARY_ITEM_LABEL: 'budget-summary-item-label',
  CALL_AGENDA_ITEM: 'call-agenda-item',
  CFOS_FDR_ESTIMATED_COST: 'cfos-fdr-estimated-cost',
  CFOS_FDR_LENGTH: 'cfos-fdr-length',
  CFOS_FDR_PER_MONTH: 'cfos-fdr-per-month',
  CFOS_LEGAL_FOOTNOTE: 'cfos-legal-footnote',
  CFOS_LEGAL_LEARN_MORE: 'cfos-legal-learn-more',
  CFOS_MONTHLY_PAYMENTS_LENGTH: 'cfos-monthly-payments-length',
  CFOS_MONTHLY_PAYMENTS_PER_MONTH: 'cfos-monthly-payments-per-month',
  CFOS_MONTHLY_PAYMENTS_WITH_INTEREST: 'cfos-monthly-payments-with-interest',
  CFOS_NAVIGATION_LEFT_ARROW: 'cfos-navigation-left-arrow',
  CFOS_NAVIGATION_LEFT_DOT: 'cfos-navigation-left-dot',
  CFOS_NAVIGATION_RIGHT_ARROW: 'cfos-navigation-right-arrow',
  CFOS_NAVIGATION_RIGHT_DOT: 'cfos-navigation-right-dot',
  DATA_SECTION_CONTENT: 'data-section-content',
  DATA_SECTION_FOOTER: 'data-section-footer',
  DATA_SECTION_TITLE: 'data-section-title',
  DEBT_HEADER_AMOUNT: 'debt-header-amount',
  DEBT_HEADER_TITLE: 'debt-header-title',
  ELIGIBLE_DEBT_SUMMARY_FOOTER: 'eligible-debt-summary-footer',
  ELIGIBLE_DEBT_SUMMARY_RING_PERCENTAGE:
    'eligible-debt-summary-ring-percentage',
  ELIGIBLE_DEBT_SUMMARY_RING_SUBTITLE: 'eligible-debt-summary-ring-subtitle',
  ELIGIBLE_DEBT_SUMMARY_RING_TITLE: 'eligible-debt-summary-ring-title',
  ESTIMATED_BENEFIT: 'estimated-benefit',
  ESTIMATED_SAVINGS: 'estimated-savings',
  FDR_CFOS_TRANSITION: 'fdr-cfos-transition',
  FINANCIAL_GOALS_ITEM: 'financial-goals-item',
  FINANCIAL_RECOVERY_FOOTER: 'financial-recovery-footer',
  FINANCIAL_RECOVERY_ITEMS_PREFIX: 'financial-recovery-items',
  FOOTER_ALL_RIGHTS_RESERVED: 'footer-all-rights-reserved',
  FOOTER_CONTACT_US: 'footer-contact-us',
  FOOTER_LEGAL_COPY: 'footer-legal-copy',
  FOOTER_PRIVACY_POLICY: 'footer-privacy-policy',
  FOOTER_TERMS_OF_USE: 'footer-terms-of-use',
  HEADER_PHONE_LINK: 'header-phone-link',
  HOW_IT_WORKS_ITEMS_PREFIX: 'how-it-works-items',
  INCOME_EXPENSE_SUMMARY_CARD_TITLE: 'income-expense-summary-card-title',
  INCOME_EXPENSE_SUMMARY_CARD_VALUE: 'income-expense-summary-card-value',
  INCOME_EXPENSE_SUMMARY_CHART_LABEL: 'income-expense-summary-chart-label',
  INCOME_EXPENSE_SUMMARY_DISCRETIONARY_TITTLE:
    'income-expense-summary-discretionary-title',
  INCOME_EXPENSE_SUMMARY_DISCRETIONARY_VALUE:
    'income-expense-summary-discretionary-value',
  INTRO_CO_APP_FULL_NAME: 'intro-co-app-full-name',
  INTRO_FULL_NAME: 'intro-full-name',
  LETS_GET_STARTED_BUTTON: 'lets-get-started-button',
  LETS_GET_STARTED_VALUE_PROP: 'lets-get-started-value-prop',
  MONTHLY_PAYMENT: 'monthly-payment',
  NAV_ARROW_LEFT: 'nav-arrow-left',
  NAV_ARROW_RIGHT: 'nav-arrow-right',
  NAV_DESKTOP_PREFIX: 'nav-desktop',
  NAV_MOBILE_CLOSE: 'nav-mobile-close',
  NAV_MOBILE_PREFIX: 'nav-mobile',

  OPTION_EVALUATION_ITEM: 'option-evaluation-item',
  OPTION_EVALUATION_ITEM_DESCRIPTION: 'option-evaluation-item-description',
  OPTION_EVALUATION_ITEM_LABEL: 'option-evaluation-item-label',

  OTHER_DEBTS_BALANCE_PREFIX: 'other-debts-creditor-balance',
  OTHER_DEBTS_CREDITOR_PREFIX: 'other-debts-creditor',
  PROGRAM_COST: 'program-cost',
  PROGRAM_LENGTH: 'program-length',
  START_PAGE_CO_APP_FULL_NAME: 'start-page-co-app-full-name',
  START_PAGE_FULL_NAME: 'start-page-full-name',
  START_PAGE_PARAGRAPH: 'start-page-paragraph',
  START_PAGE_TITLE: 'start-page-title',
  STEP_HEADER_SUBTITLE: 'step-header-subtitle',
  STEP_HEADER_TITLE: 'step-header-title',
  TOTAL_ENROLLED_DEBT: 'total-enrolled-debt',
  TOTAL_ESTIMATED_DEBT: 'total-estimated-debt',
  WELCOME_FOOTER: 'welcome-footer',
  WELCOME_ITEMS_PREFIX: 'welcome-items',
  WE_ARE_IN_FOOTER: 'we-are-in-footer',
  WE_ARE_IN_STATE_PREFIX: 'we-are-in-state',
  WHAT_OUR_CLIENTS_SAY_REVIEWS_PREFIX: 'what-our-clients-say-reviews-prefix',
  YOUR_DEBTS_ARROW_PREFIX: 'your-debts-creditor-arrow',
  YOUR_DEBTS_BALANCE_PREFIX: 'your-debts-creditor-balance',
  YOUR_DEBTS_CREDITOR_CO_APPLICANT: 'your-debts-creditor-co-applicant',
  YOUR_DEBTS_CREDITOR_DIALOG_ACCOUNT_NUMBER:
    'your-debts-creditor-dialog-account-number',
  YOUR_DEBTS_CREDITOR_DIALOG_ACCOUNT_NUMBER_VALUE:
    'your-debts-creditor-dialog-account-number-value',
  YOUR_DEBTS_CREDITOR_DIALOG_BALANCE: 'your-debts-creditor-dialog-balance',
  YOUR_DEBTS_CREDITOR_DIALOG_BALANCE_VALUE:
    'your-debts-creditor-dialog-balance-value',
  YOUR_DEBTS_CREDITOR_DIALOG_CLOSE: 'your-debts-creditor-dialog-close',
  YOUR_DEBTS_CREDITOR_DIALOG_CREDITOR: 'your-debts-creditor-dialog-creditor',
  YOUR_DEBTS_CREDITOR_DIALOG_LATE_PAYMENT:
    'your-debts-creditor-dialog-late-payment',
  YOUR_DEBTS_CREDITOR_DIALOG_LATE_PAYMENT_VALUE:
    'your-debts-creditor-dialog-late-payment-value',
  YOUR_DEBTS_CREDITOR_DIALOG_MIN_MONTHLY_PAYMENT:
    'your-debts-creditor-dialog-min-monthly-payment',
  YOUR_DEBTS_CREDITOR_DIALOG_MIN_MONTHLY_PAYMENT_VALUE:
    'your-debts-creditor-dialog-min-monthly-payment-value',
  YOUR_DEBTS_CREDITOR_DIALOG_UTILIZATION:
    'your-debts-creditor-dialog-utilization',
  YOUR_DEBTS_CREDITOR_DIALOG_UTILIZATION_VALUE:
    'your-debts-creditor-dialog-utilization-value',
  YOUR_DEBTS_CREDITOR_FOOTER_TOTAL: 'your-debts-creditor-footer-total',
  YOUR_DEBTS_CREDITOR_FOOTER_TOTAL_VALUE:
    'your-debts-creditor-footer-total-value',
  YOUR_DEBTS_CREDITOR_HEADER_AMOUNT_OWED:
    'your-debts-creditor-header-amount-owed',
  YOUR_DEBTS_CREDITOR_HEADER_CREDITOR: 'your-debts-creditor-header-creditor',
  YOUR_DEBTS_CREDITOR_PREFIX: 'your-debts-creditor',
  YOUR_DEBTS_CREDITOR_WARNING: 'your-debts-creditor-warning',
  YOUR_SOLUTION_DURATION_LABEL: 'your-solution-duration-label',
  YOUR_SOLUTION_DURATION_VALUE: 'your-solution-duration-value',
  YOUR_SOLUTION_ESTIMATED_COST_LABEL: 'your-solution-estimated-cost-label',
  YOUR_SOLUTION_ESTIMATED_COST_VALUE: 'your-solution-estimated-cost-value',
  YOUR_SOLUTION_HEADER_LOWEST_MONTHLY_PAYMENT:
    'your-solution-header-lowest-monthly-payment',
  YOUR_SOLUTION_LOWEST_MONTHLY_PAYMENT: 'your-solution-lowest-monthly-payment',
  YOUR_SOLUTION_LOWEST_MONTHLY_PAYMENT_FOOTER:
    'your-solution-lowest-monthly-payment-footer',
  YOUR_SOLUTION_MONTHLY_PAYMENT_LABEL: 'your-solution-monthly-payment-label',
  YOUR_SOLUTION_MONTHLY_PAYMENT_VALUE: 'your-solution-monthly-payment-value'
})

export { qaId }
