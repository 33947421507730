import { StepHeader } from 'components/StepHeader'
import { qaId } from 'consts/qaId'
import { node, oneOfType, oneOf, string, element } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'
import { region, RegionMap } from './RegionMap'
import { Subtitle } from './Subtitle'

const View = ({ footer, regionCode, title, subtitle }) => (
  <div className={styles.container}>
    <StepHeader subtitle={subtitle} title={title} />
    <RegionMap regionCode={regionCode} />
    <p
      {...qaAttributes({
        id: qaId.WE_ARE_IN_FOOTER
      })}
      className={styles.paragraph}
    >
      {footer}
    </p>
  </div>
)

View.propTypes = {
  footer: node.isRequired,
  regionCode: oneOf(Object.keys(region).sort()).isRequired,
  subtitle: oneOfType([element, string]).isRequired,
  title: string.isRequired
}
View.defaultProps = {
  subtitle: <Subtitle />
}

View.displayName = 'State'

export { View }
