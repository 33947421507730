import { forwardRef } from 'react'
import { CheckOutlined } from '@material-ui/icons'
import clsx from 'clsx'
import { bool, number, oneOfType, string } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'
import { SignificantIndicator } from 'components/SvgIcon'
import { featureFlags } from 'shared/constants/featureFlags'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

const View = forwardRef(({
  amount,
  id,
  hasGap,
  isHighlighted,
  label,
  percentage,
  unit,
  contentWidth,
  isMounted
}, ref) => {
  const actualPercentage = isMounted ? percentage : 0
  const roundPercentage = Math.round(actualPercentage)
  const animationStyles = {
    minWidth: contentWidth,
    width: `${roundPercentage}%`
  }

  const { isLoading, featureFlags: flags } = useFeatureFlags()
  const isAnimationDisabled = !isLoading &&
  flags?.[featureFlags.SALES_AID_SKIP_ANIMATION]

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.label, {
          [styles.labelHighlighted]: isHighlighted
        })}
        data-testid="row-label"
      >
        {label}
      </div>
      <div
        ref={ref}
        className={clsx(styles.content, {
          [styles.contentHighlighted]: isHighlighted
        })}
        data-testid="row-content"
      >
        <span className={styles.amount} {...qaAttributes({ id })}>
          {amount}
        </span>
        <span className={styles.unit}>{unit}</span>
      </div>
      <div
        className={clsx(
          isAnimationDisabled ? styles['no-animate'] : styles.animate,
          {
            [styles.animateHighlighted]: isHighlighted
          })}
        style={animationStyles}
      >
        {hasGap && (
          <div className={styles.gap}>
            <SignificantIndicator />
          </div>
        )}
        {isHighlighted && (
          <div className={styles.check}>
            <CheckOutlined />
          </div>
        )}
      </div>
    </div>
  )
})

View.propTypes = {
  amount: oneOfType([number.isRequired, string.isRequired]),
  contentWidth: number,
  hasGap: bool,
  id: string.isRequired,
  isHighlighted: bool,
  isMounted: bool,
  label: string,
  percentage: number,
  unit: string.isRequired
}

View.defaultProps = {
  hasGap: false,
  isHighlighted: false,
  percentage: 100
}
View.displayName = 'Row'
export { View }
