import { useLocation } from 'react-router-dom'
import { useLead } from 'hooks/useLead'
import { useAnalytics } from 'hooks/useAnalytics'
import { useIsCoBrowse } from 'hooks/useIsCoBrowse'
import { useSteps } from 'hooks/useSteps'
import { getApplicationData } from 'hooks/useTrackEvents/utils/getApplicationData'

const useTrackData = () => {
  const { pathname } = useLocation()
  const { fdrApplicantId, ownerId } = useLead()
  const analytics = useAnalytics()
  const [isCoBrowse] = useIsCoBrowse()
  const { all, current } = useSteps()
  const pageName = current?.label
  const applicationData = getApplicationData(
    {
      isCoBrowse,
      ownerId,
      pageName,
      pathname
    },
    all
  )
  return {
    analytics,
    applicationData,
    fdrApplicantId,
    isCoBrowse,
    ownerId,
    pageName,
    pathname
  }
}

export default useTrackData
