import { number, shape, bool, string, arrayOf, oneOfType } from 'prop-types'
import { DebtHeader } from 'components/DebtHeader'
import { StepHeader } from 'components/StepHeader'
import { qaId } from 'consts/qaId'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'
import { format } from './utils'
import React from 'react'
import { LegalFooter } from 'components/Disclosures/LegalFooter'

const View = ({
  eligibleDebt,
  monthlyPayments,
  debtReduction,
  hasSavings,
  subtitle,
  title,
  bodyTitle,
  programPaymentLabel,
  items,
  footerLabel,
  monthlyPaymentFooter,
  pageName
}) => (
  <div className={styles.container}>
    <StepHeader subtitle={subtitle} title={title} />
    <div className={styles.topSpacer} />
    <DebtHeader
      debt={eligibleDebt}
      id={qaId.TOTAL_ESTIMATED_DEBT}
      title={bodyTitle}
    />
    <div className={styles.middleSpacer} />
    <div className={styles.breakdown}>
      <section className={styles.paymentSummary}>
        <h2
          {...qaAttributes({
            id: qaId.YOUR_SOLUTION_HEADER_LOWEST_MONTHLY_PAYMENT
          })}
          className={styles.lowestMonthlyPayment}
        >
          {programPaymentLabel}
        </h2>
        <div
          {...qaAttributes({
            id: qaId.YOUR_SOLUTION_LOWEST_MONTHLY_PAYMENT
          })}
          className={styles.estimatedMonthlyPayment}
        >
          {format(monthlyPayments.min)}
        </div>
        <div
          {...qaAttributes({
            id: qaId.YOUR_SOLUTION_LOWEST_MONTHLY_PAYMENT_FOOTER
          })}
          className={styles.unit}
        >
          {monthlyPaymentFooter}
        </div>
      </section>
      <div className={styles.splitter} />
      <section aria-label="Payment schedule" className={styles.schedule}>
        {items.map(({ value, label, labelId, valueId }) => (
          <React.Fragment key={valueId}>
            <div
              {...qaAttributes({
                id: labelId
              })}
              className={styles.scheduleLabel}
            >
              {label}
            </div>
            <div
              {...qaAttributes({
                id: valueId
              })}
              className={styles.scheduleValue}
            >
              {value}
            </div>
          </React.Fragment>
        ))}
      </section>
    </div>
    <div className={styles.bottomSpacer} />
    {hasSavings && (
      <DebtHeader
        debt={debtReduction}
        id={qaId.ESTIMATED_BENEFIT}
        title={footerLabel}
      />
    )}
    <LegalFooter pageName={pageName}/>
  </div>
)

View.propTypes = {
  bodyTitle: string.isRequired,
  debtReduction: number.isRequired,
  duration: shape({
    max: number.isRequired,
    min: number.isRequired
  }).isRequired,
  eligibleDebt: number.isRequired,
  footerLabel: string.isRequired,
  hasSavings: bool,
  items: arrayOf(
    shape({
      label: string.isRequired,
      labelId: string.isRequired,
      value: oneOfType([string, number]).isRequired,
      valueId: string.isRequired
    })
  ),
  monthlyPaymentFooter: string,
  monthlyPayments: shape({
    max: number.isRequired,
    min: number.isRequired
  }).isRequired,
  pageName: string,
  programPaymentLabel: string.isRequired,
  subtitle: string.isRequired,
  title: string.isRequired,
  totalCost: number.isRequired
}

View.displayName = 'YourSolution.View'

export { View }
