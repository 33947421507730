import React from 'react'
import { DataSection } from 'components/DataSection'
import { qaId } from 'consts/qaId'
import { qaAttributes } from 'utils/qaAttributes'
import { array, object, string } from 'prop-types'
import styles from './index.module.scss'
import { StepHeader } from 'components/StepHeader'
import { FullBleed } from 'components/Layout/FullBleed'

const View = ({ figures, logo, title }) => (
  <FullBleed>
    <img alt={logo.title} async className={styles.logo} src={logo.file.url} />
    <div className={styles.content}>
      <StepHeader
        className={styles.headerWrapper}
        title={title}
        {...qaAttributes({
          id: qaId.ABOUT_PAGE_TITLE
        })}
      />
      <div className={styles.figures}>
        {figures.map((f) => (
          <DataSection
            key={f.sub_text}
            footer={() => React.createElement('span', null, f.sub_text)}
            id={qaId.ABOUT_PAGE_FIGURES}
          >
            {f.value}
          </DataSection>
        ))}
      </div>
    </div>
  </FullBleed>
)

View.propTypes = {
  backgroundUrl: string.isRequired,
  figures: array.isRequired,
  logo: object.isRequired,
  title: string.isRequired
}

export { View }
