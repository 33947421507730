import { bool, number, oneOfType, string } from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { View } from './View'

const Container = (props) => {
  const [isMounted, setIsMounted] = useState(false)
  const [contentWidth, setContentWidth] = useState(0)
  const ref = useRef()

  useEffect(() => setIsMounted(true), [])

  useEffect(() => setContentWidth(ref.current ? ref.current.offsetWidth : 0), [
    isMounted,
    setContentWidth
  ])

  return (
    <View
      ref={ref}
      contentWidth={contentWidth}
      isMounted={isMounted}
      {...props}
    />
  )
}

Container.propTypes = {
  amount: oneOfType([number.isRequired, string.isRequired]),
  hasGap: bool,
  id: string.isRequired,
  isHighlighted: bool,
  label: string,
  percentage: number,
  unit: string.isRequired
}

Container.defaultProps = {
  hasGap: false,
  isHighlighted: false,
  percentage: 100
}

export { Container }
