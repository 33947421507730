import { useEffect } from 'react'
import { View } from './View'
import { useContent } from 'hooks/useContent'
import { useLead } from 'hooks/useLead'
import { Status } from 'consts/status'

const updateDataLayer = (dataItem) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(dataItem)
}

const Container = (props) => {
  const lead = useLead()
  const { content, state } = useContent()
  const isLoading = lead.state === Status.LOADING || state === Status.LOADING
  const error = content.error || lead.error

  useEffect(() => {
    if (lead.fdrApplicantId) {
      const { fdrApplicantId } = lead || {}
      fdrApplicantId && updateDataLayer({ fdrApplicantId })
    }
  }, [lead])

  return (
    <View {...props} error={error} isLoading={isLoading} />
  )
}

Container.displayName = 'AuthorizedRoute.Container'

export { Container }
