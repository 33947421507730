import { DataSection } from 'components/DataSection'
import { number, string } from 'prop-types'

const { format } = new Intl.NumberFormat('en-us', {
  currency: 'USD',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
  style: 'currency'
})

const View = ({ debt, title, ...props }) => (
  <DataSection title={title} {...props}>{format(debt)}</DataSection>
)

View.propTypes = {
  debt: number.isRequired,
  title: string.isRequired
}

View.displayName = 'DebtHeader'

export { View }
