import { selectors } from 'store'
import { useRecoilValueLoadable } from 'recoil'
import { restrictedLead } from 'shared/utils/restrictedLead'
import { read as queryParams } from 'utils/queryParams'
import { Status } from 'consts/status'

const useLead = () => {
  const { contents, state } = useRecoilValueLoadable(selectors.leadSelector)

  return state === Status.LOADING
    ? { state: Status.LOADING }
    : {
        ...contents,
        ...restrictedLead(queryParams())
      }
}

export { useLead }
