const whileAnimateStyles = {
  overflow: 'hidden',
  position: 'absolute'
}

const transitionVariants = Object.freeze({
  CONTENT: {
    collapsed: {
      height: '2rem',
      overflow: 'hidden',
      transitionEnd: {
        WebkitLineClamp: '2'
      }
    },
    expanded: {
      height: 'auto',
      transitionEnd: {
        WebkitLineClamp: 'unset'
      }
    }
  },
  center: {
    overflow: 'visible',
    position: 'relative',
    x: 0
  },
  enter: direction => ({
    x: direction > 0 ? '100vw' : '-100vw',
    ...whileAnimateStyles
  }),
  exit: direction => ({
    opacity: 0,
    x: direction > 0 ? '-100vw' : '100vw',
    ...whileAnimateStyles
  })
})

const pageTransition = Object.freeze({
  duration: 1.0,
  ease: 'anticipate',
  type: 'spring'
})

export { transitionVariants, pageTransition }
