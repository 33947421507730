const toText = (content) => {
  if (content?.data?.target) {
    return toText(content.data.target)
  }
  if (Array.isArray(content)) {
    return toText(content[0])
  } else if (content?.content) {
    return toText(content.content)
  } else if (content?.value) {
    return content.value
  }
}

export { toText }
