import { string, func, number } from 'prop-types'
import { Arrow } from './Arrow'

const View = ({
  currentPath,
  nextPath,
  previousPath,
  currentIndex,
  setCurrentIndex
}) => {
  const state = { prevPath: currentPath }
  const hrefPrevious = {
    pathname: previousPath,
    state
  }
  const hrefNext = {
    pathname: nextPath,
    state
  }

  return (
    <>
      {previousPath && (
        <Arrow
          href={hrefPrevious}
          isPrevious
          onClick={() => setCurrentIndex(currentIndex - 1)}
        />
      )}
      {nextPath && (
        <Arrow
          href={hrefNext}
          onClick={() => setCurrentIndex(currentIndex + 1)}
        />
      )}
    </>
  )
}

View.propTypes = {
  currentIndex: number,
  currentPath: string,
  nextPath: string,
  previousPath: string,
  setCurrentIndex: func
}

View.displayName = 'Navigation.View'

export { View }
