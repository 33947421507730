import cx from 'classnames'
import { number, string } from 'prop-types'
import styles from './index.module.scss'

const View = ({ alt, className, height, src, width, ...props }) => (
  <img
    {...props}
    alt={alt}
    className={cx(styles.img, className)}
    src={src}
  />
)

View.defaultProps = {
  height: 20,
  width: 20
}

View.propTypes = {
  alt: string.isRequired,
  className: string,
  height: number,
  src: string.isRequired,
  width: number
}

export { View }
