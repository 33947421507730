import { Grid } from '@material-ui/core'
import { qaId } from 'consts/qaId'
import { qaAttributes } from 'utils/qaAttributes'
import { BadgeCoApplicant } from 'components/SvgIcon'

const View = (props) => (
  <Grid item xs={12} {...props}>
    <BadgeCoApplicant
      {...qaAttributes({
        id: qaId.YOUR_DEBTS_CREDITOR_CO_APPLICANT
      })}
      alt="Co Applicant"
    />
  </Grid>
)

export { View }
