import { Grid } from '@material-ui/core'
import cx from 'classnames'
import { ArrowRight } from 'components/SvgIcon'
import { clickEvent } from 'consts/clickEvent'
import { qaId } from 'consts/qaId'
import { func, number, string, bool } from 'prop-types'
import { currencyFormat } from 'utils/currencyFormat'
import { qaAttributes } from 'utils/qaAttributes'
import { CoApplicant } from './CoApplicant'
import styles from './index.module.scss'
import { UtilizationWarning } from './UtilizationWarning'

const normalizeCreditor = (creditor) => creditor.replace(/[\W_]+/g, '_')

const toUniqueClickEventId = (creditor) =>
  `${clickEvent.YOUR_DEBTS_TRADE_LINE}_${normalizeCreditor(creditor)}`

const View = ({
  creditor,
  balance,
  index,
  utilization,
  hasCoApplicant,
  onClick
}) => {
  const isWarningVisible = utilization >= 75

  return (
    <button
      className={styles.button}
      id={toUniqueClickEventId(creditor)}
      onClick={onClick}
    >
      <Grid classes={{ root: styles.root }} container xs={12}>
        {hasCoApplicant ? <CoApplicant className={styles.coApplicant} /> : null}
        <Grid
          className={cx(styles.name, {
            [styles.hasCoApplicant]: hasCoApplicant
          })}
          item
          xs={6}
        >
          {isWarningVisible && <UtilizationWarning />}
          <span
            {...qaAttributes({
              id: `${qaId.YOUR_DEBTS_CREDITOR_PREFIX}-${index}`
            })}
            title={creditor}
          >
            {creditor}
          </span>
        </Grid>
        <Grid
          align="left"
          className={cx(styles.amount, {
            [styles.hasCoApplicant]: hasCoApplicant
          })}
          item
          xs={6}
        >
          <span
            {...qaAttributes({
              id: `${qaId.YOUR_DEBTS_BALANCE_PREFIX}-${index}`
            })}
          >
            {currencyFormat(balance)}
          </span>
          <ArrowRight
            {...qaAttributes({
              id: `${qaId.YOUR_DEBTS_ARROW_PREFIX}-${index}`
            })}
            className={cx(styles.amountIcon, styles.floatRight)}
          />
        </Grid>
      </Grid>
    </button>
  )
}

View.propTypes = {
  balance: number,
  creditor: string.isRequired,
  hasCoApplicant: bool,
  index: number.isRequired,
  onClick: func,
  utilization: number
}

View.displayName = 'Tradeline'

export { View }
