import React from 'react'
import { View } from './View'
import { useContent } from 'hooks/useContent'
import { contentHelper } from './content'

export const Container = () => {
  const { content } = useContent()
  const props = contentHelper(content)
  return <View {...props} />
}
