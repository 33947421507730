import React from 'react'
import { View } from './View'
import { useContent } from 'hooks/useContent'

export const Container = () => {
  const { content: { financialGoalsPage } } = useContent()
  const props = {
    items: financialGoalsPage.items,
    title: financialGoalsPage.title
  }
  return <View {...props}/>
}
