// 10_500_000_000 => 11
const roundToTheNearestBillion = amount =>
  Math.round(amount / 1_000_000_000)

const { format: numberFormatter } = new Intl.NumberFormat({
  maximumSignificantDigits: 0
})

// 9_590 => 10_000
const roundToTheNearestThousand = (count) => Math.round(count / 1_000) * 1_000

// 45_000 => 50_000
const roundToTheNearestTenThousand = (count) =>
  Math.round(count / 10_000) * 10_000

export {
  numberFormatter,
  roundToTheNearestBillion,
  roundToTheNearestThousand,
  roundToTheNearestTenThousand
}
