import { useYourSolution } from 'hooks/useYourSolution'
import { View } from './View'
import PropTypes from 'prop-types'
import { standard } from './constants'
import { useItems } from './utils'

const Container = () => {
  const yourSolution = useYourSolution()
  const items = useItems({
    labels: standard.labels
  })
  return (
    <View
      bodyTitle={standard.bodyTitle}
      footerLabel={standard.footerLabel}
      hasSavings={yourSolution.debtReduction > 0}
      items={items}
      monthlyPaymentFooter={standard.monthlyPaymentFooter}
      programPaymentLabel={standard.programPaymentLabel}
      subtitle={standard.subtitle}
      title={standard.title}
      {...yourSolution}
    />
  )
}

Container.displayName = 'YourSolution.Container'

Container.propTypes = {
  subtitle: PropTypes.string
}

Container.defaultProps = {
  subtitle: 'Total Estimated Debt'
}

export { Container }
