import { useIsCoBrowse } from 'hooks/useIsCoBrowse'
import { useLead } from 'hooks/useLead'
import { useCallback, useEffect } from 'react'
import { useIsAgent } from '../hooks/useIsAgent'
import { useUpscope } from '../hooks/useUpscope'
import {
  EVENTS,
  EVENT_SESSION_END,
  AGENT,
  CLIENT,
  EVENT_SESSION_START,
  EVENT_SESSION_REQUEST
} from '../constants'
import { useSteps } from 'hooks/useSteps'
import { analyticsTrackEvent } from 'consts/analyticsTrackEvent'
import useTrackEvents from 'hooks/useTrackEvents'

let areEventsInitialized = false

const eventCallback =
  ({ beCoBrowse, event, setPage, trackCoBrowseChange }) =>
    () => {
      const isInCoBrowse = event === EVENT_SESSION_START
      beCoBrowse(isInCoBrowse)
      switch (event) {
        case EVENT_SESSION_REQUEST:
          trackCoBrowseChange(analyticsTrackEvent.CO_BROWSE_SESSION_REQUEST)
          break
        case EVENT_SESSION_START:
          setPage(0)
          trackCoBrowseChange(analyticsTrackEvent.CO_BROWSE_AGENT_JOIN)
          break
        case EVENT_SESSION_END:
          trackCoBrowseChange(analyticsTrackEvent.CO_BROWSE_SESSION_END)
          break
        default:
      }
    }

const Container = () => {
  const { fdrApplicantId, firstName, lastName } = useLead()
  const isAgent = useIsAgent()
  const upscope = useUpscope()
  const [isCoBrowse, beCoBrowse] = useIsCoBrowse()
  const { setPage } = useSteps()
  const { trackCoBrowseChange } = useTrackEvents()

  const initEvents = useCallback(() => {
    if (!areEventsInitialized) {
      EVENTS.forEach((event) => {
        upscope(
          'on',
          event,
          eventCallback({
            beCoBrowse,
            event,
            fdrApplicantId,
            isAgent,
            setPage,
            trackCoBrowseChange
          })
        )
      })

      areEventsInitialized = true
    }
  }, [
    upscope,
    beCoBrowse,
    fdrApplicantId,
    isAgent,
    setPage,
    trackCoBrowseChange
  ])

  useEffect(() => {
    if (upscope) {
      upscope('updateConnection', {
        identities: [firstName, lastName, isAgent ? AGENT : CLIENT],
        uniqueId: fdrApplicantId
      })

      initEvents()
    }
  }, [fdrApplicantId, firstName, initEvents, isAgent, lastName, upscope])

  useEffect(() => {
    const coBrowseAttribute = 'upscope-co-browse'

    document.body.setAttribute(coBrowseAttribute, isCoBrowse)
  }, [isCoBrowse])

  return null
}

export { Container, eventCallback }
