const addTealiumScript = (TEALIUM_UTAG_URL) => {
  // This script is copied directly from the Tealium docs. ESLint is disabled
  // in order to maintiain it's exactness.
  /* eslint-disable */
  
    (function (a, b, c, d) {
      a = TEALIUM_UTAG_URL;
      b = document;
      c = 'script';
      d = b.createElement(c);
      d.src = a;
      d.type = 'text/java' + c;
      d.async = true;
      a = b.getElementsByTagName(c)[0];
      a.parentNode.insertBefore(d, a);
    })();
    /* eslint-enable */
}

export { addTealiumScript }
