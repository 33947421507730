import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { StepHeader } from 'components/StepHeader'
import { InputGroup } from './InputGroup'

const View = ({ title, items, values, setValues }) => (
  <>
    <StepHeader title={title} />
    <div className={styles.container}>
      {items.map((inputProps, index) => (
        <InputGroup
          {...inputProps}
          key={index}
          index={index}
          onChange={setValues}
          value={values[inputProps.id]}
        />
      ))}
    </div>
  </>
)

View.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      icon: PropTypes.node,
      label: PropTypes.string
    })
  ).isRequired,
  setValues: PropTypes.func,
  title: PropTypes.string.isRequired,
  values: PropTypes.object
}

View.displayName = 'YourBudgetSummary.View'

export { View }
