import { clickEvent } from 'consts/clickEvent'
import { node, oneOf, shape, string, bool } from 'prop-types'
import { useState } from 'react'
import { CollapsibleCard } from './CollapsibleCard'

const title = 'Read about our policy'

const View = ({
  ariaLabels,
  children,
  clickEvents,
  isExpandedInitially,
  toggleTexts
}) => {
  const [isExpanded, beExpanded] = useState(isExpandedInitially)
  const onToggle = () => beExpanded(!isExpanded)
  const ariaLabel = isExpanded ? ariaLabels.expanded : ariaLabels.collapsed
  const id = isExpanded ? clickEvents?.collapsed : clickEvents?.expanded

  return (
    <CollapsibleCard
      ariaLabel={ariaLabel}
      id={id}
      isExpanded={isExpanded}
      onToggle={onToggle}
      title={title}
      toggleTexts={toggleTexts}
    >
      {children}
    </CollapsibleCard>
  )
}

View.defaultProps = {
  ariaLabels: {
    collapsed: 'Expand to read more about of our policy',
    expanded: 'Collapse to show less of our policy'
  },
  html: undefined,
  isExpandedInitially: false,
  leadText: undefined,
  toggleTexts: undefined
}

View.propTypes = {
  ariaLabels: shape({
    collapsed: string.isRequired,
    expanded: string.isRequired
  }),
  children: node,
  clickEvents: shape({
    collapsed: oneOf(Object.values(clickEvent)).isRequired,
    expanded: oneOf(Object.values(clickEvent)).isRequired
  }),
  isExpandedInitially: bool,
  toggleTexts: shape({
    collapsed: string.isRequired,
    expanded: string.isRequired
  })
}
View.displayName = 'Legal'
export { View }
