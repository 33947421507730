import { findStepPathIndex } from 'hooks/useSteps'

const getApplicationData = (
  { isCoBrowse, ownerId, pathname, pageName: page_name = 'UNKNOWN' },
  routes
) => ({
  isInCoBrowseSession: isCoBrowse,
  ownerId,
  page_name,
  page_order_number: findStepPathIndex(pathname, routes) + 1
})

export { getApplicationData }
