import { StepHeader } from 'components/StepHeader'
import styles from './index.module.scss'

const View = () => (
  <div className={styles.container}>
    <StepHeader
      subtitle='Page not found'
      title='404'
    />
  </div>
)

export { View }
