import { number } from 'prop-types'
import styles from './index.module.scss'

const View = ({ debtResolvedInBillions }) => (
  <p className={styles.container}>
    <span>Over <strong>${debtResolvedInBillions} billion</strong> debt resolved since
    2002.</span>
  </p>
)

View.propTypes = {
  debtResolvedInBillions: number.isRequired
}

View.displayName = 'Subtitle'

export { View }
