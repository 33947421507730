import { Menu, MenuItem } from '@material-ui/core'
import cx from 'classnames'
import { ArrowLeft } from 'components/SvgIcon'
import { qaId } from 'consts/qaId'
import { arrayOf, bool, func, node, shape, string } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const View = ({
  anchorEl,
  currentPath,
  isDark,
  links,
  text,
  onAnchorClick,
  onClose,
  onNavigate,
  ...props
}) => {
  const darkClass = { [styles.dark]: isDark }
  const onClick = (href) => (href ? onNavigate(href) : onClose())

  return (
    <>
      <button
        {...qaAttributes({ id: `${qaId.NAV_DESKTOP_PREFIX}-open-menu` })}
        {...props}
        className={cx(styles.button, darkClass)}
        onClick={onAnchorClick}
      >
        {text}
        <div className={styles.arrowContainer}>
          <ArrowLeft className={cx(styles.arrow, darkClass)} />
        </div>
      </button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        className={styles.menu}
        getContentAnchorEl={null}
        keepMounted
        onClose={onClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
      >
        {links.map(({ clickEventId, label, path }) => (
          <MenuItem
            {...qaAttributes({ id: `${qaId.NAV_DESKTOP_PREFIX}-${path}` })}
            key={path}
            className={styles.item}
            onClick={() => onClick(currentPath === path ? null : path)}
            to={path}
          >
            <span className={styles.link} id={`${clickEventId}_desktop`}>
              {label}
            </span>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

View.propTypes = {
  anchorEl: node,
  currentPath: string,
  id: string.isRequired,
  isDark: bool,
  links: arrayOf(
    shape({
      clickEventId: string.isRequired,
      label: string.isRequired,
      path: string.isRequired
    })
  ),
  onAnchorClick: func.isRequired,
  onClose: func.isRequired,
  onNavigate: func.isRequired,
  text: string.isRequired
}

View.displayName = 'Dropdown.View'

export { View }
