import { Hidden } from '@material-ui/core'
import cx from 'classnames'
import { bool, element, func, node, oneOfType, string } from 'prop-types'
import { DesktopNav } from './DesktopNav'
import { Footer } from './Footer'
import { Header } from './Header'
import styles from './index.module.scss'
import { Main } from './Main'
import { MobileNav } from './MobileNav'
import { SwipeNavigate } from 'components/SwipeNavigate'

const View = ({
  beMobileNavOpen,
  children,
  isMobileNavOpen,
  hideFooter,
  hideHeader,
  backgroundImage,
  hideMenu
}) => (
  <>
    <Hidden mdUp>
      <MobileNav
        hideMenu={hideMenu}
        isOpen={isMobileNavOpen}
        onClose={() => beMobileNavOpen(false)}
      />
    </Hidden>
    <div
      className={cx(styles.container, {
        [styles.withBG]: backgroundImage
      })}
      style={{
        backgroundImage
      }}
    >
      {!hideHeader && (
        <Header
          beMobileNavOpen={beMobileNavOpen}
          className={styles.header}
          hideMenu={hideMenu}
        >
          {!hideMenu && <DesktopNav />}
        </Header>
      )}
      <SwipeNavigate>
        <Main>{children}</Main>
      </SwipeNavigate>
      {!hideFooter && <Footer />}
    </div>
  </>
)

View.propTypes = {
  backgroundImage: string,
  beMobileNavOpen: func.isRequired,
  children: oneOfType([element, node]),
  hideFooter: bool.isRequired,
  hideHeader: bool,
  hideMenu: bool,
  isMobileNavOpen: bool.isRequired
}

View.defaultProps = {
  hideFooter: false,
  hideHeader: false,
  hideMenu: false
}

export { View }
