import { useDarkMode } from 'hooks/useDarkMode'
import { View } from './View'

const Container = () => {
  const darkMode = useDarkMode()

  return <View isDark={darkMode} />
}

Container.displayName = 'PageLoadingSpinner.Container'

export { Container }
