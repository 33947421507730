import { string, oneOfType, shape } from 'prop-types'
import { Redirect } from 'react-router-dom'
import { usePreserveToQuery } from 'hooks/usePreserveToQuery'

const View = ({ to, ...props }) => (
  <Redirect
    {...props}
    to={usePreserveToQuery(to)}
  />
)

View.displayName = 'RedirectWithQuery'

View.propTypes = {
  to: oneOfType([
    string,
    shape({
      pathname: string,
      state: shape({
        prevPath: string
      })
    })
  ])
}

export { View }
