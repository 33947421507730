import { useLead } from 'hooks/useLead'
import { useContent } from 'hooks/useContent'
import { View } from './View'
import { bool } from 'prop-types'
import { useAnalytics } from 'hooks/useAnalytics'
import { initConfetti } from './confetti'
import { USER_ACTION } from 'consts/clickEvent'
import { EVENT_ACTION } from '@achieve/cx-event-horizon/browser'
import { useSteps } from 'hooks/useSteps'
import { contentHelper } from './content'

const Container = ({ callPhone }) => {
  const lead = useLead()
  const { content } = useContent()
  const {
    buttonLabel,
    title,
    items: valueProps,
    phoneHref,
    footerText
  } = contentHelper(lead, content)
  const analytics = useAnalytics()
  const { current } = useSteps()

  const ctaOnClick = (e) => {
    analytics.track(`${buttonLabel} - ${USER_ACTION.CTA_CLCIKED}`, {
      event_action: EVENT_ACTION.BUTTON_CLICK,
      page_name: current?.label
    })
    if (!callPhone) {
      initConfetti()
    }
  }

  return (
    <View
      analytics={analytics}
      buttonLabel={buttonLabel}
      ctaOnClick={ctaOnClick}
      description={current?.description}
      footerText={footerText}
      isSelfGuided={callPhone}
      phoneHref={callPhone ? phoneHref : ''}
      title={title}
      valueProps={valueProps}
    />
  )
}

Container.propTypes = {
  callPhone: bool.isRequired
}

Container.defaultProps = {
  callPhone: false
}

export { Container }
