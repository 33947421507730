import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import cx from 'classnames'
import { clickEvent } from 'consts/clickEvent'
import { latePayment } from 'consts/latePayment'
import { qaId } from 'consts/qaId'
import { func, number, string, shape } from 'prop-types'
import { currencyFormat } from 'utils/currencyFormat'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'
import { Utilization } from './Utilization'

const isLatePayment = (latePaymentType) =>
  [latePayment.CURRENT, latePayment.UNKNOWN].includes(latePaymentType) === false

const View = ({
  creditor,
  balance,
  accountNumber,
  utilization,
  monthlyPayment,
  latePayment,
  latePaymentEs,
  handleClose,
  dialogLabels,
  locale,
  ...props
}) => (
  <Dialog {...props} aria-labelledby="tradeline-detail-dialog">
    <DialogTitle
      {...qaAttributes({
        id: qaId.YOUR_DEBTS_CREDITOR_DIALOG_CREDITOR
      })}
      className={styles.actionColorAndFont}
      disableTypography={true}
      id="tradeline-detail-dialog"
    >
      {creditor}
    </DialogTitle>
    <DialogContent dividers>
      <table className={styles.detailsTable}>
        <tbody>
          <tr>
            <th
              {...qaAttributes({
                id: qaId.YOUR_DEBTS_CREDITOR_DIALOG_BALANCE
              })}
            >
              {dialogLabels.balance}
            </th>
            <td
              {...qaAttributes({
                id: qaId.YOUR_DEBTS_CREDITOR_DIALOG_BALANCE_VALUE
              })}
            >
              {currencyFormat(balance)}
            </td>
          </tr>
          <tr>
            <th
              {...qaAttributes({
                id: qaId.YOUR_DEBTS_CREDITOR_DIALOG_ACCOUNT_NUMBER
              })}
            >
              {dialogLabels.account}
            </th>
            <td
              {...qaAttributes({
                id: qaId.YOUR_DEBTS_CREDITOR_DIALOG_ACCOUNT_NUMBER_VALUE
              })}
            >
              {accountNumber}
            </td>
          </tr>
          {Boolean(monthlyPayment) && (
            <tr>
              <th
                {...qaAttributes({
                  id: qaId.YOUR_DEBTS_CREDITOR_DIALOG_MIN_MONTHLY_PAYMENT
                })}
              >
                {dialogLabels.monthlyPayment}
              </th>
              <td
                {...qaAttributes({
                  id: qaId.YOUR_DEBTS_CREDITOR_DIALOG_MIN_MONTHLY_PAYMENT_VALUE
                })}
              >
                {currencyFormat(monthlyPayment)}
              </td>
            </tr>
          )}
          <tr>
            <th
              {...qaAttributes({
                id: qaId.YOUR_DEBTS_CREDITOR_DIALOG_LATE_PAYMENT
              })}
            >
              {dialogLabels.latePayment}
            </th>
            <td
              {...qaAttributes({
                id: qaId.YOUR_DEBTS_CREDITOR_DIALOG_LATE_PAYMENT_VALUE
              })}
              className={cx({
                [styles.red]: isLatePayment(latePayment)
              })}
              data-testid="td-dialog-late-payment"
            >
              {locale === 'es' ? latePaymentEs : latePayment}
            </td>
          </tr>
          <tr>
            <th
              {...qaAttributes({
                id: qaId.YOUR_DEBTS_CREDITOR_DIALOG_UTILIZATION
              })}
            >
              {dialogLabels.utilization}
            </th>
            <td
              {...qaAttributes({
                id: qaId.YOUR_DEBTS_CREDITOR_DIALOG_UTILIZATION_VALUE
              })}
            >
              <Utilization utilization={utilization} />
            </td>
          </tr>
        </tbody>
      </table>
    </DialogContent>
    <DialogActions classes={{ root: styles.dialogActions }}>
      <Button
        {...qaAttributes({
          id: `${qaId.YOUR_DEBTS_CREDITOR_DIALOG_CLOSE_PREFIX}-${accountNumber}`
        })}
        className={styles.actionColorAndFont}
        color="primary"
        id={clickEvent.YOUR_DEBTS_TRADE_LINE_DIALOG_CLOSE}
        onClick={handleClose}
      >
        {dialogLabels.closeLabel}
      </Button>
    </DialogActions>
  </Dialog>
)

View.propTypes = {
  accountNumber: string.isRequired,
  balance: number,
  creditor: string.isRequired,
  dialogLabels: shape({
    account: string.isRequired,
    balance: string.isRequired,
    closeLabel: string.isRequired,
    latePayment: string.isRequired,
    monthlyPayment: string.isRequired,
    utilization: string.isRequired
  }).isRequired,
  handleClose: func,
  latePayment: string,
  latePaymentEs: string,
  locale: string,
  monthlyPayment: number,
  utilization: number
}

View.defaultProps = {
  handleClose: () => { }
}

View.displayName = 'TradelineDetailDialog'

export { View }
