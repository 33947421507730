import { Typography } from '@material-ui/core'
import { FullBleed } from 'components/Layout/FullBleed'
import { Header } from 'components/Layout/Header'
import { Logo, variant as logoVariant } from 'components/Logo'
import { qaId } from 'consts/qaId'
import { string } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const View = ({
  firstName,
  lastName,
  coApplicantFirstName,
  coApplicantLastName
}) => (
  <FullBleed>
    <div className={styles.headerContainer}>
      <Header isMinimal />
    </div>
    <Logo className={styles.logo} variant={logoVariant.WHITE} />
    <div className={styles.content}>
      <div className={styles.hero}>
        <span className={styles.heroText}>Personal Debt Consultation For:</span>
        <Typography className={styles.name} variant="h1">
          <span
            {...qaAttributes({
              id: qaId.INTRO_FULL_NAME
            })}
          >
            {firstName} {lastName}
          </span>
          {Boolean(coApplicantFirstName) && Boolean(coApplicantLastName) && (
            <>
              <div className={styles.coAppAnd}>and</div>
              <span
                {...qaAttributes({
                  id: qaId.INTRO_CO_APP_FULL_NAME
                })}
              >
                {coApplicantFirstName} {coApplicantLastName}
              </span>
            </>
          )}
        </Typography>
      </div>
      <div className={styles.explanation}>
        <Typography variant="body1">
          When you enroll with Freedom Debt Relief, you'll have one of the
          leading debt relief companies on your side.
        </Typography>
        <Typography variant="body1">
          We've resolved over <strong>$14 billion</strong> in debt for our
          clients since 2002, and we're excited for the opportunity to help you!
        </Typography>
      </div>
    </div>
  </FullBleed>
)

View.propTypes = {
  coApplicantFirstName: string,
  coApplicantLastName: string,
  firstName: string.isRequired,
  lastName: string.isRequired
}

export { View }
