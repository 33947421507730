const qaAttributes = ({ id, suffix }) => {
  const finalSuffix = suffix
    ? `-${suffix}`
    : ''

  return {
    'data-elm-id': `px-${id}${finalSuffix}`
  }
}

export { qaAttributes }
