import PropTypes from 'prop-types'
import { StepHeader } from 'components/StepHeader'
import { currencyFormat } from 'utils/currencyFormat'
import { IndicatorChart } from './IndicatorChart'
import { IncomeExpenceCard } from './IncomeExpenceCard'
import { variant as IncomeExpenceVariant } from './IncomeExpenceCard/constants'
import { qaId } from 'consts/qaId'
import { qaAttributes } from 'utils/qaAttributes'

import styles from './index.module.scss'

const View = ({
  title,
  monthlyDiscretionaryIncomeLabel,
  monthlyExpensesLabel,
  monthlyIncomeLabel,
  monthlyDiscretionaryIncome,
  monthlyIncome,
  monthlyExpenses
}) => (
  <>
    <StepHeader title={title} />
    <div className={styles.container}>
      <div className={styles.incomeExpencesRow}>
        <IncomeExpenceCard
          title={monthlyIncomeLabel}
          value={monthlyIncome}
          variation={IncomeExpenceVariant.GREEN}
        />
        <IncomeExpenceCard
          title={monthlyExpensesLabel}
          value={monthlyExpenses}
          variation={IncomeExpenceVariant.RED}
        />
      </div>
      <div className={styles.discretionaryIncomeRow}>
        <div
          className={styles.discretionaryIncomeRowTitle}
          {...qaAttributes({
            id: qaId.INCOME_EXPENSE_SUMMARY_DISCRETIONARY_TITTLE
          })}>
          {monthlyDiscretionaryIncomeLabel}
        </div>
        <div
          {...qaAttributes({
            id: qaId.INCOME_EXPENSE_SUMMARY_DISCRETIONARY_VALUE
          })}>
          {currencyFormat(monthlyDiscretionaryIncome)}</div>
      </div>
      <IndicatorChart
        percentage={(monthlyDiscretionaryIncome * 100) / monthlyIncome}
      />
    </div>
  </>
)

View.propTypes = {
  monthlyDiscretionaryIncome: PropTypes.number.isRequired,
  monthlyDiscretionaryIncomeLabel: PropTypes.string.isRequired,
  monthlyExpenses: PropTypes.number.isRequired,
  monthlyExpensesLabel: PropTypes.string.isRequired,
  monthlyIncome: PropTypes.number.isRequired,
  monthlyIncomeLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

View.displayName = 'IncomeAndExpenseSummary.View'

export { View }
