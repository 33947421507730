import { View } from './View'
import { useMemo } from 'react'
import { oneOf } from 'prop-types'
import { useOtherDebts } from 'hooks/useOtherDebts'
import { useContent } from 'hooks/useContent'
import { propsSpec } from 'utils/propsSpec'

const contentToPropSpec = propsSpec({
  accountLabel: 'content.0.content.accountLabel',
  subtitle: 'subtitle',
  title: 'title',
  totalLabel: 'content.0.content.totalLabel',
  unitLabel: 'content.0.content.unitLabel'
})

const contentHelper = (content = {}, variation) => {
  if (variation === 'secured_debts') {
    return contentToPropSpec(content.securedDebtsPage)
  } else {
    return contentToPropSpec(content.otherDebtsPage)
  }
}

const Container = ({ componentVariation }) => {
  const { tradelines, total } = useOtherDebts()

  const { content } = useContent()
  const contentProps = useMemo(() => contentHelper(
    content,
    componentVariation
  ), [content, componentVariation])

  return <View total={total} tradelines={tradelines} {...contentProps} />
}

View.displayName = 'OtherDebtsContainer'

Container.propTypes = {
  componentVariation: oneOf(['other_debts', 'secured_debts'])
}

export { Container }
