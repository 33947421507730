import cx from 'classnames'
import { bool, number } from 'prop-types'
import { useEffect, useState } from 'react'
import styles from './index.module.scss'
import { qaId } from 'consts/qaId'
import { qaAttributes } from 'utils/qaAttributes'

const DEFAULT_DEGREES = -90 // Needle all the way to the left
const UNSET_PERCENTAGE = -101 // Needle below -90 or below all the way to the left
const MAX_PERCENTAGE = 100 // Needle all the way to the right
const MIN_PERCENTAGE = 0 // Needle all the way to the left this translates to degrees to -90

const View = ({ hideLabels, percentage }) => {
  const [isMounted, beMounted] = useState(false)
  const [degrees, setDegrees] = useState(DEFAULT_DEGREES)
  const actual = Math.min(Math.max(percentage, MIN_PERCENTAGE), MAX_PERCENTAGE)

  const isPercentageUnset = percentage === UNSET_PERCENTAGE

  useEffect(() => beMounted(true), [])

  useEffect(() => {
    if (!isMounted) {
      return
    }

    if (isPercentageUnset) {
      setDegrees(DEFAULT_DEGREES)

      return
    }

    // So we can animate on initial load
    const to = setTimeout(() => {
      const temp = Math.floor((actual / 100) * 180) - 90
      const clamped = Math.max(temp, -90)

      setDegrees(clamped)
    }, 300)

    return () => clearTimeout(to)
  }, [actual, isMounted, isPercentageUnset])

  const needleTransformOriginOffset = {
    transformOrigin: `${78 + (actual / 100) * 2}px 80px`
  }

  return (
    <div className={cx(styles.container, { [styles.hideLabels]: hideLabels })}>
      {!hideLabels && (
        <span
          className={cx(styles.label, styles.good)}
          {...qaAttributes({
            id: `${qaId.INCOME_EXPENSE_SUMMARY_CHART_LABEL}-poor`
          })}>Poor</span>
      )}
      <div className={styles.gauge}>
        <svg height="80" width="160">
          <linearGradient id="grad1" x1="0%" x2="100%" y1="0%" y2="0%">
            <stop className={styles.gradientStart} offset="0%" />
            <stop className={styles.gradientStop} offset="100%" />
          </linearGradient>
          <g>
            <path
              className={styles.gradient}
              d="m24.39698,81c0,-0.4 0,-0.7 0,-1.1c0,-30.6 24.8,-55.5 55.5,-55.5s55.5,24.8 55.5,55.5c0,0.4 0,0.7 0,1.1l24.4,0c0,-0.4 0,-0.7 0,-1.1c0,-44.1 -35.8,-79.9 -79.9,-79.9c-44.2,0 -80,35.8 -80,79.9c0,0.4 0,0.7 0,1.1l24.5,0z"
              fill="url(#grad1)"
            />
            <path
              className={cx(styles.needle, {
                [styles.unset]: isPercentageUnset
              })}
              d="m78.07575,24.93926l6.34236,55.06074l-11.0302,0l4.68783,-55.06074z"
              data-testid="needle"
              style={{
                /* stylelint-disable value-keyword-case */
                transform: `rotate(${degrees}deg)`,
                transition: isMounted && '1s all ease',
                ...needleTransformOriginOffset
              }}
            />
            <ellipse
              className={styles.center}
              cx="79"
              cy="79.81812"
              rx="8"
              ry="8"
            />
          </g>
        </svg>
      </div>
      {!hideLabels && (
        <span
          className={cx(styles.label, styles.bad)}
          {...qaAttributes({
            id: `${qaId.INCOME_EXPENSE_SUMMARY_CHART_LABEL}-good`
          })}>Good</span>
      )}
    </div>
  )
}

View.displayName = 'IndicatorChart'

View.defaultProps = {
  percentage: UNSET_PERCENTAGE
}

View.propTypes = {
  hideLabels: bool,
  percentage: number
}

export { View }
