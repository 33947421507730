import { Grid } from '@material-ui/core'
import { qaId } from 'consts/qaId'
import { string, number } from 'prop-types'
import { currencyFormat } from 'utils/currencyFormat'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const View = ({ balance, creditor, index }) => (
  <Grid container item xs={12}>
    <Grid
      {...qaAttributes({
        id: `${qaId.OTHER_DEBTS_CREDITOR_PREFIX}-${index}`
      })}
      className={styles.name}
      item
      xs={6}
    >
      {creditor}
    </Grid>
    <Grid
      {...qaAttributes({
        id: `${qaId.OTHER_DEBTS_BALANCE_PREFIX}-${index}`
      })}
      align="right"
      className={styles.amount}
      item
      xs={6}
    >
      {currencyFormat(balance)}
    </Grid>
  </Grid>
)

View.propTypes = {
  balance: number,
  creditor: string.isRequired,
  index: number.isRequired
}

View.displayName = 'OtherDebtsTradeline'

export { View }
