import { string, shape, number, arrayOf } from 'prop-types'
import { StepHeader } from 'components/StepHeader'
import { TradelineTable } from 'components/TradelineTable'
import { Tradeline } from './Tradeline'
import styles from './index.module.scss'

const View = ({
  accountLabel,
  subtitle,
  tradelines,
  title,
  total,
  totalLabel,
  unitLabel
}) => (
  <>
    <StepHeader
      subtitle={subtitle}
      subtitleClassName={styles.subtitle}
      title={title}
    />

    <TradelineTable
      accountLabel={accountLabel}
      amountAlign="right"
      className={styles.container}
      total={total}
      totalLabel={totalLabel}
      unitLabel={unitLabel}
    >
      {tradelines.map((tradeline, index) => (
        <Tradeline
          {...tradeline}
          key={`${tradeline.accountNumber}-${index}`}
          index={index}
        />
      ))}
    </TradelineTable>
  </>
)

View.propTypes = {
  accountLabel: string.isRequired,
  subtitle: string.isRequired,
  title: string.isRequired,
  total: number,
  totalLabel: string.isRequired,
  tradelines: arrayOf(
    shape({
      balance: number,
      creditor: string.isRequired
    })
  ).isRequired,
  unitLabel: string.isRequired
}

View.displayName = 'OtherDebts'

export { View }
