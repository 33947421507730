import cx from 'classnames'
import styles from './index.module.scss'
import { string, arrayOf, shape } from 'prop-types'
import { Review } from './Review'

const View = ({ className, reviews }) => (
  <ul className={cx(styles.container, className)}>
    {reviews.map((review, index) => (
      <Review {...review} key={review.author} index={index} />
    ))}
  </ul>
)

View.propTypes = {
  className: string,
  reviews: arrayOf(
    shape({
      author: string.isRequired,
      link: string,
      review: string.isRequired
    })
  ).isRequired
}

export { View }
