import { View } from './View'
import { string } from 'prop-types'
import { useMobileNavState } from 'hooks/useMobileNavState'
import { useDarkMode } from 'hooks/useDarkMode'
import { useContent } from 'hooks/useContent'
import { usePageContent } from 'hooks/usePageContent'

const Container = (props) => {
  const darkMode = useDarkMode()
  const { isMobileNavOpen, beMobileNavOpen } = useMobileNavState(false)
  const backgroundImage = darkMode?.backgroundImage
    ? `url(${darkMode.backgroundImage})`
    : undefined
  const { content } = useContent()
  const { hideFooterOnPage } = usePageContent()
  const { hideHeaderForDeck, hideFooterForDeck, hideMenu } = content
  return (
    <View
      backgroundImage={backgroundImage}
      beMobileNavOpen={beMobileNavOpen}
      hideFooter={hideFooterForDeck || hideFooterOnPage}
      hideHeader={darkMode?.hideHeader || hideHeaderForDeck}
      hideMenu={hideMenu}
      isMobileNavOpen={isMobileNavOpen}
      {...props}
    />
  )
}

Container.displayName = 'Layout.container'

Container.propTypes = {
  path: string
}

export { Container }
