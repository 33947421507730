import {
  createAnalytics,
  plugins,
  EVENT_ORIGIN
} from '@achieve/cx-event-horizon/browser'
import { useEnv } from 'hooks/useEnv'

let _instance

const getAnalytics = ({ appName, isDebug }) => {
  if (!_instance) {
    _instance = createAnalytics({
      application: appName,
      debug: isDebug,
      plugins: [
        plugins.analyticsFreedom({
          application: appName,
          isDebug: isDebug,
          trackingUrl: '/api/event/track'
        }),
        plugins.analyticsTealium({
          application: appName
        })
      ]
    })
  }

  return _instance
}

const useAnalytics = () => {
  const { env } = useEnv()
  const analytics = getAnalytics({
    appName: env?.APPLICATION_NAME,
    isDebug: env?.EVENT_TRACKER_DEBUG
  })

  return {
    analytics,
    page: (pageName, payload) =>
      analytics.page({
        event_origin: EVENT_ORIGIN.CLIENT,
        page_name: pageName,
        ...payload
      }),
    track: (eventName, payload) => {
      analytics.track(eventName, {
        event_origin: EVENT_ORIGIN.CLIENT,
        ...payload
      })
    }
  }
}

export { useAnalytics }
