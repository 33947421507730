import { Icon } from 'components/Icon'
import { qaId } from 'consts/qaId'
import { number, string } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const View = ({ index, src, text, title }) => (
  <li className={styles.container}>
    <Icon alt={title} className={styles.icon} src={src} />
    <h3
      {...qaAttributes({
        id: `${qaId.HOW_IT_WORKS_ITEMS_PREFIX}-title-${index}`
      })}
      className={styles.title}
    >
      {title}
    </h3>
    <div className={styles.verticalRule} />
    <p
      {...qaAttributes({
        id: `${qaId.HOW_IT_WORKS_ITEMS_PREFIX}-copy-${index}`
      })}
      className={styles.text}
    >
      {text}
    </p>
  </li>
)

View.propTypes = {
  index: number.isRequired,
  src: string.isRequired,
  text: string.isRequired,
  title: string.isRequired
}

export { View }
