import { View as HowItWorks } from './View'
import { propsSpec } from 'utils/propsSpec'
import { useContent } from 'hooks/useContent'

const contentToPropSpec = propsSpec({
  items: 'howOurProgramWorksPage.content.0.content.items',
  subtitle: 'howOurProgramWorksPage.content.0.content.subtitle',
  title: 'howOurProgramWorksPage.content.0.content.title'
})

const Container = () => {
  const { content } = useContent()
  const contentProps = contentToPropSpec(content)

  return <HowItWorks {...contentProps} />
}

Container.displayName = 'HowItWorks.Container'

export { Container }
