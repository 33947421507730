import { useUpscope } from 'components/Upscope/hooks/useUpscope'
import { useIsCoBrowse } from 'hooks/useIsCoBrowse'
import { useSteps } from 'hooks/useSteps'
import { useEffect } from 'react'

/**
 * Update the agent script on navigation
 */
const AgentScript = () => {
  // useEffect is not working and any why useLocation re-renders the component
  const { current } = useSteps()
  const upscope = useUpscope()
  const [isCoBrowse] = useIsCoBrowse()

  useEffect(() => {
    if (current?.agentScript && upscope) {
      upscope('updateConnection', {
        agentPrompt: current?.agentScript?.trim()
      })
    }
  }, [current, upscope, isCoBrowse])

  return null
}

export { AgentScript }
