const standard = {
  bodyTitle: 'Total Eligible Debt',
  footerLabel: 'Estimated Benefit',
  labels: [
    'Estimated Program Duration',
    'Monthly Program Payment',
    'Estimated Total Cost'
  ],
  monthlyPaymentFooter: 'per month*',
  programPaymentLabel: 'Your Lowest Monthly Payment',
  subtitle: 'Here’s your program summary',
  title: 'Your Solution'
}

export { standard }
