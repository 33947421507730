import { node, oneOf } from 'prop-types'
import { featureFlags } from 'shared/constants/featureFlags'
import { useFeatureFlags } from 'hooks/useFeatureFlags'

const View = ({ children, feature }) => {
  const { isLoading, featureFlags: flags } = useFeatureFlags()
  const isValid = !isLoading && flags?.[feature]

  if (!isValid) {
    return null
  }

  return children
}

View.propTypes = {
  children: node.isRequired,
  feature: oneOf(Object.values(featureFlags).sort()).isRequired
}

View.displayName = 'Feature.View'

export { View }
