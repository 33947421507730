import { useContent } from 'hooks/useContent'
import { useSetDarkMode } from 'hooks/useDarkMode'
import { View } from './View'

const Container = () => {
  const { content } = useContent()
  const { backgroundImage, figures, heroLogo, title } = content.aboutPage
  useSetDarkMode({ backgroundImage: backgroundImage.file.url })

  return (
    <View
      backgroundUrl={backgroundImage.file.url}
      figures={figures}
      logo={heroLogo}
      title={title}
    />
  )
}

export { Container }
