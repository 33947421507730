import PropTypes from 'prop-types'
import { View } from './View'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useDarkMode } from 'hooks/useDarkMode'

const Container = (props) => {
  const darkMode = useDarkMode()
  const title =
    props.title?.nodeType === 'document'
      ? documentToReactComponents(props.title)
      : props.title

  const subtitle =
    props.subtitle?.nodeType === 'document'
      ? documentToReactComponents(props.subtitle)
      : props.subtitle

  return <View {...props} isDark={darkMode} subtitle={subtitle} title={title} />
}

Container.displayName = 'StepHeader.Container'

Container.propTypes = {
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.shape({
      nodeType: PropTypes.oneOf(['document'])
    })
  ]),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.shape({
      nodeType: PropTypes.oneOf(['document'])
    })
  ])
}

export { Container }
