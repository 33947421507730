import { useCustomerCountSummary } from 'hooks/useCustomerCountSummary'
import { roundToTheNearestBillion } from '../utils'
import { View } from './View'

const Container = () => {
  const summary = useCustomerCountSummary()
  const { debtResolved } = summary.national
  const debtResolvedInBillions = roundToTheNearestBillion(debtResolved)

  return <View debtResolvedInBillions={debtResolvedInBillions} />
}

export { Container }
