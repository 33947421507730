import PropTypes from 'prop-types'
import cls from 'classnames'

const View = (props) => (
  <>
    <input {...props} className={cls('editable', props.className)} />
    <input
      className={cls(props.className, 'co-browser-view-only')}
      inputMode="none"
      placeholder={props.placeholder}
      readOnly
      type={props.type}
      value={props.value}
    />
  </>
)

View.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any
}

View.displayName = 'Input.View'

export { View }
