import { useEnv } from 'hooks/useEnv'
import { useEffect } from 'react'
import { either, isEmpty, isNil } from 'ramda'
import { logger } from 'shared/node/logger'

import { addTealiumScript } from './tealiumScript'

const initializeTealium = (tealiumId) => {
  try {
    // Configure Tealium
    window.utag_cfg_ovrd = window.utag_cfg_ovrd || {}
    window.utag_cfg_ovrd.noview = true
    addTealiumScript(tealiumId)
  } catch (error) {
    logger.error(error, 'Tealium failed to initalize.')
  }
}

const Container = () => {
  const { env, isLoading } = useEnv()
  const isNullOrEmpty = either(isNil, isEmpty)

  useEffect(() => {
    if (isNullOrEmpty(env) || isLoading) {
      return
    }

    const tealiumId = env?.TEALIUM_UTAG_URL
    initializeTealium(tealiumId)
  }, [env, isLoading, isNullOrEmpty])
  return null
}

Container.displayName = 'Tealium.Container'

export { Container }
