import PropTypes from 'prop-types'
import { StepHeader } from 'components/StepHeader'
import styles from './index.module.scss'

const View = ({ code, message }) => (
  <div className={styles.container}>
    <StepHeader subtitle={message} title={code} />
  </div>
)

View.displayName = 'ErrorPage.View'

View.propTypes = {
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  message: PropTypes.string
}

View.defaultProps = {
  code: 699,
  message: 'Unknown Error'
}

export { View }
