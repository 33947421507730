import cx from 'classnames'
import { qaId } from 'consts/qaId'
import { bool, number, string } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const View = ({ className, label, percentage, showNone, subtitle, title }) => (
  <div className={cx(styles.container, className)}>
    <div
      {...qaAttributes({
        id: qaId.ELIGIBLE_DEBT_SUMMARY_RING_TITLE
      })}
      className={styles.label}
    >
      {label}
    </div>
    <svg className={styles.ring} viewBox="-1 -1 38 38">
      <path
        className={styles.background}
        d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      {showNone
        ? (
        <text
          {...qaAttributes({
            id: qaId.ELIGIBLE_DEBT_SUMMARY_RING_SUBTITLE
          })}
          className={styles.none}
          data-testid="none"
          x="18"
          y="20"
        >
          None
        </text>
          )
        : (
        <>
          {percentage && (
            <path
              className={styles.foreground}
              d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
              data-testid="overlay"
              strokeDasharray={`${percentage}, 100`}
            />
          )}
          <text
            {...qaAttributes({
              id: qaId.ELIGIBLE_DEBT_SUMMARY_RING_PERCENTAGE
            })}
            className={styles.percentage}
            data-testid="percentage"
            x="18"
            y="17.5"
          >
            {title || `${percentage}%`}
          </text>
          <text
            {...qaAttributes({
              id: qaId.ELIGIBLE_DEBT_SUMMARY_RING_SUBTITLE
            })}
            className={styles.subtitle}
            x="18"
            y="23"
          >
            {subtitle}
          </text>
        </>
          )}
    </svg>
  </div>
)

View.propTypes = {
  className: string,
  label: string.isRequired,
  percentage: number.isRequired,
  showNone: bool,
  subtitle: string,
  title: string
}

View.displayName = 'RingChart'

export { View }
