import { useMemo } from 'react'
import { useEligibleDebtSummary } from 'hooks/useEligibleDebtSummary'
import { View } from './View'
import { useContent } from 'hooks/useContent'
import { string } from 'prop-types'
import { propsSpec } from 'utils/propsSpec'

const getNestedContent = (content) => {
  const contentPath = 'debtSummaryPage.content.0.content.debtSummaryContent'
  const dataLabelsPath = `${contentPath}.dataLabels`
  return propsSpec({
    accountsCollectionLabel: `${dataLabelsPath}.accountsCollectionLabel`,
    eligibleDebtsLabel: `${dataLabelsPath}.eligibleDebtsLabel`,
    latePaymentsLabel: `${dataLabelsPath}.latePaymentsLabel`,
    legalDisclosure: `${contentPath}.legalDisclosure`,
    otherDebtsLabel: `${dataLabelsPath}.otherDebtsLabel`,
    overallUsageLabel: `${dataLabelsPath}.overallUsageLabel`,
    subtitle: 'debtSummaryPage.subtitle',
    title: 'debtSummaryPage.title'
  })(content)
}

const Container = ({ title }) => {
  const summary = useEligibleDebtSummary()
  const { content } = useContent()

  const contentProps = useMemo(() => getNestedContent(content), [content])

  return <View
    {...contentProps}
    title={contentProps.title || title}
    {...summary} />
}

Container.displayName = 'EligibleDebtSummary.Container'

Container.propTypes = {
  title: string
}

export { Container }
