import { node } from 'prop-types'
import { Navigation } from './Navigation'
import styles from './index.module.scss'

const View = ({ children }) => (
  <main className={styles.container}>
    {children}
    <Navigation />
  </main>
)

View.propTypes = {
  children: node.isRequired
}

View.displayName = 'Main.View'

export { View }
