import { useLead } from 'hooks/useLead'
import { useContent } from 'hooks/useContent'
import { View } from './View'
import { useSetDarkMode } from 'hooks/useDarkMode'

const Container = () => {
  const {
    firstName,
    lastName,
    coApplicantFirstName,
    coApplicantLastName
  } = useLead()

  const { content } = useContent()
  const {
    backgroundImage,
    consultationTitle,
    heroLogo,
    startParagraph,
    coAppSeparator
  } = content.startPage

  useSetDarkMode({
    backgroundImage: backgroundImage.file.url,
    hideHeader: true
  })

  return (
    <View
      coAppSeparator={coAppSeparator}
      coApplicantFirstName={coApplicantFirstName}
      coApplicantLastName={coApplicantLastName}
      firstName={firstName}
      lastName={lastName}
      logo={heroLogo}
      paragraph={startParagraph}
      title={consultationTitle}
    />
  )
}

export { Container }
