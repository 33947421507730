import { usePresence } from 'framer-motion'
import { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/**
 * Context to provide the current dark mode state.
 *
 * Motivation: Some pages need to change the color scheme
 * of the navigation bar but we cannot simply pass the color
 * scheme prop down because the pages are children of the Layout Component
 * which contains the navigation component.
 *
 * We could simply move the state up to the layout component but the layout component
 * does not know about when the specific page that needs the different color scheme
 * is mounted and unmounted. To facilitate this we use context.
 *
 * On the pages that need to change the color scheme we call useSetDarkMode() hook.
 * This will set the dark mode state to true after the component mounts (and is present) and
 * unset the state once it unmounts. In this way only the pages that need to change the color
 * scheme to true will have the dark mode state set the default value is false.
 */
const DarkModeContext = createContext(null)

/**
 * Helper function to get the current dark mode state.
 * This state relates to whether or not to use light colored
 * fonts and colors on a dark background.
 * @returns {boolean}
 */
const useDarkMode = () => {
  const { darkMode } = useContext(DarkModeContext)
  return darkMode
}

const useSetDarkMode = (
  { backgroundImage, hideHeader } = { hideHeader: false }
) => {
  const { setDarkMode } = useContext(DarkModeContext)
  const [isPresent] = usePresence()
  useEffect(() => {
    if (isPresent) {
      setDarkMode({ backgroundImage, hideHeader })
    }
    return () => {
      setDarkMode(null)
    }
  }, [backgroundImage, hideHeader, isPresent, setDarkMode])
}

const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false)
  const value = {
    darkMode,
    setDarkMode
  }
  return (
    <DarkModeContext.Provider value={value}>
      {children}
    </DarkModeContext.Provider>
  )
}

DarkModeProvider.propTypes = {
  children: PropTypes.node
}

DarkModeProvider.displayName = 'DarkModeProvider'

export { DarkModeProvider, useDarkMode, DarkModeContext, useSetDarkMode }
