import React from 'react'
import { node, string } from 'prop-types'
import styles from './index.module.scss'
import classNames from 'classnames'

const View = ({ children, className }) => (
  <div className={classNames(styles.container, className)}>{children}</div>
)

View.propTypes = {
  children: node,
  className: string
}

View.displayName = 'FullBleed'

export { View }
