import { useLead } from '../useLead'

const example = {
  debtReduction: 4462,
  duration: {
    max: 30,
    min: 18
  },
  eligibleDebt: 20516.0,
  monthlyPayments: {
    max: 700,
    min: 418
  },
  totalCost: 16053
}

const useYourSolution = () => {
  const {
    totalEstimatedDebt: eligibleDebt,
    estimatedProgramMinimumLength: durationMin,
    estimatedProgramMaximumLength: durationMax,
    estimatedDebtMonthlyPaymentMinimum: monthlyPaymentsMin,
    estimatedDebtMonthlyPaymentMaximum: monthlyPaymentsMax,
    estimatedCostToClient: totalCost,
    estimatedProgramSavings: debtReduction
  } = useLead()

  return {
    debtReduction,
    duration: {
      max: durationMax,
      min: durationMin
    },
    eligibleDebt: Number.parseFloat(eligibleDebt),
    monthlyPayments: {
      max: monthlyPaymentsMax,
      min: monthlyPaymentsMin
    },
    totalCost
  }
}

export { example, useYourSolution }
