const useFormatCurrency = ({
  forceDecimals,
  showNonZeroDecimals
} = {}) => amount => {
  let actualAmount = amount

  if (showNonZeroDecimals === false) {
    actualAmount = Math.round(amount)
  }

  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    minimumFractionDigits:
      forceDecimals || (amount !== 0 && showNonZeroDecimals) ? undefined : 0,
    style: 'currency'
  }).format(actualAmount)
}

export { useFormatCurrency }
