import React from 'react'
import { clickEvent } from 'consts/clickEvent'
import { qaId } from 'consts/qaId'
import { qaAttributes } from 'utils/qaAttributes'
import PropTypes from 'prop-types'
import { ReactComponent as Afcc } from './Afcc.svg'
import styles from './index.module.scss'

const View = ({ legalCopy, links, hideAFCCLogo }) => (
    <footer className={styles.wrapper}>
    <div className={styles.footer}>
      {!hideAFCCLogo && <Afcc className={styles.afccLogo} />}
      <p className={styles.footerlinks}>
        <a
          {...qaAttributes({ id: qaId.FOOTER_PRIVACY_POLICY })}
          href={links['Privacy Policy'].href}
          id={clickEvent.APP_FOOTER_LINK_PRIVACY_POLICY}
          rel="noopener noreferrer"
          target="_blank"
        >
          {links['Privacy Policy'].label}
        </a>
        {links['Terms of Use'] && (
          <>
            <span className={styles.dot}></span>
            <a
              {...qaAttributes({ id: qaId.FOOTER_TERMS_OF_USE })}
              href={links['Terms of Use'].href}
              id={clickEvent.APP_FOOTER_LINK_TERMS_OF_USE}
              rel="noopener noreferrer"
              target="_blank"
            >
              {links['Terms of Use'].label}
            </a>
          </>
        )}
        {links['Contact Us'] && (
          <>
            <span className={styles.dot}></span>
            <a
              {...qaAttributes({ id: qaId.FOOTER_CONTACT_US })}
              href={links['Contact Us'].href}
              id={clickEvent.APP_FOOTER_LINK_CONTACT_US}
              rel="noopener noreferrer"
              target="_blank"
            >
              {links['Contact Us'].label}
            </a>
          </>
        )}
      </p>
      <span className={styles.line}></span>
      {legalCopy &&
        React.cloneElement(legalCopy, {
          ...qaAttributes({ id: qaId.FOOTER_LEGAL_COPY }),
          className: styles.disclaimer
        })}
      <p
        {...qaAttributes({ id: qaId.FOOTER_ALL_RIGHTS_RESERVED })}
        className={styles.rightsReserved}
      >
        &copy; {new Date().getFullYear()}. All rights reserved. Freedom Debt
        Relief, LLC
      </p>
    </div>
  </footer>
)

View.displayName = 'Footer'

const linkType = PropTypes.shape({
  ':type': PropTypes.string,
  href: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string
})

View.propTypes = {
  hideAFCCLogo: PropTypes.bool,
  legalCopy: PropTypes.element,
  links: {
    'Contact Us': linkType,
    'Privacy Policy': linkType,
    'Terms of Use': linkType
  }
}
View.defaultProps = {
  hideAFCCLogo: false
}

export { View }
