import { StepHeader } from 'components/StepHeader'
import { Budget, Clock, Dollar } from 'components/SvgIcon'
import { qaId } from 'consts/qaId'
import React from 'react'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const items = [
  {
    id: 1,
    img: <Dollar alt="Dollar Savings" />,
    text: 'Resolve your debt for less than what you owe'
  },
  {
    id: 2,
    img: <Budget alt="Monthly Savings" />,
    text: 'At a monthly amount that fits your budget'
  },
  {
    id: 3,
    img: <Clock alt="Time Savings" />,
    text: 'In less time than making minimum monthly payments'
  }
]

const View = () => (
  <div className={styles.container}>
    <StepHeader
      subtitle="With Freedom Debt Relief you can:"
      title="An Affordable Solution To Reduce Your Debt"
    />

    <div className={styles.content}>
      <ul className={styles.itemContainer}>
        {items.map((item, index) => (
          <li key={item.id} className={styles.item}>
            <div className={styles.icon}>{item.img}</div>
            <div
              {...qaAttributes({
                id: `${qaId.WELCOME_ITEMS_PREFIX}-${index}`
              })}
              className={styles.text}
            >
              {item.text}
            </div>
          </li>
        ))}
      </ul>

      <div
        {...qaAttributes({
          id: qaId.WELCOME_FOOTER
        })}
        className={styles.conclusion}
      >
        We resolve 50,000 debt accounts for our clients on average per month!
      </div>
    </div>
  </div>
)
View.displayName = 'Welcome'
export { View }
