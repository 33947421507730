/* eslint-disable */
import { StepHeader } from 'components/StepHeader'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { Text } from './Text'
import { toText } from 'utils/content/toText'
import { TrustPilotReview } from './TrustPilotReview'
import { LegalFooter } from '../../Disclosures/LegalFooter'
import { ARP } from 'components/Layout/constants'

const View = ({ content, title, subtitle, componentVariation }) => {
  const pageName = 'trustPage';
  const stats = <Text {...content[0]} />
  const isARP = ARP === componentVariation;
  const ratingText = content[2]
  const trustPilotReview = (
    <TrustPilotReview ratingText={toText(ratingText)} {...content[1]} />
  );
     
    return(
      <>
      <StepHeader subtitle={subtitle} title={title} />
      <div className={styles.container}>
       {stats}
       { !isARP && trustPilotReview}
      </div>
        <LegalFooter pageName={pageName}/> 
    </>
    )
}

View.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  componentVariation: PropTypes.string
}
View.defaultProps = {
  componentVariation: undefined
}

View.displayName = 'TrustPage'

export { View }
