import { bool, shape } from 'prop-types'
import { Route } from 'react-router-dom'
import { PageLoadingSpinner } from 'components/PageLoadingSpinner'
import { ErrorPage } from 'components/ErrorPage'

const View = ({ isLoading, error, ...props }) =>
  isLoading
    ? (
    <PageLoadingSpinner />
      )
    : error
      ? (
    <ErrorPage code={error.code} message={error.message} />
        )
      : (
    <Route {...props} />
        )

View.displayName = 'AuthorizedRoute.View'

View.propTypes = {
  error: shape(ErrorPage.propTypes),
  isAuthenticated: bool,
  isLoading: bool
}

export { View }
