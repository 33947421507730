import { StepHeader } from 'components/StepHeader'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { Bullet } from 'components/SvgIcon'
import Box from '@material-ui/core/Box'
import { qaId } from 'consts/qaId'
import { qaAttributes } from 'utils/qaAttributes'

const View = ({ items, title }) => (
  <>
    <StepHeader
      title={title}
    />
    <div className={styles.container}>
      {items.map(item => (
        <Box
          {...qaAttributes({ id: qaId.CALL_AGENDA_ITEM })}
          key={item}
          alignItems="center"
          className={styles.item}
          display="flex">
          <Bullet />
          {item}
          </Box>
      ))}
    </div>
  </>
)

View.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired
}

View.displayName = 'CallAgenda'

export { View }
