import React from 'react'
import { View } from './View'
import { useContent } from 'hooks/useContent'

export const Container = () => {
  const { content: { callAgendaPage } } = useContent()
  const props = {
    items: callAgendaPage.items,
    title: callAgendaPage.title
  }
  return <View {...props}/>
}
