import { View } from './View'
import { qaId } from 'consts/qaId'
import { bool, func, node, oneOf, shape, string } from 'prop-types'
import { useEffect, useState } from 'react'

const Container = ({
  isExpanded, onToggle, ...props
}) => {
  const [isOpen, beOpen] = useState(isExpanded)
  useEffect(() => beOpen(isExpanded), [isExpanded])

  const onClick = () => {
    const willBeOpen = !isOpen

    onToggle(willBeOpen)
    beOpen(willBeOpen)
  }

  return (
    <View
      isExpanded={isExpanded}
      isOpen={isOpen}
      onClick={onClick}
      {...props}
    />
  )
}

Container.propTypes = {
  ariaLabel: string.isRequired,
  children: node.isRequired,
  id: string.isRequired,
  isExpanded: bool,
  onToggle: func,
  qaId: oneOf(Object.values(qaId)),
  toggleTexts: shape({
    collapsed: string.isRequired,
    expanded: string.isRequired
  })
}
export { Container }
