import cx from 'classnames'
import { bool, number } from 'prop-types'

import styles from './index.module.scss'
import { BorderLinearProgress } from './BorderLinearProgress'

const View = ({ utilization, progress, isOverutilized }) => (
  <div className={styles.root}>
    <BorderLinearProgress
      isOverutilized={isOverutilized}
      value={progress}
      variant="determinate"
    />
    <div className={cx({ [styles.overutilized]: isOverutilized })}>
      {utilization}%
    </div>
  </div>
)

View.propTypes = {
  isOverutilized: bool,
  progress: number,
  utilization: number
}

View.displayName = 'BorderLinearProgress'

export { View }
