import { View } from './View'
import { useHeader } from 'hooks/useHeader'
import { useMobileNavState } from 'hooks/useMobileNavState'
import { useDarkMode } from 'hooks/useDarkMode'

const Container = (props) => {
  const darkMode = useDarkMode()
  const headerContent = useHeader()
  const { isMobileNavOpen, beMobileNavOpen } = useMobileNavState()
  return (
    <View
      {...props}
      beMobileNavOpen={beMobileNavOpen}
      isMobileNavOpen={isMobileNavOpen}
      {...headerContent}
      isDark={darkMode}
    />
  )
}

Container.displayName = 'Header.Container'

export { Container }
