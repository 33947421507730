import { string, oneOfType, shape } from 'prop-types'
import { Link } from 'react-router-dom'
import { usePreserveToQuery } from 'hooks/usePreserveToQuery'

const View = ({ to, ...props }) => (
  <Link {...props} to={usePreserveToQuery(to)} />
)

View.displayName = 'LinkWithQuery'

View.propTypes = {
  to: oneOfType([
    string,
    shape({
      pathname: string,
      state: shape({
        prevPath: string
      })
    })
  ])
}

export { View }
