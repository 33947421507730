import cx from 'classnames'
import { string } from 'prop-types'
import styles from './index.module.scss'

const View = ({ className, ...props }) => (
  <div {...props} className={cx(styles.container, className)} />
)

View.propTypes = {
  className: string
}
View.displayName = 'LegalText'
export { View }
