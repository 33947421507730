import { List, ListItem } from './List'
import { StepHeader } from 'components/StepHeader'
import styles from './index.module.scss'
import PropTypes from 'prop-types'
import { standard } from './constants'

const View = ({ title, subtitle, items }) => (
  <div className={styles.container}>
    <StepHeader subtitle={subtitle} title={title} />
    <List>
      {items.map((item, index) => (
        <ListItem index={index} {...item} key={item.title} />
      ))}
    </List>
  </div>
)

View.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  ),
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
}

View.defaultProps = {
  items: standard.items,
  subtitle: standard.subtitle,
  title: standard.title
}

export { View }
