import React from 'react'
import { Section } from '../Section'
import { Row } from '../Row'
import { StepHeader } from 'components/StepHeader'
import { string, arrayOf, object } from 'prop-types'
import styles from './index.module.scss'
import { defaultTitleSubtitle } from './constants'
import { LegalFooter } from '../../../Disclosures/LegalFooter'

const View = ({ title, subtitle, comparisons, comparisonsDisplayData }) => {
  const pageName = 'consumerFinancialOutcomes'
  return (
  <div className={styles.container}>
    <StepHeader subtitle={subtitle} title={title} />
    <div className={styles.content}>
      {comparisons.map(
        ({
          name,
          fieldName: title,
          content: {
            footnote: { note: footnote, title: footnoteNumber },
            rows: rowData
          }
        }) => (
          <Section
            footnote={footnote}
            footnoteNumber={footnoteNumber}
            title={title}
          >
            {Object.keys(rowData).map((rowFor) => {
              const {
                hasGap,
                id,
                isHighlighted,
                label,
                unit
              } = rowData[rowFor]
              const { value, percentage } = comparisonsDisplayData[name][rowFor]

              return (
                <Row
                  amount={value}
                  hasGap={hasGap}
                  id={id}
                  isHighlighted={isHighlighted}
                  label={label}
                  percentage={percentage}
                  unit={unit}
                />
              )
            })}
          </Section>
        )
      )}
    </div>
    <LegalFooter pageName={pageName}/>
  </div>
  )
}

View.propTypes = {
  comparisons: arrayOf(object),
  comparisonsDisplayData: object,
  subtitle: string.isRequired,
  title: string.isRequired
}

View.defaultProps = {
  subtitle: defaultTitleSubtitle.subtitle,
  title: defaultTitleSubtitle.title
}

View.displayName = 'StepContentView'

export { View }
