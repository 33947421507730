import { country } from 'consts/country'
import { usState } from 'consts/usState'
import CA from './images/CA.svg'
import NY from './images/NY.svg'
import TX from './images/TX.svg'
import USA from './images/USA.svg'

const region = Object.freeze({
  ...usState,
  ...country
})

const map = Object.freeze({
  CA,
  NY,
  TX,
  USA
})

export { map, region }
