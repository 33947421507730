import { usState } from 'consts/usState'

const NATIONAL_DEBT_RESOLVED = 15_000_000_000

const activeCountPerState = ({
  [usState.CA.code]: 41113,
  [usState.NY.code]: 24818,
  [usState.TX.code]: 32054
})

const useCustomerCountSummary = stateAbbrev => {
  const activeCount = activeCountPerState[stateAbbrev]

  return {
    national: {
      debtResolved: NATIONAL_DEBT_RESOLVED,
      monthly: 50_000,
      total: 800_000
    },
    state: activeCount && ({
      abbrev: stateAbbrev,
      active: activeCount
    })
  }
}

export { useCustomerCountSummary }

// QaAttributes
