import { DebtHeader } from 'components/DebtHeader'
import { StepHeader } from 'components/StepHeader'
import { qaId } from 'consts/qaId'
import { number, shape, string, object } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'
import { RingChart } from './RingChart'

const safePercentage = (dividend, divisor) =>
  divisor ? Math.round((dividend / divisor) * 100) : 0

const View = ({
  accountsCollectionLabel,
  accountsInCollection,
  credit,
  eligibleDebt,
  allTradelinesCount,
  eligibleDebtRatio,
  latePayments,
  latePaymentsLabel,
  otherDebtRatio,
  title,
  subtitle,
  eligibleDebtsLabel,
  otherDebtsLabel,
  overallUsageLabel,
  legalDisclosure
}) => (
  <div className={styles.container}>
    <StepHeader title={title} />
    <DebtHeader
      debt={eligibleDebt}
      id={qaId.TOTAL_ESTIMATED_DEBT}
      title={subtitle}
    />
    <section aria-label="Your debt summary" className={styles.metrics}>
      {Boolean(credit?.used) && (
        <RingChart
          className={styles.metric}
          label={overallUsageLabel.label}
          percentage={safePercentage(credit.used, credit.max)}
          showNone={!credit.used}
          subtitle={overallUsageLabel.chartLabel}
        />
      )}
      {latePayments.current !== 0
        ? (
        <RingChart
          className={styles.metric}
          label={latePaymentsLabel.label}
          percentage={safePercentage(latePayments.current, latePayments.total)}
          showNone={!latePayments.current}
          subtitle={latePaymentsLabel.chartLabel}
          title={`${latePayments.current}/${latePayments.total}`}
        />
          )
        : (
        <RingChart
          className={styles.metric}
          label={eligibleDebtsLabel.label}
          percentage={safePercentage(
            eligibleDebtRatio.count,
            allTradelinesCount
          )}
          showNone={!eligibleDebtRatio.count}
          subtitle={eligibleDebtsLabel.chartLabel}
          title={`${eligibleDebtRatio.count}/${allTradelinesCount}`}
        />
          )}

      {accountsInCollection.current !== 0
        ? (
        <RingChart
          className={styles.metric}
          label={accountsCollectionLabel.label}
          percentage={safePercentage(
            accountsInCollection.current,
            accountsInCollection.total
          )}
          showNone={!accountsInCollection.current}
          subtitle={accountsCollectionLabel.chartLabel}
          title={`${accountsInCollection.current}/${accountsInCollection.total}`}
        />
          )
        : (
        <RingChart
          className={styles.metric}
          label={otherDebtsLabel.label}
          percentage={safePercentage(otherDebtRatio.count, allTradelinesCount)}
          showNone={!otherDebtRatio.count}
          subtitle={otherDebtsLabel.chartLabel}
          title={`${otherDebtRatio.count}/${allTradelinesCount}`}
        />
          )}
    </section>
    <section
      {...qaAttributes({
        id: qaId.ELIGIBLE_DEBT_SUMMARY_FOOTER
      })}
      aria-label="Legal"
      className={styles.legal}
    >
      {legalDisclosure}
    </section>
  </div>
)

const accountsInCollection = shape({
  current: number.isRequired,
  total: number.isRequired
})

const credit = shape({
  max: number.isRequired,
  used: number
})

const latePayments = shape({
  current: number,
  total: number.isRequired
})
const ratios = shape({
  count: number.isRequired
})

const eligibleDebtRatio = ratios
const otherDebtRatio = ratios

View.propTypes = {
  accountsCollectionLabel: object.isRequired,
  accountsInCollection: accountsInCollection.isRequired,
  allTradelinesCount: number.isRequired,
  credit: credit.isRequired,
  eligibleDebt: number.isRequired,
  eligibleDebtRatio: eligibleDebtRatio.isRequired,
  eligibleDebtsLabel: object.isRequired,
  latePayments: latePayments.isRequired,
  latePaymentsLabel: object.isRequired,
  legalDisclosure: string.isRequired,
  otherDebtRatio: otherDebtRatio.isRequired,
  otherDebtsLabel: object.isRequired,
  overallUsageLabel: object.isRequired,
  subtitle: string.isRequired,
  title: string.isRequired
}

View.displayName = 'EligibleDebtSummary.View'

View.defaultProps = {
  accountsCollectionLabel: {
    chartLabel: 'accounts',
    label: 'Accounts in Collection*'
  },
  eligibleDebtsLabel: {
    chartLabel: 'accounts',
    label: 'Eligible Debts Ratio*'
  },
  latePaymentsLabel: {
    chartLabel: 'accounts',
    label: 'Late Payments*'
  },
  legalDisclosure: '* This information was gathered from data contained within your credit report, which you authorized FDR to access.',
  otherDebtsLabel: {
    chartLabel: 'accounts',
    label: 'Other Debts Ratio*'
  },
  overallUsageLabel: {
    chartLabel: 'average',
    label: 'Overall Usage*'
  },
  subtitle: 'Total Estimated Debt',
  title: 'Your Debt Summary'
}

export { View }
