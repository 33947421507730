import cx from 'classnames'
import { qaId } from 'consts/qaId'
import { qaAttributes } from 'utils/qaAttributes'
import { AlertLine } from './AlertLine'
import styles from './index.module.scss'

const View = () => (
  <AlertLine
    {...qaAttributes({
      id: qaId.YOUR_DEBTS_CREDITOR_WARNING
    })}
    className={cx(styles.alertIcon, styles.floatLeft)}
    data-testid="utilization-warning"
  />
)

View.displayName = 'UtilizationWarning'

export { View }
