import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { atoms } from 'store'

const useMobileNavState = () => {
  const [isMobileNavOpen, beMobileNavOpen] = useRecoilState(atoms.mobileNavOpen)
  const location = useLocation()
  // When the route changes, force close the mobile nav
  useEffect(() => {
    beMobileNavOpen(false)
  }, [location, beMobileNavOpen])

  return {
    beMobileNavOpen,
    isMobileNavOpen
  }
}

export { useMobileNavState }
