import { useRecoilState } from 'recoil'
import { atoms } from 'store'

export const useIncomeAndExpenses = () => {
  const [incomeAndExpense, setIncomeAndExpenses] = useRecoilState(
    atoms.incomeAndExpense
  )

  const setValues = ({ name, value = '' }) => {
    const monthlyExpenses = {
      ...incomeAndExpense,
      [name]: String(value).replace(/^0+/gm, '')
    }

    if (
      name !== 'monthlyDiscretionaryIncome' &&
      monthlyExpenses.monthlyExpenses >= 0
    ) {
      monthlyExpenses.monthlyDiscretionaryIncome =
        monthlyExpenses.monthlyIncome - monthlyExpenses.monthlyExpenses
    }

    if (
      name !== 'monthlyExpenses' &&
      monthlyExpenses.monthlyIncome >= 0 &&
      monthlyExpenses.monthlyIncome -
        monthlyExpenses.monthlyDiscretionaryIncome !==
        0
    ) {
      monthlyExpenses.monthlyExpenses =
        monthlyExpenses.monthlyIncome -
        monthlyExpenses.monthlyDiscretionaryIncome
    }

    setIncomeAndExpenses(monthlyExpenses)
  }

  return {
    incomeAndExpense,
    setIncomeAndExpenses,
    setValues
  }
}
