import { number, string } from 'prop-types'
import { Image } from 'components/Image'
import LogoTrustPilot from 'components/SvgIcon/LogoTrustPilot.svg'
import styles from './index.module.scss'
import { Stars } from './Stars'

const View = ({ tenths, wholeNumber, text = 'out of 5' }) => (
  <div className={styles.container}>
    <Image alt="Trustpilot logo" height={48} src={LogoTrustPilot} width={183} />
    <div className={styles.rating}>
      <Stars tenths={tenths} wholeNumber={wholeNumber} />
      <div>
        <strong data-testid="rating">
          {wholeNumber}
          {tenths > 0 ? `.${tenths}` : ''}
        </strong>{' '}
        {text}
      </div>
    </div>
  </div>
)

View.propTypes = {
  tenths: number,
  text: string.isRequired,
  wholeNumber: number.isRequired
}

export { View }
