import { clickEvent } from 'consts/clickEvent'
import { Legal as SharedLegal } from 'components/Disclosures/Legal'
import { LegalText } from 'components/Disclosures/LegalText'
import PropTypes from 'prop-types'
import styles from './index.module.scss'

const clickEvents = {
  collapsed: clickEvent.CFOS_FREEDOM_ADVANTAGE_LEARN_MORE,
  expanded: clickEvent.CFOS_FREEDOM_ADVANTAGE_READ_LESS
}

const View = ({ legalDisclaimer, toggleTexts, ariaLabels }) => (
  <SharedLegal
    ariaLabels={ariaLabels}
    clickEvents={clickEvents}
    toggleTexts={toggleTexts}
  >
    <LegalText className={styles.container}>{legalDisclaimer}</LegalText>
  </SharedLegal>
)

View.displayName = 'LegalFooter'

View.propTypes = {
  ariaLabels: PropTypes.shape({
    collapsed: PropTypes.string,
    expanded: PropTypes.string
  }),
  legalDisclaimer: PropTypes.element.isRequired,
  toggleTexts: PropTypes.shape({
    collapsed: PropTypes.string,
    expanded: PropTypes.string
  })
}

View.defaultProps = {
  ariaLabels: {
    collapsed: 'Expand learn more about our estimates',
    expanded: 'Collapse learn more about our estimates'
  },
  toggleTexts: {
    collapsed: 'Learn more about our estimates',
    expanded: 'Read less'
  }
}

export { View }
