import { useFooter } from 'hooks/useFooter'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { View } from './View'
import { reduce } from 'ramda'
import { turnBullPages } from 'consts/turnbullPages'
import { useSteps } from 'hooks/useSteps'
import { ARP } from '../constants'

const Container = (props) => {
  const footerContent = useFooter()
  const currentStep = useSteps().current
  const hideAFCCLogo = turnBullPages.includes(currentStep?.path) &&
      ARP === currentStep?.description
  let legalCopy

  if (footerContent?.legalCopy) {
    const legalCopyDoc = documentToReactComponents(footerContent.legalCopy)
    if (Array.isArray(legalCopyDoc) && legalCopyDoc.length) {
      legalCopy = legalCopyDoc[0]
    }
  }

  const links = reduce((links, link) => {
    links[link.name] = link
    return links
  }, {}, footerContent.legalLinks)

  return <View
  {...props}
  hideAFCCLogo={hideAFCCLogo}
  legalCopy={legalCopy}
  links={links} />
}

Container.displayName = 'Footer.Container'

export { Container }
