
export const getStaticContent = () => ({
  content: {
    incomeAndExpenseSummary
  }
})

export const incomeAndExpenseSummary = {
  cartLabels: {
    max: 'Good',
    min: 'Poor'
  },
  messages: {
    overBudget: 'over budget',
    underBudget: 'over budget'
  },
  monthlyDiscretionaryIncomeLabel: 'Est. Monthly Discretionary Income',
  monthlyExpensesLabel: 'Est. Monthly Expenses',
  monthlyIncomeLabel: 'Est. Monthly Income',
  title: 'Your budget summary'
}
