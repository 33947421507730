import PropTypes from 'prop-types'
import { View } from './View'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const Container = (props) => {
  const title =
      props.title?.nodeType === 'document'
        ? documentToReactComponents(props.title)
        : props.title
  return <View {...props} title={title} />
}

Container.displayName = 'DataSection.Container'

Container.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.func,
  id: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.shape({
      nodeType: PropTypes.oneOf(['document'])
    })
  ])
}

export { Container }
