import {
  fdr as fdrAmortizationFormat,
  minimumPayments as minimumPaymentsFormat
} from '../amortizationFormat'

const debtComparisonToDisplay = (formatCurrency, debtComparison) => {
  const { amortization, monthlyPayments, totalDebt } = debtComparison

  return {
    amortization: {
      fdr: fdrAmortizationFormat(amortization.fdr),
      minimumPayments: minimumPaymentsFormat(amortization.minimumPayments)
    },
    monthlyPayments: {
      fdr: `${formatCurrency(monthlyPayments.fdr)}*`,
      minimumPayments: formatCurrency(monthlyPayments.minimumPayments)
    },
    totalDebt: {
      fdr: `${formatCurrency(totalDebt.fdr)}*`,
      minimumPayments: formatCurrency(totalDebt.minimumPayments)
    }
  }
}

export { debtComparisonToDisplay }
