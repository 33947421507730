import { Prompt, useLocation } from 'react-router'

const Container = () => {
  const location = useLocation()

  return (
    <Prompt
      message={({ pathname }) => location.pathname !== pathname}
    />
  )
}

Container.displayName = 'NavigationControl'

export { Container }
