import { usState } from 'consts/usState'
import { number } from 'prop-types'
import { useCustomerCountSummary } from 'hooks/useCustomerCountSummary'
import { useLead } from 'hooks/useLead'
import { region } from './RegionMap'
import {
  numberFormatter,
  roundToTheNearestTenThousand,
  roundToTheNearestBillion
} from './utils'
import { View } from './View'

const Container = ({ version }) => {
  const { clientMailingState } = useLead()
  const stateAbbrev = (clientMailingState || '').toUpperCase()
  const summary = useCustomerCountSummary(stateAbbrev)
  const isV2 = version === 2
  const summaryData = summary.national
  const { debtResolved } = summaryData
  const debtResolvedInBillions = roundToTheNearestBillion(debtResolved)
  const clientCount = numberFormatter(summary?.state?.active)
  const clientNationalCount = numberFormatter(summaryData?.total)
  const debtAccountCount = numberFormatter(summaryData?.monthly)
  const isStateView = !!summary?.state

  const viewProps = isV2
    ? {
        footer: (
        <>
          <ul>
            <li><strong>${debtResolvedInBillions}+ billion</strong> debt settled</li>
            <li><strong>{clientNationalCount}</strong>+ clients served</li>
            <li><strong>{debtAccountCount}</strong>+ debt accounts settled monthly</li>
          </ul>
        </>
        ),
        regionCode: region.USA.code,
        subtitle: 'America’s largest debt relief company',
        title: 'Freedom Debt Relief'
      }
    : isStateView
      ? {
          footer: (
          <>
            We resolve an average of{' '}
            <strong>{debtAccountCount}</strong>{' '}
            accounts a month.
          </>
          ),
          regionCode: summary?.state?.abbrev,
          title: `${clientCount} Current Clients In ${usState[stateAbbrev]?.name}`
        }
      : {
          footer:
            'Our program is available across most of the United States - 64% coverage.',
          regionCode: region.USA.code,
          title: `Over ${numberFormatter(
            roundToTheNearestTenThousand(summary?.national?.total)
          )} Clients In The Country`
        }

  return <View {...viewProps} />
}

Container.propTypes = {
  version: number.isRequired
}

Container.defaultProps = {
  version: 1
}

export { Container }
