import { Tealium } from 'components/Tealium'
import { Init as UpscopeInit } from 'components/Upscope'
import { DarkModeProvider } from 'hooks/useDarkMode'
import { useEnv } from 'hooks/useEnv'
import { node } from 'prop-types'

const Container = ({ children }) => {
  const { isLoading: isEnvLoading } = useEnv()

  if (isEnvLoading) {
    return null
  }

  return (
    <>
      <Tealium />
      <UpscopeInit />
        <DarkModeProvider>
          {children}
        </DarkModeProvider>
    </>
  )
}

Container.propTypes = {
  children: node.isRequired
}

Container.displayName = 'Provider.Container'

export { Container }
