import { useHistory } from 'react-router-dom'
import { useKeyPress } from 'hooks/useKeyPress'
import { useSteps } from 'hooks/useSteps'

const Container = () => {
  const { push, location } = useHistory()
  const { previous, next, current } = useSteps()
  const handleStepChange = (direction) => {
    const pathname = direction === -1
      ? previous?.path
      : next?.path

    if (pathname) {
      push({
        ...location,
        pathname,
        state: {
          ...location.state,
          prevPath: current?.path
        }
      })
    }
  }

  useKeyPress('ArrowLeft', () => {
    handleStepChange(-1)
  })

  useKeyPress('ArrowRight', () => {
    handleStepChange(1)
  })

  return null
}

export { Container }
