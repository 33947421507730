import { forwardRef } from 'react'
import styles from './index.module.scss'
import { node, string } from 'prop-types'
import { ListItem, ListItemText } from '@material-ui/core'
import { LinkWithQuery } from 'components/LinkWithQuery'
import { qaAttributes } from 'utils/qaAttributes'
import { qaId } from 'consts/qaId'
import { useSteps } from 'hooks/useSteps'

const CustomLink = forwardRef(({ id, to, ...props }, ref) => {
  const { current } = useSteps()

  return (
    <LinkWithQuery
      {...qaAttributes({ id: `${qaId.NAV_MOBILE_PREFIX}-${to}` })}
      ref={ref}
      id={`${id}_mobile`}
      to={{
        pathname: to,
        state: {
          prevPath: current?.path
        }
      }}
      {...props}
    />
  )
})

CustomLink.propTypes = {
  id: string.isRequired,
  to: string.isRequired
}

const View = ({ title, ...props }) => (
  <ListItem {...props} component={CustomLink}>
    <ListItemText
      classes={{
        primary: styles.link
      }}
      primary={title}
    />
  </ListItem>
)

View.displayName = 'Item.view'

View.propTypes = {
  title: node.isRequired
}

export { View }
