import { FullBleed } from 'components/Layout/FullBleed'
import { StepHeader } from 'components/StepHeader'
import styles from './index.module.scss'

const View = () => (
  <FullBleed className={styles.container}>
    <div className={styles.hero}>
      <StepHeader
        subtitle="Learn how we can help you in your debt situation"
        title="The Freedom Advantage"
      />
    </div>
  </FullBleed>
)

View.displayName = 'FreedomAdvantage.View'

export { View }
