import cx from 'classnames'
import { Image } from 'components/Image'
import { LinkWithQuery } from 'components/LinkWithQuery'
import ArrowLeft from 'components/SvgIcon/ArrowLeft.svg'
import { clickEvent } from 'consts/clickEvent'
import { qaId } from 'consts/qaId'
import { bool, object, oneOfType, string, func } from 'prop-types'
import { useState } from 'react'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'

const Icon = (props) => (
  <div className={styles.iconContainer}>
    <Image {...props} height={12} src={ArrowLeft} width={8} />
  </div>
)

const View = ({ href, isPrevious, onClick }) => {
  const [isDisabled, beDisabled] = useState(false)

  const imageProps = isPrevious
    ? {
        alt: 'Navigate to previous page'
      }
    : {
        alt: 'Navigate to next page',
        className: styles.rotate180
      }

  const handleOnClick = () => {
    onClick()
    beDisabled(true)
    // Wait for the transition to finish before allowing another navigation event
    setTimeout(() => beDisabled(false), 500)
  }

  const clickEventId = isPrevious
    ? clickEvent.APP_NAVIGATION_ARROW_LEFT
    : clickEvent.APP_NAVIGATION_ARROW_RIGHT

  return isDisabled
    ? (
    <div
      className={cx(styles.container, styles.disabled, {
        [styles.previous]: isPrevious,
        [styles.next]: !isPrevious
      })}
      data-testid="disabled"
      id={clickEventId}
    >
      <Icon {...imageProps} />
    </div>
      )
    : (
    <LinkWithQuery
      {...qaAttributes({
        id: isPrevious ? qaId.NAV_ARROW_LEFT : qaId.NAV_ARROW_RIGHT
      })}
      className={cx(styles.container, {
        [styles.previous]: isPrevious,
        [styles.next]: !isPrevious
      }, 'navigation-arrow')}
      id={clickEventId}
      onClick={handleOnClick}
      to={href}
    >
      <Icon {...imageProps} />
    </LinkWithQuery>
      )
}

View.propTypes = {
  href: oneOfType([string, object]).isRequired,
  isPrevious: bool,
  onClick: func
}

View.defaultProps = {
  onClick: () => {
  // default empty function so that the arrow is clickable
  }
}
View.displayName = 'Arrow.View'

export { View }
