import { useLead } from 'hooks/useLead'
import { View } from '../ThankYou/View'
import { qaId } from 'consts/qaId'
import { currencyFormat } from 'utils/currencyFormat'
import { useContent } from 'hooks/useContent'
import { useSetDarkMode } from 'hooks/useDarkMode'

const Container = () => {
  const {
    estimatedDebtMonthlyPayment,
    estimatedProgramLength,
    estimatedProgramSavings,
    totalEstimatedDebt,
    estimatedCostToClient
  } = useLead()
  const { content } = useContent()
  const { backgroundImage } = content.startPage
  const backgroundUrl = useSetDarkMode({
    backgroundImage: backgroundImage?.file?.url
  })

  const dataSections = [
    {
      qaId: qaId.MONTHLY_PAYMENT,
      title: 'Est. Total Monthly Program Payment',
      value: currencyFormat(estimatedDebtMonthlyPayment)
    },
    {
      qaId: qaId.PROGRAM_LENGTH,
      title: 'Est. Program Length',
      value: `${estimatedProgramLength} months`
    },
    {
      qaId: qaId.TOTAL_ENROLLED_DEBT,
      title: 'Est. Total Eligible Debt',
      value: currencyFormat(totalEstimatedDebt)
    },
    {
      qaId: qaId.PROGRAM_COST,
      title: 'Est. Total Program Cost',
      value: currencyFormat(estimatedCostToClient)
    }
  ]
  if (estimatedProgramSavings) {
    dataSections.push({
      qaId: qaId.ESTIMATED_SAVINGS,
      title: 'Est. Program Benefit',
      value: currencyFormat(estimatedProgramSavings)
    })
  }
  const title = 'A personalized solution to put debt in your past'
  const subtitle =
    'Your program is designed to help you free up cash each month, decrease the overall amount you owe, and reduce the time it takes to pay down your debt.'

  return (
    <View
      backgroundUrl={backgroundUrl}
      dataSections={dataSections}
      subtitle={subtitle}
      title={title}
    />
  )
}

export { Container }
