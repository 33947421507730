import { selector } from 'recoil'
import { contentSelector } from './content'

const navigationSelector = selector({
  get: ({ get }) => {
    const content = get(contentSelector)
    if (content?.navigation) {
      return content.navigation
    }
    return { links: [] }
  },
  key: 'navigation'
})

export { navigationSelector }
