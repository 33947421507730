import axios from 'axios'

const xhr = axios.create({
  baseURL: '/api',
  params: {
    token: new URLSearchParams(window.location.search).get('token')
  }
})

// Middleware
xhr.interceptors.response.use(response => response.data)

export { xhr }
