import cx from 'classnames'
import { bool } from 'prop-types'
import { LinearProgress } from '@material-ui/core'
import styles from './index.module.scss'

const View = ({ isOverutilized, ...props }) => (
  <LinearProgress
    classes={{
      bar: cx(styles.bar, { [styles.overutilized]: isOverutilized }),
      root: styles.root
    }}
    {...props}
  />
)

View.propTypes = {
  isOverutilized: bool
}

View.displayName = 'Utilization'

export { View }
