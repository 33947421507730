import { useLead } from 'hooks/useLead'
import React from 'react'
import { View as YourSolution } from '../YourSolution/View'
import { useContent } from 'hooks/useContent'
import { propsSpec } from 'utils/propsSpec'
import { useYourSolution } from 'hooks/useYourSolution'
import { useItems } from '../YourSolution/utils'

const contentToPropSpec = propsSpec({
  and: 'yourDebtSolutionPage.content.0.content.and',
  bodyTitle: 'yourDebtSolutionPage.content.0.content.bodyTitle',
  footerLabel: 'yourDebtSolutionPage.content.0.content.footerLabel',
  headerTitle: 'yourDebtSolutionPage.content.0.content.headerTitle',
  labels: 'yourDebtSolutionPage.content.0.content.labels',
  language: 'yourDebtSolutionPage.content.0.content.language',
  programPaymentLabel:
    'yourDebtSolutionPage.content.0.content.programPaymentLabel',
  subtitle: 'yourDebtSolutionPage.content.0.content.subtitle'
})

const Container = () => {
  const { firstName, coApplicantFirstName } = useLead()
  const yourSolution = useYourSolution()
  const pageName = 'yourDebtSolutionPage'
  const { content } = useContent()
  const contentProps = contentToPropSpec(content)

  const nameString = coApplicantFirstName
    ? `${firstName} ${contentProps.and} ${coApplicantFirstName}`
    : firstName
  const title = `${contentProps.headerTitle} ${nameString}!`
  const items = useItems({
    labels: contentProps.labels,
    language: contentProps.language
  })
  return (
    <YourSolution
      bodyTitle={contentProps.bodyTitle}
      footerLabel={contentProps.footerLabel}
      hasSavings={yourSolution.debtReduction > 0}
      items={items}
      pageName={pageName}
      programPaymentLabel={contentProps.programPaymentLabel}
      subtitle={contentProps.subtitle}
      title={title}
      {...yourSolution}
    />
  )
}

export { Container }
