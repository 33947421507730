import { useHistory } from 'react-router-dom'
import React, { useState, useCallback, useEffect } from 'react'
import { element, node, oneOfType, arrayOf, string } from 'prop-types'
import { useSteps } from 'hooks/useSteps'
import { DEFAULT_POSITION, DEFAULT_MOVE_THRESHOLD } from './constants'

const Container = ({ children, swipeClassNameWhitelist }) => {
  const { push, location } = useHistory()
  const { previous, next, current } = useSteps()
  const handleStepChange = (direction) => {
    const pathname = direction === -1 ? previous?.path : next?.path

    if (pathname) {
      push({
        ...location,
        pathname,
        state: {
          ...location.state,
          prevPath: current?.path
        }
      })
    }
  }

  const [state, updateState] = useState({
    isDragging: false,
    origin: DEFAULT_POSITION,
    translation: DEFAULT_POSITION
  })

  const containsWhitelistedElement = useCallback(
    (target) =>
      swipeClassNameWhitelist.find((className) =>
        target?.classList?.contains(className)
      ),
    [swipeClassNameWhitelist]
  )

  const handleTouchStart = useCallback(({ touches }) => {
    const { clientX, clientY, target } = touches[0]
    if (containsWhitelistedElement(target)) {
      return
    }
    updateState((oldState) => ({
      ...oldState,
      isDragging: true,
      origin: { x: clientX, y: clientY }
    }))
  }, [containsWhitelistedElement])

  const handleTouchMove = useCallback(
    ({ touches }) => {
      const { clientX, clientY, target } = touches[0]
      if (containsWhitelistedElement(target)) {
        return
      }
      const translation = {
        x: clientX - state.origin.x,
        y: clientY - state.origin.y
      }
      updateState((oldState) => ({
        ...oldState,
        translation
      }))
      if (translation.x > DEFAULT_MOVE_THRESHOLD) {
        handleStepChange(-1)
      } else if (translation.x < -DEFAULT_MOVE_THRESHOLD) {
        handleStepChange(1)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.origin]
  )

  const handleTouchend = useCallback(() => {
    updateState((oldState) => ({
      ...oldState,
      isDragging: false
    }))
  }, [])

  useEffect(() => {
    if (state.isDragging) {
      window.addEventListener('touchmove', handleTouchMove)
      window.addEventListener('touchend', handleTouchend)
    } else {
      window.removeEventListener('touchmove', handleTouchMove)
      window.removeEventListener('touchend', handleTouchend)

      updateState((oldState) => ({ ...oldState, translation: { x: 0, y: 0 } }))
    }
  }, [state.isDragging, handleTouchMove, handleTouchend])

  return (
    <div data-testid="swipe-navigate" onTouchStart={handleTouchStart}>
      {children}
    </div>
  )
}

Container.propTypes = {
  children: oneOfType([element, node]),
  swipeClassNameWhitelist: arrayOf(string)
}

Container.defaultProps = {
  swipeClassNameWhitelist: ['navigation-arrow']
}

export { Container }
