import { useLocation, useHistory } from 'react-router-dom'
import { findIndex, propEq } from 'ramda'
import { useRoutes } from 'hooks/useRoutes'

const getStepPath = (pathname = '') => {
  const pathSegments = pathname.split('/') // ['', 'steps', 'intro']
  return pathSegments.length > 2 ? pathSegments[2] : pathname
}

const findStepPathIndex = (pathname, routes) => {
  const path = getStepPath(pathname)
  const hasSpecificPath = propEq('path', path)

  return findIndex(hasSpecificPath, routes)
}
const useSteps = () => {
  const { pathname, state } = useLocation()
  const routes = useRoutes()
  const history = useHistory()

  const currentIndex = findStepPathIndex(pathname, routes)
  const previousIndex = findStepPathIndex(state?.prevPath, routes)

  const setPage = (index) => {
    if (currentIndex === index) {
      return
    }
    const [, deck] = pathname?.split('/')
    const newPath = ['', deck, routes[index].path].join('/') // /steps/intro
    const search = history?.location?.search ?? ''
    history.push(newPath + search, {
      prevPath: pathname
    })
  }
  return {
    all: routes,
    current: routes[currentIndex],
    currentIndex,
    next: currentIndex === -1 ? undefined : routes[currentIndex + 1],
    previous: routes[currentIndex - 1],
    previousIndex,
    setPage
  }
}

export { useSteps, findStepPathIndex, getStepPath }
