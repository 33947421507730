import cx from 'classnames'
import { qaId } from 'consts/qaId'
import { motion } from 'framer-motion'
import { bool, func, node, oneOf, shape, string } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import { transitionVariants } from '../../../App/StepsLayout/constants'
import styles from './index.module.scss'

const transition = { duration: 0.3 }

const View = ({
  ariaLabel,
  children,
  id,
  toggleTexts,
  qaId,
  onClick,
  isOpen
}) => {
  const qaDataElms = qaId ? qaAttributes({ id: qaId }) : {}
  const actualToggleTexts = toggleTexts

  const toggleText = isOpen
    ? actualToggleTexts.expanded
    : actualToggleTexts.collapsed

  return (
    <div className={cx(styles.container, { [styles.open]: isOpen })}>
      <motion.section
        key="content"
        animate={isOpen ? 'expanded' : 'collapsed'}
        className={styles.childContent}
        initial="collapsed"
        transition={transition}
        variants={transitionVariants.CONTENT}
      >
        {children}
      </motion.section>
      <button
        {...qaDataElms}
        aria-label={ariaLabel}
        className={styles.button}
        data-testid="collapsible-card-cta"
        id={id}
        onClick={onClick}
        type="button"
      >
        {toggleText}
      </button>
    </div>
  )
}

View.defaultProps = {
  isExpanded: false,
  isOpen: false,
  onClick: () => null,
  onToggle: () => null,
  qaId: undefined,
  toggleTexts: {
    collapsed: 'Read more',
    expanded: 'Read less'
  }
}

View.propTypes = {
  ariaLabel: string.isRequired,
  children: node.isRequired,
  id: string.isRequired,
  isExpanded: bool,
  isOpen: bool,
  onClick: func,
  onToggle: func,
  qaId: oneOf(Object.values(qaId)),
  toggleTexts: shape({
    collapsed: string.isRequired,
    expanded: string.isRequired
  })
}
View.displayName = 'CollapsibleCard'
export { View }
