import { getReasonPhrase } from 'http-status-codes'
import { View } from './View'

const Container = ({ code, message }) => (
  <View code={code} message={message || getReasonPhrase(code)} />
)

View.displayName = 'ErrorPage.Container'

Container.propTypes = View.propTypes

export { Container }
