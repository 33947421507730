import { atom } from 'recoil'

const incomeAndExpense = atom({
  default: {
    monthlyDiscretionaryIncome: '',
    monthlyExpenses: '',
    monthlyIncome: ''
  },
  key: 'incomeAndExpense'
})

export { incomeAndExpense }
