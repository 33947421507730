import cx from 'classnames'
import { LinkWithQuery } from 'components/LinkWithQuery'
import { qaId } from 'consts/qaId'
import { bool, string, array } from 'prop-types'
import { qaAttributes } from 'utils/qaAttributes'
import { Dropdown } from './Dropdown'
import styles from './index.module.scss'

const View = ({ isDark, prevPath, links }) => (
  <div className={styles.container}>
    {links.map(({ clickEventId, label, links: innerLinks, path }) => (
      !Array.isArray(innerLinks)
        ? (
        <LinkWithQuery
          {...qaAttributes({ id: `${qaId.NAV_DESKTOP_PREFIX}-${path}` })}
          key={label}
          className={cx(styles.link, { [styles.dark]: isDark })}
          id={`${clickEventId}_desktop`}
          to={{
            pathname: path,
            state: {
              prevPath
            }
          }}
        >
          {label}
        </LinkWithQuery>
          )
        : (
        <Dropdown
          key={label}
          id={clickEventId}
          links={innerLinks}
          text={label}
        />
          )
    ))}
  </div>
)

View.propTypes = {
  isDark: bool,
  links: array,
  prevPath: string
}

View.displayName = 'DesktopNav.View'

export { View }
