import { isSignificant } from '../isSignificant'

const toWholePercent = float => Math.round(100 * float)

const normalize = debtComparison => {
  const { amortization, monthlyPayments, totalDebt } = debtComparison

  const maxes = {
    amortization: Math.max(amortization.fdr.min, amortization.minimumPayments),
    monthlyPayments: Math.max(
      monthlyPayments.fdr,
      monthlyPayments.minimumPayments
    ),
    totalDebt: Math.max(totalDebt.fdr, totalDebt.minimumPayments)
  }

  const gaps = {
    amortization: {
      fdr: isSignificant(amortization.minimumPayments, amortization.fdr.min),
      minimumPayments: isSignificant(
        amortization.fdr.min,
        amortization.minimumPayments
      )
    },
    monthlyPayments: {
      fdr: isSignificant(monthlyPayments.minimumPayments, monthlyPayments.fdr),
      minimumPayments: isSignificant(
        monthlyPayments.fdr,
        monthlyPayments.minimumPayments
      )
    },
    totalDebt: {
      fdr: isSignificant(totalDebt.minimumPayments, totalDebt.fdr),
      minimumPayments: isSignificant(totalDebt.fdr, totalDebt.minimumPayments)
    }
  }

  const percentages = {
    amortization: {
      fdr: toWholePercent(amortization.fdr.min / maxes.amortization),
      minimumPayments: toWholePercent(
        amortization.minimumPayments / maxes.amortization
      )
    },
    monthlyPayments: {
      fdr: toWholePercent(monthlyPayments.fdr / maxes.monthlyPayments),
      minimumPayments: toWholePercent(
        monthlyPayments.minimumPayments / maxes.monthlyPayments
      )
    },
    totalDebt: {
      fdr: toWholePercent(totalDebt.fdr / maxes.totalDebt),
      minimumPayments: toWholePercent(
        totalDebt.minimumPayments / maxes.totalDebt
      )
    }
  }

  return {
    gaps,
    percentages
  }
}

export { normalize }
