import { View } from './View'

const Container = ({ utilization }) => (
  <View
    isOverutilized={utilization >= 75}
    progress={Math.min(utilization, 100)}
    utilization={utilization}
  />
)

Container.propTypes = {
  ...View.propTypes
}

View.displayName = 'UtilizationContainer'

export { Container }
