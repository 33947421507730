import { number } from 'prop-types'
import { Star, starFulfillment } from './Star'
import styles from './index.module.scss'

const TOTAL_STARS = 5

const View = ({ tenths, wholeNumber }) => {
  const fractionalStars = tenths > 0 ? 1 : 0
  const emptyStars = TOTAL_STARS - (wholeNumber + fractionalStars)

  return (
    <div className={styles.container}>
      {[...new Array(wholeNumber)].map((_, index) => (
        <Star key={`star-full-${index}`} fulfillment={starFulfillment.FULL} />
      ))}
      {[...new Array(fractionalStars)].map((_, index) => (
        <Star key={`star-half-${index}`} fulfillment={starFulfillment.HALF} />
      ))}
      {[...new Array(emptyStars)].map((_, index) => (
        <Star key={`star-empty-${index}`} fulfillment={starFulfillment.EMPTY} />
      ))}
    </div>
  )
}

View.defaultProps = {
  tenths: 0
}

View.propTypes = {
  tenths: number,
  wholeNumber: number.isRequired
}

export { View }
