import { DataSection } from 'components/DataSection'
import { StepHeader } from 'components/StepHeader'
import { string, arrayOf, shape, oneOfType, number } from 'prop-types'
import styles from './index.module.scss'
import { FullBleed } from 'components/Layout/FullBleed'

const View = ({ dataSections, title, subtitle }) => (
  <FullBleed>
    <div className={styles.wrapper}>
      <StepHeader
        className={styles.headerWrapper}
        subtitle={subtitle}
        title={title}
      />
      <div className={styles.sections}>
        {dataSections.map(({ value, qaId, title, footer }) => (
          <DataSection key={qaId} footer={footer} id={qaId} title={title}>
            {value}
          </DataSection>
        ))}
      </div>
    </div>
  </FullBleed>
)

View.propTypes = {
  dataSections: arrayOf(
    shape({
      qaId: string.isRequired,
      title: string.isRequired,
      value: oneOfType([string.isRequired, number.isRequired]).isRequired
    })
  ).isRequired,
  subtitle: string.isRequired,
  title: string.isRequired
}

View.defaultProps = {
  subtitle: "As a recap, here's what you're getting when you join our program:",
  title: 'Thank You'
}

View.displayName = 'ThankYou'

export { View }
