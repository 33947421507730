import PropTypes from 'prop-types'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { qaAttributes } from 'utils/qaAttributes'
import styles from './index.module.scss'
import cls from 'classnames'

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      if (node?.data?.target[':type'] === 'Asset') {
        return (
          <img
            alt={node.data.target.file.title}
            src={node.data.target.file.url}
          />
        )
      }
      return null
    },
    [BLOCKS.PARAGRAPH]: (node, children) =>
      children.filter((node) => node)?.length ? <p>{children}</p> : null
  }
}

const View = ({ content, name, ':tags': tags }) => (
  // TODO: Move the ID to qaId consts
  <div
    className={cls(styles.text, {
      [styles.alignCenter]: tags.includes('alignCenter')
    })}
    {...qaAttributes({ id: 'text', suffix: name })}
  >
    {documentToReactComponents(content, options)}
  </div>
)

View.propTypes = {
  ':tags': PropTypes.arrayOf(PropTypes.string).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object
  ]).isRequired,
  name: PropTypes.string.isRequired
}

View.displayName = 'TextView'

export { View }
