import { useNavigationLinks } from 'hooks/useNavigationLinks'
import { View } from './View'

const Container = (props) => {
  const navigationLinks = useNavigationLinks()

  return <View {...props} links={navigationLinks.links} />
}

Container.displayName = 'MobileNav.Container'

export { Container }
